import { useEffect } from 'react';
import { Trans } from 'react-i18next';

import { useActiveRoundForBase } from '../../../../hooks/RoundHooks';
import { Entitlement } from '../../../../model/Entitlement';
import { FlightAttendant } from '../../../../model/FlightAttendant';
import { CHANGE_R2_MODAL } from '../Constants';
import styles from './../../../fa/feature/vacation/vacation.module.scss';

interface R2EnrollmentAlertProps {
  currAtt: FlightAttendant;
  entitlement: Entitlement;
  showChangeButton: boolean;
}

export const R2EnrollmentAlert = (props: R2EnrollmentAlertProps) => {
  const { currAtt, entitlement } = props;
  const { data: activeRound, isFetched: roundFetched } = useActiveRoundForBase(
    currAtt?.base.initials
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="d-print-block">
        {(entitlement?.partR2 && (
          <div className="alert alert-success" role="alert">
            <div className="row">
              <div className="col-12 col-lg-6 align-self-center">
                <i className={`fa-solid fa-check  ${styles.alert_icon}`}></i>
                <Trans i18nKey="vacation.confirm_r2_enrolled" />
              </div>
              {(props.showChangeButton && (
                <div className="col-12 col-lg-6 d-flex justify-content-lg-end mt-2 mt-lg-0 d-print-none">
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-bs-toggle="modal"
                    data-bs-target={`#${CHANGE_R2_MODAL + currAtt.employeeNo}Modal`}>
                    <Trans
                      components={{
                        uline: <u className="text-decoration-none" />,
                      }}
                      i18nKey="vacation.round_2_withdraw_prompt"
                    />
                  </button>
                </div>
              )) ||
                ''}
            </div>
          </div>
        )) || (
          <div className="alert alert-warning" role="alert">
            <div className="row">
              <div className="col-12 col-lg-6 align-self-center">
                <i className={`fa-solid fa-triangle-exclamation ${styles.alert_icon}`}></i>
                <Trans i18nKey="vacation.confirm_r2_not_enrolled" components={{ bold: <b /> }} />
              </div>
              {(props.showChangeButton && (
                <div className="col-12 col-lg-6 d-flex justify-content-lg-end mt-2 mt-lg-0 d-print-none">
                  <button
                    type="button"
                    className="btn btn-warning"
                    data-bs-toggle="modal"
                    data-bs-target={`#${CHANGE_R2_MODAL + currAtt.employeeNo}Modal`}>
                    <Trans
                      components={{
                        uline: <u className="text-decoration-none" />,
                      }}
                      i18nKey="vacation.round_2_enroll_prompt"
                    />
                  </button>
                </div>
              )) ||
                ''}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
