//Module imports
import { AccountInfo, IPublicClientApplication, InteractionStatus } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { loginRequest } from '../../../../config/auth-config';
import { CPM_PATHS } from '../Constants';
//Component imports
import { useGeneralContext } from '../context/general-context-provider';
import AccessValidation from '../role_based_access/AccessValidation';
//Resources imports
import styles from './menu.module.scss';

interface CPMItemsProps {}

export const CPMItems = (props: CPMItemsProps) => {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <div className={`nav_list ${isAuthenticated ? '' : 'd-none'}`}>
        <NavLink to={CPM_PATHS.home} className="nav_link">
          <i className="fs-3 bi bi-house nav_icon"></i>
          <span className="nav_name">{t('menu.cpm.home')}</span>
        </NavLink>
        <NavLink to={CPM_PATHS.bases} className="nav_link">
          <i className="fs-3 bi bi-building nav_icon"></i>
          <span className="nav_name">{t('menu.cpm.bases')}</span>
        </NavLink>
        <NavLink to={CPM_PATHS.days_availability} className="nav_link">
          <i className="fs-3 bi bi-calendar3 nav_icon"></i>
          <span className="nav_name">{t('menu.days_availability')}</span>
        </NavLink>
      </div>
    </>
  );
};
