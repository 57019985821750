import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import axiosInstance from '../config/axios-instance';
import {
  CreateSbl,
  DeleteSbl,
  GetSBLProcessingForEmployee,
  GetSblForEmployee,
  ModifySbl,
} from '../config/endpoints-config';
import { StandingBidLine } from '../model/StandingBidLine';

export const useSblsForEmployee = (employeeId?: number, year?: number, roundNo?: number) =>
  useQuery<StandingBidLine[], AxiosError>(
    ['getSbls', employeeId, year, roundNo],
    () =>
      axiosInstance.get(GetSblForEmployee(employeeId!, year!, roundNo!)).then((resp) => resp.data),
    {
      enabled: !!employeeId && !!year,
    }
  );

export const useProcessingSBLForEmployee = (employeeId?: number, year?: number, roundNo?: number) =>
  useQuery<boolean, AxiosError>(
    ['processingSbls', employeeId, year],
    () =>
      axiosInstance
        .get<StandingBidLine[]>(GetSBLProcessingForEmployee(employeeId!, year!, roundNo!))
        .then((resp) => {
          let response = resp.data;
          return response.length > 0;
        }),
    {
      enabled: !!employeeId && !!year,
      refetchInterval: 2000,
    }
  );

type DeleteSblData = {
  employeeId: number;
  year: number;
  id: number;
};

export const useDeleteSbl = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, DeleteSblData>(
    (sbl) => axiosInstance.delete(DeleteSbl(sbl.id)),
    {
      onSuccess: (resp, sbl) => {
        queryClient.invalidateQueries(['getSbls', sbl.employeeId, sbl.year]);
      },
    }
  );
};

export const useCreateSbl = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, StandingBidLine>(
    (sbl) => axiosInstance.post(CreateSbl, sbl),
    {
      onSuccess: (resp, sbl) => {
        queryClient.invalidateQueries(['getSbls', sbl.flightAttendantId, sbl.year]);
      },
    }
  );
};

export const useModifySbl = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, StandingBidLine>(
    (sbl) => axiosInstance.put(ModifySbl(sbl.id), sbl),
    {
      onSuccess: (resp, sbl) => {
        queryClient.invalidateQueries(['getSbls', sbl.flightAttendantId, sbl.year]);
      },
    }
  );
};
