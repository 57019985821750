import { Navigate } from 'react-router-dom';

import { CPM, CPM_PATHS, FA, FA_PATHS } from '../utils/Constants';
import { useUserContext } from '../utils/context/user-context-provider';

export const RedirectToHome = () => {
  const userCtxt = useUserContext();
  const { role } = userCtxt;
  let homePath = '';

  switch (role) {
    case FA:
      homePath = FA_PATHS.home;
      break;
    case CPM:
      homePath = CPM_PATHS.home;
      break;

    default:
  }

  return <Navigate to={{ pathname: homePath }} />;
};
