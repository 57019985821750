//Env
export const DEVELOPMENT = 'development';
export const STAGING = 'staging';
export const PRODUCTION = 'production';

//Status
export const SUCCESS = 'success';
export const FAIL = 'fail';
export const ERROR = 'error';
export const SERVER_ERROR = 'server_error';

export const SUCCESS_CODE = 200;
export const SERVER_ERROR_CODE = 500;
export const NOT_FOUND = 404;

//General
export const BID_YEAR = 2025;
export const ACTIVE = 1;
export const INACTIVE = 0;
export const EMP_NO_LENGTH = 6;
export const ROUND_1 = 1;
export const ROUND_2 = 2;
export const MIN_SPLIT_LENGTH = 4;
export const QUESTIONS_EMAIL = 'DL-FAANNUALVACBID@flyjazz.ca';
export const BEFORE = 'BEFORE';
export const AFTER = 'AFTER';

// Roles
export const FA: string = 'FA';
export const CPM: string = 'CPM';
export const Admin: string = 'Admin';
export const ReadOnly: string = 'ReadOnly';

//Paths
export const FA_PATHS: { [key: string]: any } = {
  home: '/fa/home',
  vac_bid: '/fa/vacation',
  vac_bid_standing: '/fa/vacation/standing',
  stat_bid: '/fa/stat',
  stat_bid_standing: '/fa/stat/standing',
  days_availability: '/fa/days_availability',
  faq: '/fa/faq',
};

export const CPM_PATHS: { [key: string]: any } = {
  home: '/cpm/home',
  bases: '/cpm/bases',
  fa_list: '/cpm/bases/fa_list',
  french_training_dates: '/cpm/bases/french_training_dates',
  round: '/cpm/bases/round',
  timetable: '/cpm/bases/round_timetable',
  days_availability: '/cpm/days_availability',
  bid_summary: '/cpm/bases/round_timetable/bid_summary',
};

//Buttons
export const SAVE_CHANGES: string = 'save_changes';
export const YES: string = 'yes';
export const YES_SIGN_OUT: string = 'yes_sign_out';
export const CLOSE: string = 'close';
export const CANCEL: string = 'cancel';

//Impersonate
export const IMPERSONATE_ACTION: boolean = true;
export const NOT_IMPERSONATE_ACTION: boolean = false;
export const NO_NUMBER: number = 0;

//Modals

export const SIGN_OUT_MODAL: string = 'sign_out';
export const CHANGE_R2_MODAL: string = 'change_r2';
export const MARK_FRENCH_MODAL: string = 'mark_french';
export const ENABLE_FA_MODAL: string = 'enable_fa';
export const MODIFY_ROUND_MODAL: string = 'modify_round';
export const GENERATE_TIMETABLE: string = 'generate_timetable';
export const DELETE_BACKUP: string = 'delete_backup';
export const DELETE_SPLIT: string = 'delete_split';
export const REMOVE_FTD: string = 'remove_ftd';

//Pages
export const FA_LIST: string = 'FA_LIST';

//Airports
export const HALIFAX: number = 1;
export const MONTREAL: number = 2;
export const TORONTO: number = 3;
export const MONT_JOLI: number = 4;
export const VANCOUVER: number = 5;
export const CALGARY: number = 6;

//Trigger
export const MANUAL: number = 1;
export const HOOK: number = 2;
