import moment, { Moment } from 'moment-timezone';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

import { useAwardedVacationForEmployee } from '../../../../hooks/AwardedVacationHooks';
import { useEntitlementsForEmployee } from '../../../../hooks/EntitlementHooks';
import { useCurrentFlightAttendant } from '../../../../hooks/FlightAttendantHooks';
import { useActiveRoundForBase } from '../../../../hooks/RoundHooks';
import { useSplitsForEmployee } from '../../../../hooks/SplitHooks';
import { useRoundTimetable } from '../../../../hooks/TimetableHooks';
import {
  BID_YEAR,
  CPM,
  CPM_PATHS,
  FA_PATHS,
  QUESTIONS_EMAIL,
  ROUND_2,
} from '../../../components/utils/Constants';
import Utils from '../../../components/utils/Utils';
import { Content } from '../../../components/utils/content/content';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';
import { useUserContext } from '../../../components/utils/context/user-context-provider';
import { Loading } from '../../../components/utils/loading/loading';
import { EarlyAlert } from '../../common/early-alert/early-alert';
import { StatutoryTable } from './statutory-table';
import styles from './statutory.module.scss';

interface StatutoryProps {}

export const Statutory = (props: StatutoryProps) => {
  const { t } = useTranslation();
  const { role } = useUserContext();
  const { selectedLanguage } = useGeneralContext();
  const location = useLocation();
  const impersonateFaEmpNo =
    location.state !== null
      ? (location.state as { impersonateFaEmpNo: number }).impersonateFaEmpNo
      : undefined;

  const { data: currAtt } = useCurrentFlightAttendant(impersonateFaEmpNo!);
  const { data: activeRound } = useActiveRoundForBase(currAtt?.base.initials);
  const { data: timetable } = useRoundTimetable(currAtt?.employeeNo, activeRound?.id);
  const { data: entitlement } = useEntitlementsForEmployee(currAtt?.employeeNo, BID_YEAR);
  const { data: splits } = useSplitsForEmployee(currAtt?.employeeNo, BID_YEAR);
  const { data: awardedVacation } = useAwardedVacationForEmployee(
    currAtt?.employeeNo,
    activeRound?.year
  );

  const impersonateFa = {
    isImpersonate: impersonateFaEmpNo !== undefined,
    faName: currAtt?.name!,
    faEmpNo: currAtt?.employeeNo!,
  };

  /* Time-Updated States / Handling Refresh Interval */

  const [currentTime, setCurrentTime] = useState<Moment>(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const startMoment =
    (timetable?.startTime !== undefined && moment(timetable?.startTime)) || undefined;
  const endMoment = (timetable?.endTime !== undefined && moment(timetable?.endTime)) || undefined;
  const minutesLeft =
    endMoment && !endMoment.isBefore(currentTime)
      ? endMoment.diff(currentTime, 'minutes')
      : undefined;

  const secondsLeft =
    endMoment && !endMoment.isBefore(currentTime)
      ? endMoment.diff(currentTime, 'seconds')
      : undefined;

  const tooEarly = startMoment !== undefined && startMoment.isAfter(currentTime);
  const bidEnded = endMoment !== undefined && endMoment.isBefore(currentTime);

  if (role === CPM && location.state === null) {
    return <Navigate to={CPM_PATHS.home} replace={true} />;
  }

  if (
    activeRound?.id === undefined ||
    activeRound?.roundNo !== ROUND_2 ||
    !activeRound?.submitted
  ) {
    return (
      <Content
        includeHeader={true}
        title={t('menu.fa.statutory_days_bid')}
        subtitle={t('statutory.round_2_subtitle')}
        impersonateFA={impersonateFa}>
        <div className="alert alert-secondary" role="alert">
          <i className="bi bi-info-circle-fill me-2"></i>
          {t('general.errors.no_active_round')}
        </div>
        <StatutoryTable
          bidEnded={bidEnded}
          entitlement={entitlement}
          splits={splits || []}
          awardedVacation={awardedVacation || []}
          currAtt={currAtt}
          activeRound={activeRound}
        />
      </Content>
    );
  }

  if (currAtt !== undefined && timetable === undefined) {
    return (
      <Content
        includeHeader={true}
        title={t('menu.fa.statutory_days_bid')}
        subtitle={t('statutory.round_2_subtitle')}
        impersonateFA={impersonateFa}>
        <div className="alert alert-secondary text-secondary" role="alert">
          <i className="bi bi-info-circle-fill me-2"></i>
          <Trans
            components={{ strong: <strong className="text-secondary" /> }}
            values={{
              roundNum: activeRound?.roundNo,
              email: QUESTIONS_EMAIL,
            }}
            i18nKey="home.fa.info_body_error"
          />
        </div>
      </Content>
    );
  }

  if (currAtt === undefined || timetable === undefined) {
    return (
      <Content
        includeHeader={true}
        title={t('menu.fa.statutory_days_bid')}
        subtitle={t('statutory.round_2_subtitle')}
        impersonateFA={impersonateFa}>
        <Loading />
      </Content>
    );
  }

  if (tooEarly) {
    return (
      <Content
        includeHeader={true}
        title={t('menu.fa.statutory_days_bid')}
        subtitle={t('statutory.round_2_subtitle')}
        impersonateFA={impersonateFa}>
        <EarlyAlert
          headerKey="statutory.early_alert_header"
          bodyKey="statutory.early_alert_body"
          linkTo={FA_PATHS.stat_bid_standing}
          linkKey="statutory.go_to_standing_bid"
          values={{
            scheduledDate:
              Utils.formatDate(
                timetable?.startTime?.toString(),
                currAtt?.base.id,
                selectedLanguage
              ) || '...',
            startTime:
              Utils.formatTime(timetable?.startTime?.toString(), currAtt?.base.id) || '...',
            endTime: Utils.formatTime(timetable?.endTime?.toString(), currAtt?.base.id) || '...',
            currentTime:
              selectedLanguage === 'fr'
                ? Utils.formatTime(currentTime.toDate().toUTCString(), currAtt?.base.id) +
                  ' le ' +
                  Utils.formatOnlyDateUTC(
                    currentTime.toDate().toUTCString(),
                    currAtt?.base.id,
                    selectedLanguage
                  )
                : Utils.formatDateTime(
                    currentTime.toDate().toUTCString(),
                    currAtt?.base.id,
                    selectedLanguage
                  ),
          }}
          roundNo={ROUND_2}
          impersonateFaEmpNo={impersonateFaEmpNo}
        />
      </Content>
    );
  }

  return (
    <Content
      includeHeader={true}
      title={t('menu.fa.statutory_days_bid')}
      subtitle={t('statutory.round_2_subtitle')}
      impersonateFA={impersonateFa}>
      {bidEnded && (
        <div className="alert alert-danger  d-print-none" role="alert">
          <i className={`fa-solid fa-circle-xmark  ${styles.alert_icon}`}></i>
          <Trans i18nKey="statutory.allotted_time_passed" />
        </div>
      )}
      <div className="row">
        <div className="col">
          {!bidEnded && (
            <div
              className="alert alert-champagne d-flex d-md-block flex-column d-print-none"
              role="alert">
              <div className="d-inline-block">
                <Trans
                  components={{ bold: <b /> }}
                  values={{
                    minutes: minutesLeft,
                    seconds: secondsLeft,
                    allottedMinutes: activeRound ? activeRound.allottedTime : '...',
                  }}
                  i18nKey={
                    minutesLeft !== undefined
                      ? minutesLeft > 0
                        ? 'statutory.time_left_template'
                        : 'statutory.time_seconds_left_template'
                      : 'statutory.time_left_complete'
                  }
                />
              </div>
              <div className="d-inline-block float-end">
                {(currAtt?.base?.initials && (
                  <Trans
                    components={{ bold: <b /> }}
                    values={{
                      base: currAtt?.base?.initials,
                      currentTime:
                        currAtt?.base !== undefined
                          ? Utils.formatDateTime(
                              currentTime.toDate().toUTCString(),
                              currAtt.base.id,
                              selectedLanguage
                            )
                          : null,
                    }}
                    i18nKey="statutory.base_time_template"
                  />
                )) ||
                  t('statutory.base_load_fail')}
              </div>
            </div>
          )}
        </div>
      </div>
      <StatutoryTable
        bidEnded={bidEnded}
        entitlement={entitlement}
        splits={splits || []}
        awardedVacation={awardedVacation || []}
        currAtt={currAtt}
        activeRound={activeRound}
      />
    </Content>
  );
};
