import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { useCurrentFlightAttendant } from '../../../../hooks/FlightAttendantHooks';
import { useActiveRoundForBase } from '../../../../hooks/RoundHooks';
import { FlightAttendant } from '../../../../model/FlightAttendant';
import { CHANGE_R2_MODAL, FA_PATHS, ROUND_1 } from '../Constants';
import { useGeneralContext } from '../context/general-context-provider';
import { Header } from '../header/header';
import { Callout } from './callout';
import styles from './content.module.scss';
import { FloatingAlert } from './floating-alert';

interface ActiveTimeslotFloatingAlertProps {
  impersonateFaEmpNo: number;
}

export const ActiveTimeslotFloatingAlert = (props: ActiveTimeslotFloatingAlertProps) => {
  const { impersonateFaEmpNo } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: currAtt } = useCurrentFlightAttendant(impersonateFaEmpNo);
  const { data: activeRound } = useActiveRoundForBase(currAtt?.base.initials);

  const goToBid = () => {
    if (activeRound?.roundNo === 1 || activeRound?.roundNo === 2) {
      navigate(activeRound?.roundNo === 1 ? FA_PATHS.vac_bid : FA_PATHS.stat_bid, {
        state: { impersonateFaEmpNo: impersonateFaEmpNo, fromActiveTimeslotAlert: true },
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <FloatingAlert>
        <div className="row">
          <div className="col d-flex d-lg-inline-block justify-content-center mb-2 text-dark">
            <Trans
              components={{
                bold: <b />,
              }}
              i18nKey="standing.active_timeslot_alert.active_timeslot"></Trans>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex d-lg-inline-block justify-content-center mb-2 text-dark">
            <button type="button" className="btn btn-danger btn-jazz-red me-2" onClick={goToBid}>
              {t(`general.buttons.go_to_bid`)}
            </button>
          </div>
        </div>
      </FloatingAlert>
    </>
  );
};
