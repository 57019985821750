import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Entitlement } from '../../../../model/Entitlement';
import { FlightAttendant } from '../../../../model/FlightAttendant';
import { Split } from '../../../../model/Split';
import { StandingBidLine } from '../../../../model/StandingBidLine';
import { FA_PATHS, ROUND_1, ROUND_2 } from '../../../components/utils/Constants';
import { AddBackupModal } from '../../../components/utils/modals/add-backup-modal';
import { AttachBackupModal } from '../../../components/utils/modals/attach-backup-modal';
import { BackupRow } from './backup-row';

interface StandingBidTableProps {
  roundNumber: number | undefined;
  currAtt: FlightAttendant | undefined;
  entitlement?: Entitlement;
  backups: StandingBidLine[];
  isAttach: boolean;
  setIsAttach: (isAttach: boolean) => void;
  splitAttach: Split;
  attachCategory: string;
}

export const StandingBidTable = (props: StandingBidTableProps) => {
  const { t } = useTranslation();
  const {
    roundNumber,
    currAtt,
    entitlement,
    backups,
    isAttach,
    setIsAttach,
    splitAttach,
    attachCategory,
  } = props;
  const [editingSbl, setEditingSbl] = useState<StandingBidLine>();

  return (
    <>
      <div className="row d-print-none">
        <div className="col-12 col-lg align-self-center mb-2 mb-md-0">
          <span className="fs-4 fw-bold text-champagne">{t('standing.backups')}</span>
        </div>

        <div className="col-12 col-lg-auto d-block d-lg-flex d-lg-block align-items-center justify-content-between flex-wrap flex-lg-nowrap">
          <div
            className="btn btn-champagne mb-3 d-flex align-items-center justify-content-center flex-grow-1 p-3 py-lg-2"
            data-bs-toggle="modal"
            data-bs-target="#addBackupModal"
            onClick={() => {
              setIsAttach(false);
            }}>
            <i className="bi bi-plus-circle"></i>
            <Trans i18nKey="standing.table.add_backup" />
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-auto d-block d-lg-flex d-lg-block align-items-center justify-content-between flex-wrap flex-lg-nowrap d-print-none">
          <div
            className="btn btn-outline-champagne mb-3 d-flex align-items-center justify-content-center flex-grow-1 p-3 py-lg-2"
            onClick={() => window.print()}>
            <i className="bi bi-printer"></i>
            <Trans i18nKey="vacation.print_confirmation" />
          </div>
        </div>
        <div className="col-12 col-lg-auto d-block d-lg-flex d-lg-block align-items-center justify-content-between flex-wrap flex-lg-nowrap">
          <Link
            to={FA_PATHS.days_availability}
            className="btn btn-outline-champagne mb-3 d-flex align-items-center justify-content-center flex-grow-1 p-3 py-lg-2">
            <i className="bi bi-calendar"></i>
            <Trans i18nKey="standing.days_availability" />
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-xl-2 d-block d-lg-flex d-xl-block align-items-center justify-content-between flex-wrap flex-lg-nowrap">
          <div
            className={`card flex-grow-1 m-3 m-xl-0 mx-0 mx-lg-3 mx-xl-0 d-block ${
              roundNumber === ROUND_1 ? 'd-lg-flex' : 'd-none'
            }`}>
            <h5 className="card-header">
              <Trans i18nKey="standing.table.entitlement" />
            </h5>
            <div className="card-body">
              <div className="">
                <Trans i18nKey="home.fa.allowed" />
                <span className="badge rounded=pill text-bg-secondary float-end px-3">
                  {entitlement?.vacation !== undefined ? entitlement.vacation : '...'}
                </span>
              </div>
            </div>
          </div>
          <div
            className={`card flex-grow-1 m-3  m-xl-0  mx-0 mx-lg-3 mx-xl-0 d-block  ${
              roundNumber === ROUND_2 ? 'd-lg-flex' : 'd-none'
            }`}>
            <h5 className="card-header">
              <Trans i18nKey="statutory.table.statutory_days" />
            </h5>
            <div className="card-body">
              <div className="mb-3">
                <Trans i18nKey="home.fa.allowed" />
                <span className="badge rounded-pill text-bg-secondary float-end px-3">
                  {entitlement?.statutory !== undefined ? entitlement.statutory : '...'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-10 d-block d-lg-flex d-xl-block align-items-center justify-content-between flex-wrap flex-lg-nowrap">
          {currAtt &&
            backups.map((backup, idx) => (
              <BackupRow
                backup={backup}
                index={idx + 1}
                baseId={currAtt?.baseId}
                key={backup.id + backup.flightAttendantId}
                onEdit={(backup) => setEditingSbl(backup)}
                setIsAttach={setIsAttach}
              />
            ))}
        </div>
      </div>
      <AddBackupModal
        isAttach={isAttach}
        backup={editingSbl}
        entitlement={entitlement}
        onSuccess={() => setEditingSbl(undefined)}
        length={backups?.length || 0}
      />
      <AttachBackupModal
        isAttach={isAttach}
        backup={editingSbl}
        entitlement={entitlement}
        splitAttach={splitAttach}
        attachCategory={attachCategory}
        onSuccess={() => setEditingSbl(undefined)}
        length={backups?.length || 0}
      />
    </>
  );
};
