import { RedirectToHome } from '../view/components/general/redirect-to-home';
import { CPM_PATHS, FA_PATHS } from '../view/components/utils/Constants';
import { DaysAvailability } from '../view/components/utils/days-availability/days-availability';
import { Bases } from '../view/cpm/feature/bases/bases';
import { BidSummary } from '../view/cpm/feature/bases/bid-summary';
import { FAList } from '../view/cpm/feature/bases/fa-list';
import { FrenchTrainingDates } from '../view/cpm/feature/bases/french_training_dates';
import { ModifyRound } from '../view/cpm/feature/bases/modify_round';
import { RoundTimetable } from '../view/cpm/feature/bases/round-timetable';
import { CPMHome } from '../view/cpm/feature/home/home';
import { Faq } from '../view/fa/feature/faq/faq';
import { FAHome } from '../view/fa/feature/home/home';
import { StandingBid } from '../view/fa/feature/standing-bid/standing-bid';
import { Statutory } from '../view/fa/feature/statutory/statutory';
import { Vacation } from '../view/fa/feature/vacation/vacation';

// Note: Make sure to include at least "Admin" role in roles array.
const routes = [
  {
    path: '/',
    component: RedirectToHome,
    roles: ['Admin', 'FA', 'CPM'],
  },
  //FA PATHS
  {
    path: FA_PATHS.home,
    component: FAHome,
    roles: ['Admin', 'FA', 'CPM'],
  },
  {
    path: FA_PATHS.vac_bid,
    component: Vacation,
    roles: ['Admin', 'FA', 'CPM'],
  },
  {
    path: FA_PATHS.vac_bid_standing,
    component: StandingBid,
    roles: ['Admin', 'FA', 'CPM'],
  },
  {
    path: FA_PATHS.stat_bid,
    component: Statutory,
    roles: ['Admin', 'FA', 'CPM'],
  },
  {
    path: FA_PATHS.stat_bid_standing,
    component: StandingBid,
    roles: ['Admin', 'FA', 'CPM'],
  },
  {
    path: FA_PATHS.days_availability,
    component: DaysAvailability,
    roles: ['Admin', 'FA', 'CPM'],
  },
  {
    path: FA_PATHS.faq,
    component: Faq,
    roles: ['Admin', 'FA', 'CPM'],
  },
  {
    path: '*',
    component: RedirectToHome,
    roles: ['Admin'],
  },
  //CPM PATHS
  {
    path: CPM_PATHS.home,
    component: CPMHome,
    roles: ['Admin', 'CPM'],
  },
  {
    path: CPM_PATHS.bases,
    component: Bases,
    roles: ['Admin', 'CPM'],
  },
  {
    path: CPM_PATHS.fa_list,
    component: FAList,
    roles: ['Admin', 'CPM'],
  },
  {
    path: CPM_PATHS.french_training_dates,
    component: FrenchTrainingDates,
    roles: ['Admin', 'CPM'],
  },
  {
    path: CPM_PATHS.timetable,
    component: RoundTimetable,
    roles: ['Admin', 'CPM'],
  },
  {
    path: CPM_PATHS.round,
    component: ModifyRound,
    roles: ['Admin', 'CPM'],
  },
  {
    path: CPM_PATHS.days_availability,
    component: DaysAvailability,
    roles: ['Admin', 'FA', 'CPM'],
  },
  {
    path: CPM_PATHS.bid_summary,
    component: BidSummary,
    roles: ['Admin', 'FA', 'CPM'],
  },
];

export default routes;
