import React from 'react';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useProcessingSBLForEmployee } from '../../../../hooks/StandingBidLineHooks';
import { AwardedVacation } from '../../../../model/AwardedVacation';
import { Entitlement } from '../../../../model/Entitlement';
import { FlightAttendant } from '../../../../model/FlightAttendant';
import { Round } from '../../../../model/Round';
import { Split } from '../../../../model/Split';
import { BID_YEAR, FA_PATHS } from '../../../components/utils/Constants';
import { SplitModal } from '../../../components/utils/modals/split-modal';
import { EmailConfirmation } from '../../common/email-confirmation';
import { VacationRow } from './vacation-row';
import styles from './vacation.module.scss';

interface VacationTableProps {
  bidEnded: boolean;
  entitlement?: Entitlement;
  splits: Array<Split[]>;
  awardedVacation: AwardedVacation[];
  currAtt?: FlightAttendant;
  activeRound?: Round;
}

export const VacationTable = ({
  bidEnded,
  entitlement,
  splits,
  awardedVacation,
  currAtt,
  activeRound,
}: VacationTableProps) => {
  const { t } = useTranslation();
  const [editingSplit, setEditingSplit] = useState<Split>();

  // Calculate stats
  let totalVacation = 0; // TODO: distinguish between vacation vs stat/banked?
  splits.forEach((split) =>
    split.forEach(
      (item) => (totalVacation += item.totalDays - (item.attachedDaysStart + item.attachedDaysEnd))
    )
  );

  const remainingVacation = entitlement ? entitlement.vacation - totalVacation : 0;

  //Use the max index in the splits lists to determine how many simple or compound splits (more than split equivalente to 1 split) are there.

  const remainingsplits = entitlement && splits ? entitlement.splits - splits.length : 0;
  const isActiveRound = activeRound !== undefined && Object.hasOwn(activeRound, 'id');
  const { data: processingSBL } = useProcessingSBLForEmployee(
    currAtt?.employeeNo,
    BID_YEAR,
    activeRound?.roundNo
  );

  return (
    <>
      <div className="row d-print-none">
        <div className="col-12 col-lg align-self-center mb-2 mb-md-0">
          <span className="fs-4 fw-bold text-champagne">{t('vacation.table.splits')}</span>
        </div>
        <div className="col-12 col-sm-6 col-lg-auto d-block d-lg-flex d-lg-block align-items-center justify-content-between flex-wrap flex-lg-nowrap">
          <div
            className={`btn btn-champagne mb-3 d-flex align-items-center justify-content-center flex-grow-1 p-3 py-lg-2 ${
              remainingVacation === 0 || remainingsplits === 0 || !isActiveRound || processingSBL
                ? 'disabled'
                : ''
            }`}
            data-bs-toggle="modal"
            data-bs-target="#splitModal">
            <i className="bi bi-plus-circle"></i>
            <Trans i18nKey="vacation.table.add_new_split" />
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-auto d-block d-lg-flex d-lg-block align-items-center justify-content-between flex-wrap flex-lg-nowrap">
          {currAtt && <EmailConfirmation attendant={currAtt} year={BID_YEAR} />}
        </div>
        <div className="col-12 col-sm-6 col-lg-auto d-block d-lg-flex d-lg-block align-items-center justify-content-between flex-wrap flex-lg-nowrap">
          <div
            className="btn btn-outline-champagne mb-3 d-flex align-items-center justify-content-center flex-grow-1 p-3 py-lg-2"
            onClick={() => window.print()}>
            <i className="bi bi-printer"></i>
            <Trans i18nKey="vacation.print_confirmation" />
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-auto d-block d-lg-flex d-lg-block align-items-center justify-content-between flex-wrap flex-lg-nowrap">
          <Link
            to={FA_PATHS.days_availability}
            className="btn btn-outline-champagne mb-3 d-flex align-items-center justify-content-center flex-grow-1 p-3 py-lg-2">
            <i className="bi bi-calendar"></i>
            <Trans i18nKey="vacation.days_availability" />
          </Link>
        </div>
      </div>
      <div className="row mb-3">
        <div
          className={`d-flex flex-wrap flex-sm-nowrap col-12 col-xl-3 col-xxl-2 flex-row flex-xl-column gap-3 gap-xl-0 ${styles.entitlement_container} pe-xl-0 mb-3 mb-xl-0`}>
          <div className="col-12 col-sm-6 col-xl-12 card mb-xl-3">
            <h5 className="card-header">
              <Trans i18nKey="vacation.table.paid_vacation" />
            </h5>
            <div className="card-body">
              <div className="mb-3">
                <Trans i18nKey="home.fa.allowed" />
                <span className="badge rounded=pill text-bg-secondary float-end px-3">
                  {entitlement?.vacation !== undefined ? entitlement.vacation : '...'}
                </span>
              </div>
              <div className="mb-3">
                <Trans i18nKey="home.fa.remain" />
                <span className="badge rounded=pill text-bg-secondary float-end px-3">
                  {remainingVacation}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-xl-12 card">
            <h5 className="card-header">
              <Trans i18nKey="vacation.table.splits" />
            </h5>
            <div className="card-body">
              <div className="mb-3">
                <Trans i18nKey="home.fa.allowed_splits" />
                <span className="badge rounded=pill text-bg-secondary float-end px-3">
                  {entitlement?.splits !== undefined ? entitlement.splits : '...'}
                </span>
              </div>
              <div className="mb-3">
                <Trans i18nKey="home.fa.remain_splits" />
                <span className="badge rounded=pill text-bg-secondary float-end px-3">
                  {remainingsplits}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          {processingSBL && (
            <div className="alert alert-info" role="alert">
              <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
              <span role="status">{t('standing.wait_processing_backups')}</span>
            </div>
          )}
          {currAtt &&
            splits.map((group, idx) => (
              <React.Fragment key={idx}>
                <div className="row">
                  <div className="col-12 col-md-4 col-xxl-12 mb-2 mb-xxl-0">
                    <span className="fw-bold fs-5 text-secondary">
                      <Trans i18nKey="vacation.table.split" /> {idx + 1}
                    </span>
                  </div>
                </div>
                {group.map((split, idx) => (
                  <VacationRow
                    key={split.id}
                    index={split.index}
                    split={split}
                    baseId={currAtt.baseId}
                    committed={true}
                    onEdit={(split) => setEditingSplit(split)}
                    bidEnded={bidEnded}
                    isActiveRound={isActiveRound}
                    processingSBL={processingSBL!}
                  />
                ))}
              </React.Fragment>
            ))}
        </div>
        <SplitModal
          id="splitModal"
          split={editingSplit}
          onSuccess={() => setEditingSplit(undefined)}
          bidEnded={bidEnded}
        />
      </div>
    </>
  );
};
