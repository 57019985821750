import { useEffect } from 'react';
import { Trans } from 'react-i18next';

import { FlightAttendant } from '../../../../model/FlightAttendant';
import { CHANGE_R2_MODAL } from '../Constants';
import { FloatingAlert } from './floating-alert';

interface R2FloatingAlertProps {
  currAtt: FlightAttendant;
}

export const R2FloatingAlert = (props: R2FloatingAlertProps) => {
  const { currAtt } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <FloatingAlert>
        <div className="row">
          <div className="col d-flex d-lg-inline-block justify-content-center mb-2 text-dark">
            <Trans
              components={{
                bold: <b />,
                uline: <u className="" />,
                strong: <strong className="" />,
              }}
              i18nKey="standing.big_alert.footer"></Trans>
          </div>
        </div>

        <div className="row">
          <div className="col d-flex d-lg-inline-block justify-content-center">
            <button
              type="button"
              className="btn btn-danger btn-jazz-red"
              data-bs-toggle="modal"
              data-bs-target={`#${CHANGE_R2_MODAL + currAtt.employeeNo}Modal`}>
              <Trans
                components={{
                  uline: <u className="text-decoration-none" />,
                }}
                i18nKey="vacation.round_2_participate_prompt"
              />
            </button>
          </div>
        </div>
      </FloatingAlert>
    </>
  );
};
