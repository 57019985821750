import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
  useEntitlementsForEmployee,
  useUpdateR2Preference,
} from '../../../../hooks/EntitlementHooks';
import { useCurrentFlightAttendant, useUpdateR2 } from '../../../../hooks/FlightAttendantHooks';
import { useActiveRoundForBase } from '../../../../hooks/RoundHooks';
import { useSplitsForEmployee } from '../../../../hooks/SplitHooks';
import { useSblsForEmployee } from '../../../../hooks/StandingBidLineHooks';
import { useRoundTimetable } from '../../../../hooks/TimetableHooks';
import { Split } from '../../../../model/Split';
import {
  CHANGE_R2_MODAL,
  CPM,
  CPM_PATHS,
  FA_PATHS,
  ROUND_1,
  ROUND_2,
} from '../../../components/utils/Constants';
import { ActiveTimeslotFloatingAlert } from '../../../components/utils/alerts/active-timeslot-floating-alert';
import { FloatingAlert } from '../../../components/utils/alerts/floating-alert';
import { R2EnrollmentAlert } from '../../../components/utils/alerts/r2-enrollment-alert';
import { R2FloatingAlert } from '../../../components/utils/alerts/r2-floating-alert';
import { Content } from '../../../components/utils/content/content';
import { useUserContext } from '../../../components/utils/context/user-context-provider';
import { Loading } from '../../../components/utils/loading/loading';
import { ConfirmationModal } from '../../../components/utils/modals/confirmation-modal';
import styles from './../vacation/vacation.module.scss';
import { AwardedSplits } from './awarded-splits';
import { StandingBidTable } from './standing-bid-table';

interface StandingBidProps {}

export const StandingBid = (props: StandingBidProps) => {
  const { t } = useTranslation();
  const { role } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  /** To DO: Revisit this for next year. **/
  const [isAttach, setIsAttach] = useState(false);
  const [splitAttach, setSplitAttach] = useState<Split>();
  const [attachCategory, setAttachCategory] = useState<string>('');

  /** ------------------------------------------------ **/

  const [currentTime, setCurrentTime] = useState<Moment>(moment());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const impersonateFaEmpNo =
    location.state !== null
      ? (location.state as { impersonateFaEmpNo: number }).impersonateFaEmpNo
      : undefined;

  const { data: currAtt } = useCurrentFlightAttendant(impersonateFaEmpNo!);
  const { data: activeRound } = useActiveRoundForBase(currAtt?.base.initials);
  const { data: timetable } = useRoundTimetable(currAtt?.employeeNo, activeRound?.id);
  const { data: entitlement, isFetching: fetchingEntitlement } = useEntitlementsForEmployee(
    currAtt?.employeeNo,
    activeRound?.year
  );
  const { data: backups } = useSblsForEmployee(
    currAtt?.employeeNo,
    activeRound?.year,
    activeRound?.roundNo
  );

  const { data: splits } = useSplitsForEmployee(currAtt?.employeeNo, activeRound?.year);

  const {
    mutate: updateR2Preference,
    isError: isR2PrefUpdateError,
    error: r2PrefUpdateError,
    isLoading: r2PrefUpdateInProgress,
  } = useUpdateR2Preference();

  const [round2Enrolment, setRound2Enrolment] = useState<boolean>(false);

  const notifyRound2EnrolmentChange = (newVal: boolean) => {
    setRound2Enrolment(newVal);
    updateR2Preference({
      employeeId: currAtt!.employeeNo,
      year: activeRound!.year,
      r2Preference: newVal,
      baseId: currAtt!.baseId,
    });
  };

  const impersonateFa = {
    isImpersonate: impersonateFaEmpNo !== undefined,
    faName: currAtt?.name!,
    faEmpNo: currAtt?.employeeNo!,
  };

  const r2PrefLoading = r2PrefUpdateInProgress || fetchingEntitlement;

  useEffect(() => {
    if (!entitlement) return;
    setRound2Enrolment(entitlement.partR2);
  }, [entitlement]);

  const startMoment =
    (timetable?.startTime !== undefined && moment(timetable?.startTime)) || undefined;
  const activeTimeslot = startMoment !== undefined && startMoment.isSameOrBefore(currentTime);

  if (role === CPM && location.state === null) {
    return <Navigate to={CPM_PATHS.home} replace={true} />;
  }

  if (activeRound?.id === undefined || !activeRound?.submitted) {
    return (
      <Content includeHeader={true} title={t('menu.fa.standing_bid')} impersonateFA={impersonateFa}>
        <div className="alert alert-secondary" role="alert">
          <i className="bi bi-info-circle-fill me-2"></i>
          {t('general.errors.no_active_round')}
        </div>
      </Content>
    );
  }

  if (currAtt === undefined || timetable === undefined) {
    return (
      <Content
        includeHeader={true}
        title={t('menu.fa.standing_bid')}
        subtitle={
          activeRound?.roundNo === ROUND_1
            ? t('standing.round_1_subtitle')
            : activeRound?.roundNo === ROUND_2
            ? t('statutory.round_2_subtitle')
            : ''
        }
        impersonateFA={impersonateFa}>
        <Loading />
      </Content>
    );
  }

  return (
    <Content
      includeHeader={true}
      title={t('menu.fa.standing_bid')}
      subtitle={
        activeRound?.roundNo === ROUND_1
          ? t('standing.round_1_subtitle')
          : activeRound?.roundNo === ROUND_2
          ? t('statutory.round_2_subtitle')
          : ''
      }
      impersonateFA={impersonateFa}>
      <div className={`${activeRound?.roundNo === ROUND_1 ? '' : 'd-none'}`}>
        <R2EnrollmentAlert
          currAtt={currAtt}
          entitlement={entitlement!}
          showChangeButton={
            activeRound !== undefined && activeRound.roundNo === ROUND_1
          }></R2EnrollmentAlert>
      </div>
      <AwardedSplits
        activeRound={activeRound}
        splits={splits}
        currAtt={currAtt}
        setIsAttach={setIsAttach}
        setSplitAttach={setSplitAttach}
        setAttachCategory={setAttachCategory}
      />
      <ConfirmationModal
        modalType={CHANGE_R2_MODAL}
        employeeId={currAtt.employeeNo}
        submitAction={() => {
          notifyRound2EnrolmentChange(!entitlement?.partR2);
        }}
        includeHeader={true}>
        <span>
          <Trans
            i18nKey={
              entitlement?.partR2
                ? 'general.modals.body.r2_opt_out_confirm'
                : 'general.modals.body.r2_opt_in_confirm'
            }
          />
        </span>
      </ConfirmationModal>

      <StandingBidTable
        isAttach={isAttach}
        setIsAttach={setIsAttach}
        attachCategory={attachCategory}
        roundNumber={activeRound?.roundNo}
        currAtt={currAtt}
        entitlement={entitlement}
        splitAttach={splitAttach!}
        backups={backups || []}
      />
      {!round2Enrolment && activeRound.roundNo === ROUND_1 && (
        <R2FloatingAlert currAtt={currAtt}></R2FloatingAlert>
      )}

      {activeTimeslot && (
        <ActiveTimeslotFloatingAlert
          impersonateFaEmpNo={impersonateFaEmpNo!}></ActiveTimeslotFloatingAlert>
      )}
    </Content>
  );
};
