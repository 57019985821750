import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from '../config/axios-instance';
import {
  GetDaysAvailability,
  GetDaysAvailabilityByYear,
  GetFrenchTrainingDates,
  GetFrenchTrainingDatesByYear,
} from '../config/endpoints-config';
import { DayAvailability } from '../model/DayAvailability';
import { FrenchTrainingDate } from '../model/FrenchTrainingDate';

export const useDaysAvailability = (year?: number, month?: number, baseInitials?: string) =>
  useQuery<DayAvailability[], AxiosError>(
    ['daysAvailability', year, month, baseInitials],
    () =>
      axiosInstance
        .get(GetDaysAvailability(year!, month!, baseInitials!))
        .then((resp) => resp.data),
    {
      enabled: !!year && !!month && !!baseInitials, // Require all 3 params before query will execute
      placeholderData: [],
      refetchInterval: 5000, // Refresh the days availability data every 5 seconds to ensure we're always seeing latest
    }
  );

export const useDaysAvailabilityByYear = (year?: number, baseInitials?: string) =>
  useQuery<DayAvailability[], AxiosError>(
    ['daysAvailabilityByYear', year, baseInitials],
    () =>
      axiosInstance.get(GetDaysAvailabilityByYear(year!, baseInitials!)).then((resp) => resp.data),
    {
      enabled: !!year && !!baseInitials, // Require all 2 params before query will execute
      placeholderData: [],
      refetchInterval: 5000, // Refresh the days availability data every 5 seconds to ensure we're always seeing latest
    }
  );
