import { useTranslation } from 'react-i18next';

import { QUESTIONS_EMAIL } from '../Constants';
import { Content } from '../content/content';
import { DaysAvailabilityCalendar } from './days-availability-calendar';

interface DaysAvailabilityProps {}

// TODO: Make this user-agnostic ot the extent of allowing management and attendants to both view availability
export const DaysAvailability = (props: DaysAvailabilityProps) => {
  const { t } = useTranslation();

  return (
    <Content
      includeHeader={true}
      title={t('menu.days_availability')}
      subtitle={t('availability.subtitle', { email: QUESTIONS_EMAIL })}>
      <DaysAvailabilityCalendar />
    </Content>
  );
};
