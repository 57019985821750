import { AccountInfo } from '@azure/msal-browser';
import { useIsAuthenticated } from '@azure/msal-react';
import { Navigate, Outlet } from 'react-router-dom';

interface PrivateRouteProps {
  userRole: string;
  route: {
    path: string;
    component: any;
    roles: string[];
  };
}

//Role based access
const PrivateRoute = (props: PrivateRouteProps) => {
  const isAuthenticated = useIsAuthenticated();

  if (props.userRole !== undefined) {
    if (props.route.roles.length > 0) {
      return isAuthenticated && props.route.roles.includes(props.userRole) ? (
        <Outlet />
      ) : (
        <Navigate to={`/`} replace />
      );
    }
  }

  return <Navigate to={`/`} replace />; //To do: Replace for another screen in the case where the account has no role. Maybe a sign in one.
};

export default PrivateRoute;
