export interface Round {
  id: number;
  roundNo: number;
  year: number;
  periodStartDate: Date;
  periodEndDate: Date;
  bidStartDate: Date;
  bidStartTime: Date;
  bidEndDate: Date;
  bidEndTime: Date;
  allottedTime: number;
  baseId: number;
  submitted: boolean;
  submittedByName: string;
  submittedByEmployeeId: number;
  submittedDate: Date;
  closingDate: Date;
}

export class Round {
  id: number = 0;
  roundNo: number = 0;
  year: number = 0;
  periodStartDate: Date = new Date();
  periodEndDate: Date = new Date();
  bidStartDate: Date = new Date();
  bidStartTime: Date = new Date();
  bidEndDate: Date = new Date();
  bidEndTime: Date = new Date();
  allottedTime: number = 0;
  baseId: number = 0;
  submitted: boolean = false;
  submittedByName: string = '';
  submittedByEmployeeId: number = 0;
  submittedDate: Date = new Date();
  closingDate: Date = new Date();
}
