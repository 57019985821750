import moment from 'moment-timezone';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { FAController } from '../../../../controller/FAController';
import { useDaysAvailabilityByYear } from '../../../../hooks/DaysAvailabilityHooks';
import { usePostFrenchTrainingDates } from '../../../../hooks/FrenchTrainingDatesHooks';
import { useActiveRoundForBase } from '../../../../hooks/RoundHooks';
import { useSplitsForBase, useSplitsForEmployee } from '../../../../hooks/SplitHooks';
import { Base } from '../../../../model/Base';
import { FlightAttendant } from '../../../../model/FlightAttendant';
import { FormError } from '../../../../model/FormError';
import { FrenchTrainingDate } from '../../../../model/FrenchTrainingDate';
import { BID_YEAR, CPM, EMP_NO_LENGTH, NOT_FOUND } from '../Constants';
import Utils from '../Utils';
import { useGeneralContext } from '../context/general-context-provider';
import { useUserContext } from '../context/user-context-provider';
import styles from './split-modal.module.scss';

interface AddFrenchTrainingDatesModalProps {
  frenchTrainingDates: FrenchTrainingDate[];
  flightAtts: FlightAttendant[];
  base: Base;
  setSuccessMessage: (value: string) => void;
  setErrorMessage: (value: string) => void;
}

export const AddFrenchTrainingDatesModal = ({
  setErrorMessage,
  setSuccessMessage,
  frenchTrainingDates,
  flightAtts,
  base,
}: AddFrenchTrainingDatesModalProps) => {
  const { t } = useTranslation();
  const { role } = useUserContext();
  const { selectedLanguage } = useGeneralContext();
  const faCtrl = new FAController();

  const [errors, setErrors] = useState<FormError[]>([]);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [bidStartDate, setBidStartDate] = useState<Date | null>(null);
  const [bidEndDate, setBidEndDate] = useState<Date | null>(null);
  const [existingFTDs, setExistingFTDs] = useState<Date[]>([]);
  const [searching, setSearching] = useState<boolean>(false);

  const [errorModalMessage, setErrorModalMessage] = useState<string>('');
  const [employeeId, setEmployeeId] = useState<string>('');
  const [selectedFA, setSelectedFA] = useState<FlightAttendant | null>();

  const { data: activeRound, isFetched: roundFetched } = useActiveRoundForBase(base.initials);

  const { data: existingSplits, isFetched: existingSplitsFetched } = useSplitsForEmployee(
    selectedFA?.employeeNo,
    activeRound ? activeRound.year : BID_YEAR
  );

  const bidYear = BID_YEAR;
  let minDate = useMemo(() => (bidYear ? new Date(bidYear, 0, 1) : undefined), [bidYear]);
  let maxDate = useMemo(() => (bidYear ? new Date(bidYear, 11, 31) : undefined), [bidYear]);

  const {
    mutate: addFrenchTrainingDate,
    isError: isAddFTDError,
    error: addFTDError,
    isLoading: addFTDInProgress,
  } = usePostFrenchTrainingDates(setErrorMessage, setSuccessMessage);

  const excludedDaysFromSplits = useMemo(
    () => Utils.extractSplitDates(existingSplits!),
    [existingSplits]
  );

  if (startDate !== null && existingSplits !== null) {
    for (const group of existingSplits || []) {
      for (const split of group || []) {
        if (moment(Utils.formatDateOnly(split.startDate)).isBetween(startDate, maxDate))
          maxDate = Utils.formatDateOnly(split.startDate)!;
      }
    }

    for (const ftd of existingFTDs || []) {
      if (moment(ftd).isBetween(startDate, maxDate)) maxDate = ftd;
    }
  }
  if (endDate !== null && existingSplits !== null) {
    for (const group of existingSplits || []) {
      for (const split of group || []) {
        if (moment(Utils.formatDateOnly(split.endDate)).isBetween(minDate, endDate))
          minDate = Utils.formatDateOnly(split.endDate)!;
      }
    }

    for (const ftd of existingFTDs || []) {
      if (moment(ftd).isBetween(minDate, endDate)) minDate = ftd;
    }
  }

  const handleAddSubmit = () => {
    if (startDate !== null && selectedFA !== undefined && selectedFA !== null && endDate !== null) {
      addFrenchTrainingDate({
        employeeId: selectedFA?.employeeNo,
        startDate:
          startDate.getFullYear() + '/' + (startDate.getMonth() + 1) + '/' + startDate.getDate(),
        endDate: endDate.getFullYear() + '/' + (endDate.getMonth() + 1) + '/' + endDate.getDate(),
      });
      discardChanges();
    }
  };

  const empIdOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setEmployeeId(e.target.value);
      setErrorModalMessage('');
      setSelectedFA(null);
      setStartDate(null);
    }
  };

  const handleSearch = (e: any) => {
    e.preventDefault && e.preventDefault();
    setSearching(true);
    if (employeeId.length === EMP_NO_LENGTH) {
      faCtrl
        .getFA(Number(employeeId))
        .then((response) => {
          if (response.status === NOT_FOUND) {
            setErrorModalMessage(
              t('general.modals.errors.no_fa_found', {
                empNo: employeeId,
              })
            );
          } else {
            let resultFa = response?.data as FlightAttendant;
            setSelectedFA(resultFa);
          }
        })
        .catch((error) => {
          setErrorModalMessage(t('general.errors.server_error'));
        })
        .finally(() => {
          setSearching(false);
        });
    } else {
      setErrorModalMessage(t('general.modals.errors.short_emp_no'));
      setSearching(false);
    }
  };

  const onKeyEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  const discardChanges = () => {
    setExistingFTDs([]);
    setStartDate(null);
    setEndDate(null);
    setSelectedFA(null);
    setEmployeeId('');
    setSearching(false);
    setErrorModalMessage('');
    setErrors([]);
  };

  const getExistingFTDs = (id: number) => {
    var newTrainingDates: Date[] = [];
    frenchTrainingDates
      .filter((ftd: FrenchTrainingDate) => {
        if (ftd.flightAttendantId === id) {
          return ftd;
        }
      })
      .map((ftd: FrenchTrainingDate, index) => newTrainingDates.push(new Date(ftd.trainingDate)));
    return newTrainingDates;
  };

  const dayClassName = (day: Date) => {
    if (day.getFullYear() !== bidYear) return styles.outOfScopeDate;
    const dayMoment = moment(day);
    if (
      dayMoment.isBetween(startDate, endDate, undefined, '[]') &&
      dayMoment.isBetween(
        Utils.formatDateOnly(startDate!),
        Utils.formatDateOnly(endDate!),
        undefined,
        '[]'
      )
    )
      return styles.modifyingDate;
    for (const split of excludedDaysFromSplits || []) {
      if (dayMoment.isBetween(split.start, split.end, undefined, '[]')) return styles.awardedDate;
    }
    if (existingFTDs.filter((ftd) => dayMoment.isSame(ftd, 'day')).length > 0)
      return styles.unavailableDate;
    return null;
  };

  useEffect(() => {
    discardChanges();
  }, []);

  useEffect(() => {
    if (selectedFA !== null && selectedFA !== undefined) {
      setExistingFTDs(getExistingFTDs(selectedFA.employeeNo));
    } else {
      setExistingFTDs([]);
    }
  }, [selectedFA]);

  if (role === CPM) {
    return (
      <>
        <div
          className="modal fade"
          id="addFrenchTrainingDatesModal"
          tabIndex={-1}
          aria-labelledby="addFrenchTrainingDatesModalLabel"
          aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5 text-start" id="addFrenchTrainingModalDatesLabel">
                  {t('general.modals.titles.add_french_training_dates')}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={discardChanges}></button>
              </div>
              <div className="modal-body pb-3">
                <form className="row">
                  <div className="col-12 pb-3 text-start d-flex align-items-center">
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        value={employeeId}
                        placeholder={t('general.modals.body.search_by_emp_no')}
                        aria-label={t('general.modals.body.search_by_emp_no')}
                        aria-describedby="button-addon2"
                        maxLength={EMP_NO_LENGTH}
                        onChange={(e) => {
                          empIdOnChange(e);
                        }}
                        onKeyUp={(e) => {
                          onKeyEnter(e);
                        }}
                      />
                      <button
                        className="btn btn-secondary btn-zindex-0"
                        type="submit"
                        id="button-addon2"
                        onClick={(e) => {
                          handleSearch(e);
                        }}
                        disabled={searching || employeeId.length === 0}>
                        <i className="bi bi-search"></i>
                      </button>
                    </div>
                  </div>
                </form>
                <div className={`row ${errorModalMessage === '' ? '' : 'd-none'}`}>
                  <div className="col-12 text-start d-flex align-items-center">
                    <div
                      className={`table-responsive w-100 ${
                        selectedFA !== undefined && selectedFA !== null ? '' : 'd-none'
                      }`}>
                      <table className="table table-hover align-middle">
                        <thead>
                          <tr className="text-center">
                            <th scope="col">{t('bases.fa_list.table.employee_no')}</th>
                            <th scope="col" className="text-start">
                              {t('bases.fa_list.table.name')}
                            </th>
                            <th scope="col" className="text-center">
                              {t('bases.fa_list.table.seniority')}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="table-group-divider">
                          <tr key={selectedFA?.employeeNo} className={`text-center`}>
                            <th scope="row">{selectedFA?.employeeNo}</th>
                            <td className="text-capitalize text-start">{selectedFA?.name}</td>
                            <td>{selectedFA?.seniority}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className={`row ${errorModalMessage === '' ? 'd-none' : ''}`}>
                  <div className="col-12 text-start d-flex align-items-center">
                    <div className="alert alert-danger w-100" role="alert">
                      <i className="bi bi-exclamation-circle-fill me-2"></i>
                      {errorModalMessage}
                    </div>
                  </div>
                </div>
                <form className="row g-1 needs-validation pt-3" noValidate>
                  <div className="col-md-6">
                    <label className="form-label">{t('general.modals.body.start_date')}</label>
                    <DatePicker
                      className={`form-control ${
                        Utils.hasFieldError(errors, Utils.getVarName({ startDate }))
                          ? 'is-invalid'
                          : ''
                      } validate-form-date-input`}
                      placeholderText={t('general.date_format').toLowerCase()}
                      dateFormat={t('general.date_format')}
                      excludeDates={existingFTDs}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      excludeDateIntervals={excludedDaysFromSplits}
                      isClearable={true}
                      locale={selectedLanguage}
                      selected={startDate}
                      minDate={minDate}
                      maxDate={endDate || maxDate}
                      dayClassName={dayClassName}
                      disabled={selectedFA === null || selectedFA === undefined}
                      onChange={(date: any) => {
                        setStartDate(date);
                      }}
                    />
                    <div
                      className={`invalid-feedback ${
                        Utils.hasFieldError(errors, Utils.getVarName({ startDate }))
                          ? 'd-block'
                          : 'd-none'
                      }`}>
                      {Utils.getFieldError(errors, Utils.getVarName({ startDate }))?.message}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">{t('general.modals.body.end_date')}</label>
                    <DatePicker
                      className={`form-control ${
                        Utils.hasFieldError(errors, Utils.getVarName({ endDate }))
                          ? 'is-invalid'
                          : ''
                      } validate-form-date-input`}
                      placeholderText={t('general.date_format').toLowerCase()}
                      dateFormat={t('general.date_format')}
                      excludeDates={existingFTDs}
                      excludeDateIntervals={excludedDaysFromSplits}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      isClearable={true}
                      locale={selectedLanguage}
                      selected={endDate}
                      minDate={startDate}
                      maxDate={maxDate}
                      dayClassName={dayClassName}
                      disabled={startDate == null || startDate == undefined}
                      onChange={(date: any) => {
                        setEndDate(date);
                      }}
                    />
                    <div
                      className={`invalid-feedback ${
                        Utils.hasFieldError(errors, Utils.getVarName({ startDate }))
                          ? 'd-block'
                          : 'd-none'
                      }`}>
                      {Utils.getFieldError(errors, Utils.getVarName({ startDate }))?.message}
                    </div>
                  </div>
                </form>
              </div>
              <div className={`modal-footer`}>
                <div className="row me-auto w-100">
                  <div className="col-12 text-end p-0 d-flex">
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-outline-jazz-red me-2 w-50"
                      data-bs-dismiss="modal"
                      onClick={(e) => discardChanges()}>
                      {t(`general.buttons.cancel`)}
                    </button>
                    <button
                      id="uploadFile"
                      type="button"
                      className="btn btn-danger btn-jazz-red w-50"
                      onClick={handleAddSubmit}
                      data-bs-dismiss="modal"
                      disabled={endDate === null || startDate === null}>
                      {t(`general.buttons.submit`)}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};
