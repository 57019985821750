//Component imports
import {
  GetActiveRoundsForBase,
  GetCrewTracBST,
  GetCrewTracVAC,
  GetEntitleReport,
  PutExtendRound,
  Rounds,
} from '../config/endpoints-config';
import GeneralService from './GeneralService';

export default class ReportsService extends GeneralService {
  async getEntitleReport(year: number) {
    let result = this.getFileWithParams(GetEntitleReport(year), null);
    return result;
  }

  async getCrewTracVAC(startDate: Date, endDate: Date) {
    let result = this.getFileWithParams(GetCrewTracVAC(startDate, endDate), null);
    return result;
  }

  async getCrewTracBST(startDate: Date, endDate: Date) {
    let result = this.getFileWithParams(GetCrewTracBST(startDate, endDate), null);
    return result;
  }
}
