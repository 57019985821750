import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import routes from '../../../config/route-config';
import { useLanguagePrefByEmpId } from '../../../hooks/LanguageHooks';
import { DEVELOPMENT } from '../utils/Constants';
import Utils from '../utils/Utils';
import { GeneralContextProvider } from '../utils/context/general-context-provider';
import { useUserContext } from '../utils/context/user-context-provider';
import { Menu } from '../utils/menu/menu';
import PrivateRoute from '../utils/role_based_access/PrivateRoute';
import { loginRequest } from './../../../config/auth-config';
import './app.scss';

// Allow for importing the devtools in prod during runtime if necessary
const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
    default: d.ReactQueryDevtools,
  }))
);

function App() {
  //Initializations
  const { t, i18n } = useTranslation();
  const LANGUAGES: { [index: string]: any } = {
    en: { name: t('languages.english') },
    fr: { name: t('languages.french') },
  };

  useMsalAuthentication(InteractionType.Redirect);
  const userInfo = useUserContext();

  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  //State
  const [isMenuToggled, setMenuToggled] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showDevtools, setShowDevtools] = React.useState(false);
  const { data: selectedLanguage } = useLanguagePrefByEmpId(parseInt(userInfo.employeeId));

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  //Hooks
  useEffect(() => {
    Utils.loadFrenchMoment();
    //Language
    i18n.changeLanguage(selectedLanguage);
    moment.locale(selectedLanguage);
  }, [selectedLanguage, i18n]);

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.pageYOffset > 200 ? setShowButton(true) : setShowButton(false);
    };

    window.addEventListener('scroll', handleScrollButtonVisibility);
    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisibility);
    };
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== DEVELOPMENT) {
      //Auth
      if (!isAuthenticated && inProgress === InteractionStatus.None) {
        instance.loginRedirect(loginRequest).catch((e) => {
          console.error(e);
        });
      }
    }
  }, []);

  React.useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <>
      <GeneralContextProvider
        isMenuToggled={isMenuToggled}
        selectedLanguage={selectedLanguage!}
        languages={LANGUAGES}>
        <BrowserRouter>
          <AuthenticatedTemplate>
            <Menu setMenuToggled={setMenuToggled} />
            <Routes>
              {routes.map((route) => (
                <Route
                  key={`private_${route.path}`}
                  element={<PrivateRoute userRole={userInfo.role} route={route} />}>
                  <Route key={route.path} path={route.path} element={<route.component />} />
                </Route>
              ))}
            </Routes>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div className="container-fluid">
              <div className="row flex-nowrap">
                <Menu setMenuToggled={setMenuToggled} />
              </div>
            </div>
          </UnauthenticatedTemplate>
        </BrowserRouter>
      </GeneralContextProvider>
      {showButton && (
        <div>
          <button
            className="btn btn-danger btn-jazz-red rounded-circle position-fixed bottom-0 start-50 translate-middle-x mb-4 position-in-front z-index-top no_print"
            onClick={handleScrollToTop}>
            <i className="fas fa-chevron-up me-0" />
          </button>
        </div>
      )}
      <ReactQueryDevtools initialIsOpen />
      {showDevtools && (
        <React.Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </React.Suspense>
      )}
    </>
  );
}

export default App;
