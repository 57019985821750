import ReportsService from '../services/ReportsService';

export class ReportsController {
  private reportsService: ReportsService;

  constructor() {
    this.reportsService = new ReportsService();
  }

  public async getEntitleReport(year: number) {
    try {
      return this.reportsService.getEntitleReport(year);
    } catch (error) {
      console.log('ReportsController Error - ', error);
    }
  }

  public async getCrewTracVAC(startDate: Date, endDate: Date) {
    try {
      return this.reportsService.getCrewTracVAC(startDate, endDate);
    } catch (error) {
      console.log('ReportsController Error - ', error);
    }
  }
  public async getCrewTracBST(startDate: Date, endDate: Date) {
    try {
      return this.reportsService.getCrewTracBST(startDate, endDate);
    } catch (error) {
      console.log('ReportsController Error - ', error);
    }
  }
}
