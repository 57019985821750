import { useAccount, useMsal } from '@azure/msal-react';
import React, { useContext, useEffect } from 'react';
import { ReactNode, useState } from 'react';

export type UserInfo = {
  role: string;
  employeeId: string;
  name: string;
  email: string;
};

const defaultUserInfo = { role: '', employeeId: '', name: '', email: '' };

type UserContextProps = {
  children: ReactNode;
};

export const UserContext = React.createContext<UserInfo>({ ...defaultUserInfo });

const ROLE_INDEX = 0;

export function UserInfoProvider({ children }: UserContextProps) {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [contextState, setContextState] = useState<UserInfo>({ ...defaultUserInfo });

  useEffect(() => {
    setContextState({
      role: account?.idTokenClaims?.roles?.at(ROLE_INDEX) || '',
      employeeId: String(account?.idTokenClaims?.employeeid) || '',
      name: account?.name || '',
      email: account?.username || '',
    });
  }, [account]);

  return <UserContext.Provider value={contextState}>{children}</UserContext.Provider>;
}

export const useUserContext = () => useContext(UserContext);
