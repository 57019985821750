import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import axiosInstance from '../config/axios-instance';
import {
  Entitlements,
  FlightAttendantChangeBase,
  FlightAttendants,
  GetCurrentFA,
  GetFa,
  GetFlightAttendantsByBase,
  ImportFlightAttendant,
  UpdateFaStatus,
  UpdateFrenchPreference,
  UpdateR2PReference,
} from '../config/endpoints-config';
import { Entitlement } from '../model/Entitlement';
import { FlightAttendant } from '../model/FlightAttendant';

export const useCurrentFlightAttendant = (id?: number) => {
  return useQuery<FlightAttendant, AxiosError>(['current', id], () =>
    axiosInstance.get(id ? GetFa(id) : GetCurrentFA).then((resp) => {
      return resp.data;
    })
  );
};

export const useFlightAttendantsForBase = (baseId: number, year: number) =>
  useQuery<FlightAttendant[], AxiosError>(
    ['getFAs', baseId],
    () =>
      axiosInstance
        .get(GetFlightAttendantsByBase, { params: { baseId: baseId!, year: year! } })
        .then((resp) => resp.data as FlightAttendant[]),
    {
      enabled: !!baseId,
      refetchInterval: 5000,
    }
  );

type R2PreferenceData = {
  r2Preference: boolean;
  employeeId: number;
  year: number;
  baseId: number;
};

export const useUpdateR2 = (
  setErrorMessage: (value: string) => void,
  setSuccessMessage: (value: string) => void
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse, AxiosError, R2PreferenceData>(
    (pref) =>
      axiosInstance.put(UpdateR2PReference(pref.employeeId), pref.r2Preference, {
        params: { year: pref.year },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    {
      onSuccess: (resp, pref) => {
        queryClient.invalidateQueries(['getFAs', pref.baseId]);
        setSuccessMessage(t('general.status.success'));
      },
      onError: (error, query) => {
        setErrorMessage(t('general.errors.server_error'));
      },
    }
  );
};

type FrenchPreferenceData = {
  frenchLang: number;
  employeeId: number;
  baseId: number;
};

export const useUpdateFrench = (
  setErrorMessage: (value: string) => void,
  setSuccessMessage: (value: string) => void
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse, AxiosError, FrenchPreferenceData>(
    (pref) =>
      axiosInstance.put(UpdateFrenchPreference(pref.employeeId), pref.frenchLang, {
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    {
      onSuccess: (resp, pref) => {
        queryClient.invalidateQueries(['getFAs', pref.baseId]);
        setSuccessMessage(t('general.status.success'));
      },
      onError: (error, query) => {
        setErrorMessage(t('general.errors.server_error'));
      },
    }
  );
};

type StatusPreferenceData = {
  status: number;
  employeeId: number;
  baseId: number;
};

export const useUpdateFaStatus = (
  setErrorMessage: (value: string) => void,
  setSuccessMessage: (value: string) => void
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse, AxiosError, StatusPreferenceData>(
    (pref) =>
      axiosInstance.put(UpdateFaStatus(pref.employeeId), pref.status, {
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    {
      onSuccess: (resp, pref) => {
        queryClient.invalidateQueries(['getFAs', pref.baseId]);
        setSuccessMessage(t('general.status.success'));
      },
      onError: (error, query) => {
        setErrorMessage(t('general.errors.server_error'));
      },
    }
  );
};

type UpdateFAData = {
  fa: FlightAttendant;
  employeeId: number;
  year: number;
  baseId: number;
};
export const useUpdateFA = (setErrorMessage: (value: string) => void) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse, AxiosError, UpdateFAData>(
    (data) =>
      axiosInstance.put(FlightAttendants + data.fa.employeeNo, data.fa, {
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    {
      onSuccess: (resp, data) => {
        queryClient.invalidateQueries(['getFAs', data.baseId]);
      },
      onError: (error, query) => {
        setErrorMessage(t('general.errors.server_error'));
      },
    }
  );
};

type UpdateEntitlementData = {
  entitlement: Entitlement;
  employeeId: number;
  year: number;
  baseId: number;
};
export const useUpdateEntitlement = (setErrorMessage: (value: string) => void) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse, AxiosError, UpdateEntitlementData>(
    (data) =>
      axiosInstance.put(Entitlements + data.entitlement.id, data.entitlement, {
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    {
      onSuccess: (resp, data) => {
        queryClient.invalidateQueries(['getFAs', data.baseId]);
      },
      onError: (error, query) => {
        setErrorMessage(t('general.errors.server_error'));
      },
    }
  );
};

type ChangeBaseData = {
  employeeId: number;
  year: number;
  currentBaseId: number;
  destBaseId: number;
};
export const useChangeBase = (setErrorMessage: (value: string) => void) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse, AxiosError, ChangeBaseData>(
    (data) =>
      axiosInstance.put(FlightAttendantChangeBase(data.employeeId, data.destBaseId), null, {
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    {
      onSuccess: (resp, data) => {
        queryClient.invalidateQueries(['getFAs', data.currentBaseId]);
      },
      onError: (error, query) => {
        setErrorMessage(t('general.errors.server_error'));
      },
    }
  );
};

type ImportFAData = {
  employeeId: number;
  year: number;
  baseId: number;
};
export const useImportFA = (
  setSuccessMessage: (value: string) => void,
  setErrorMessage: (value: string) => void
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse, AxiosError, ImportFAData>(
    (data) =>
      axiosInstance.get(ImportFlightAttendant, {
        params: {
          employeeId: data.employeeId,
          baseId: data.baseId,
          year: data.year,
        },
      }),
    {
      onSuccess: (resp, data) => {
        let fa = resp.data as FlightAttendant;
        queryClient.invalidateQueries(['getFAs', data.baseId]);
        setSuccessMessage(
          t('general.modals.success.successfully_imported', {
            empName: fa.name.toUpperCase(),
          })
        );
      },
      onError: (error, query) => {
        setErrorMessage(t('general.errors.server_error'));
      },
    }
  );
};
