export interface FormError {
  field: string;
  message: string;
}

export class FormError {
  field: string = '';
  message: string = '';

  constructor(field: string, error: string) {
    this.field = field;
    this.message = error;
  }
}
