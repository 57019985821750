import moment from 'moment-timezone';
import { Trans } from 'react-i18next';

import { useSendSummaryForEmployee } from '../../../hooks/EmailHooks';
import { FlightAttendant } from '../../../model/FlightAttendant';

interface EmailConfirmationProps {
  attendant: FlightAttendant;
  year: number;
}

export const EmailConfirmation = ({ attendant, year }: EmailConfirmationProps) => {
  const { mutate: sendEmail } = useSendSummaryForEmployee();
  const emailCooldownMinutes = 5;
  const canEmail =
    attendant.lastManualEmail === null ||
    moment().diff(moment(attendant.lastManualEmail), 'minute') >= emailCooldownMinutes;
  const emailTimeout =
    (!canEmail &&
      moment.duration(
        moment(attendant.lastManualEmail).add(emailCooldownMinutes, 'minutes').diff(moment())
      )) ||
    undefined;

  return (
    <div
      className={`btn btn-outline-champagne mb-3 d-flex align-items-center justify-content-center flex-grow-1 p-3 py-lg-2 d-print-none ${
        canEmail ? '' : 'disabled'
      }`}
      onClick={() => sendEmail({ employeeId: attendant.employeeNo, year: year })}>
      <i className="bi bi-envelope"></i>
      <Trans i18nKey="general.buttons.send_confirmation_email" />
      {emailTimeout &&
        ` [${emailTimeout?.minutes()}:${emailTimeout?.seconds().toString().padStart(2, '0')}]`}
    </div>
  );
};
