import React, { createContext, useContext } from 'react';

interface GeneralContextProps {
  isMenuToggled: boolean;
  selectedLanguage: string;
  languages: { [index: string]: any };
  children: React.ReactNode;
}

const generalContextObj: GeneralContextProps = {
  isMenuToggled: true,
  selectedLanguage: 'en',
  languages: {},
  children: undefined,
};
const GeneralContext = createContext(generalContextObj);

export const GeneralContextProvider = (props: GeneralContextProps) => {
  const generalCtx = {
    isMenuToggled: props.isMenuToggled,
    selectedLanguage: props.selectedLanguage,
    languages: props.languages,
    children: props.children,
  };

  return <GeneralContext.Provider value={generalCtx}>{props.children}</GeneralContext.Provider>;
};

export const useGeneralContext = () => useContext(GeneralContext);
