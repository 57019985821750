import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import FlyingAirplane from '../../../../assets/img/flying-airplane.gif';
import { Content } from '../content/content';
import styles from './loading.module.scss';

export const Loading = () => {
  const { t } = useTranslation();

  return (
    <>
      <Content includeHeader={false}>
        <div className="">
          <div className="d-flex align-items-center justify-content-center">
            <div className={styles.loading}>
              <img src={FlyingAirplane} alt="airplane" width="70"></img>
              <p className={`lead ${styles.loading}`}>{t('general.loading')}</p>
            </div>
          </div>
        </div>
      </Content>
    </>
  );
};
