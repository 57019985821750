import { APIResponse } from '../model/APIResponse';
import { FlightAttendant } from '../model/FlightAttendant';
import { Round } from '../model/Round';
import { Timetable } from '../model/Timetable';
import TimetableService from '../services/TimetableService';
import Utils from '../view/components/utils/Utils';

export class TimetableController {
  private timetables: Timetable[] = [];
  private timetableService: TimetableService;

  constructor() {
    this.timetableService = new TimetableService();
  }

  public async postTimetablePreview(round: Round) {
    try {
      return this.timetableService.postTimetablePreview(round);
    } catch (error) {
      console.log('TimetableController Error - ', error);
    }
  }

  public async postRoundTimetable(round: Round) {
    try {
      return this.timetableService.postRoundTimetable(round);
    } catch (error) {
      console.log('TimetableController Error - ', error);
    }
  }

  public async postTimetables(timetable: Timetable) {
    try {
      return this.timetableService.postTimetables(timetable);
    } catch (error) {
      console.log('TimetableController Error - ', error);
    }
  }

  public async getRoundTimetable(employeeId: number, roundId: number) {
    try {
      return this.timetableService.getRoundTimetable(employeeId, roundId);
    } catch (error) {
      console.log('TimetableController Error - ', error);
    }
  }

  public async getTimetablesExcel(
    employeeId: number,
    roundNo: number,
    year: number,
    language: string
  ) {
    try {
      let languageNum = Utils.getLangNum(language);
      return this.timetableService.getTimetablesExcel(employeeId, roundNo, year, languageNum);
    } catch (error) {
      console.log('TimetableController Error - ', error);
    }
  }

  public async getDownloadAllowed(employeeId: number, roundNo: number, year: number) {
    try {
      const response = await this.timetableService.getDownloadAllowed(employeeId, roundNo, year);
      if (response !== undefined || response !== null) {
        return response.data as boolean;
      }
    } catch (error) {
      console.log('TimetableController Error - ', error);
    }
  }

  public async getPreviewFATimeSlot(employeeId: number, roundId: number) {
    try {
      return this.timetableService.getPreviewFATimeSlot(employeeId, roundId);
    } catch (error) {
      console.log('TimetableController Error - ', error);
    }
  }

  public async postCalculateAllotedTime(round: Round) {
    try {
      return this.timetableService.postCalculateAllotedTime(round);
    } catch (error) {
      console.log('TimetableController Error - ', error);
    }
  }
}
