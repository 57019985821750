import {
  Entitlements,
  FlightAttendants,
  GetActiveFlightAttendantsByBase,
  GetActiveFlightAttendantsCountByBase,
  GetActiveRoundsForBase,
  GetCurrentFA,
  GetFAWithoutTimetableByBase,
  GetFlightAttendantByEmployeeId,
  GetFlightAttendantsByBase,
  GetFlightAttendantsCountByBase,
  GetLastUpdatedFAList,
  GetR2FlightAttendantsByBase,
  GetR2FlightAttendantsCountByBase,
  ImportFlightAttendant,
} from '../config/endpoints-config';
import { Entitlement } from '../model/Entitlement';
import { FlightAttendant } from '../model/FlightAttendant';
import GeneralService from './GeneralService';

export default class FAService extends GeneralService {
  async getActiveRoundsForBase(baseInitials: string) {
    let result = this.get(GetActiveRoundsForBase(baseInitials!));
    return result;
  }

  async getFAsByBase(baseId: number, year: number) {
    let params = {
      baseId: baseId,
      year: year,
    };
    let result = this.getWithParams(GetFlightAttendantsByBase, params);
    return result;
  }

  async getFAsByBaseCount(baseId: number) {
    let params = {
      baseId: baseId,
    };
    let result = this.getWithParams(GetFlightAttendantsCountByBase, params);
    return result;
  }

  async getActiveFAsByBaseCount(baseId: number) {
    let params = {
      baseId: baseId,
    };
    let result = this.getWithParams(GetActiveFlightAttendantsCountByBase, params);
    return result;
  }

  async getActiveFAsByBase(baseId: number) {
    let params = {
      baseId: baseId,
    };
    let result = this.getWithParams(GetActiveFlightAttendantsByBase, params);
    return result;
  }

  async getR2FlightAttendantsCountByBase(baseId: number) {
    let params = {
      baseId: baseId,
    };
    let result = this.getWithParams(GetR2FlightAttendantsCountByBase, params);
    return result;
  }

  async getR2FlightAttendantsByBase(baseId: number) {
    let params = {
      baseId: baseId,
    };
    let result = this.getWithParams(GetR2FlightAttendantsByBase, params);
    return result;
  }

  async updateFA(fa: FlightAttendant) {
    let result = this.putWithBodyId(FlightAttendants, fa, fa.employeeNo);
    return result;
  }

  async updateEntitlement(ent: Entitlement, employeeId: string) {
    let params = {
      employeeId: employeeId,
    };
    let result = this.putWithBodyIdAndParams(Entitlements, ent, ent.id, params);
    return result;
  }

  async getLastUpdatedFAList(baseId: number) {
    let result = this.getWithId(GetLastUpdatedFAList, baseId);
    return result;
  }

  async getFA(id: number) {
    let result = this.getWithId(FlightAttendants, id);
    return result;
  }

  async importFlightAttendantByEmployeeId(employeeId: string, baseId: number, year: number) {
    let params = {
      employeeId: employeeId,
      baseId: baseId,
      year: year,
    };
    let result = this.getWithParams(ImportFlightAttendant, params);
    return result;
  }

  async getFlightAttendantByEmployeeId(employeeId: string, baseId: number, year: number) {
    let params = {
      employeeId: employeeId,
      baseId: baseId,
      year: year,
    };
    let result = this.getWithParams(GetFlightAttendantByEmployeeId, params);
    return result;
  }
  async getFAWithoutTimetableByBase(roundId: number, baseId: number) {
    let params = {
      roundId: roundId,
      baseId: baseId,
    };
    let result = this.getWithParams(GetFAWithoutTimetableByBase, params);
    return result;
  }

  async getCurrent() {
    return this.getWithParams(GetCurrentFA, null);
  }
}
