import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { TimetableController } from '../../../../controller/TimetablesController';
import { Base } from '../../../../model/Base';
import { Round } from '../../../../model/Round';
import { Timetable } from '../../../../model/Timetable';
import { BID_YEAR } from '../../../components/utils/Constants';
import { ResultAlert } from '../../../components/utils/alerts/result-alert';
import { Content } from '../../../components/utils/content/content';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';
import { useUserContext } from '../../../components/utils/context/user-context-provider';
import { AddFaTimetableModal } from '../../../components/utils/modals/add-fa-timetable';
import { TimetableTable } from './timetable-table';

interface RoundTimetableProps {}

export const RoundTimetable = (props: RoundTimetableProps) => {
  const { t } = useTranslation();
  let location = useLocation();
  const userCtxt = useUserContext();
  const { employeeId } = userCtxt;
  const gralCtxt = useGeneralContext();
  const { selectedLanguage } = gralCtxt;

  const timetableCtrl = new TimetableController();
  const [timetable, setTimetable] = useState<Timetable[]>();

  const [base, setBase] = useState<Base>(new Base());
  const [round, setRound] = useState<Round>(new Round());

  const [timetableVisible, setTimetableVisible] = useState<boolean>(true);
  const [generatingTimetable, setGeneratingTimetable] = useState<boolean>(true);
  const [addedFA, setAddedFA] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const successRef = useRef<null | HTMLDivElement>(null);

  const fetchTimetable = async (round: Round) => {
    try {
      timetableCtrl
        .getRoundTimetable(Number(employeeId), round.id)
        .then(function (response) {
          // handle success
          let timetable = response?.data as Timetable[];
          setTimetable(timetable);
        })
        .catch(function (error) {
          // handle message
          console.error('HandlePreviewTimetable', error);
        })
        .finally(() => {
          setGeneratingTimetable(false);
        });
    } catch (e) {
      //To do: handle error.
      console.log(e);
    }
  };

  const downloadFile = async () => {
    try {
      timetableCtrl
        .getTimetablesExcel(Number(employeeId), round.roundNo, BID_YEAR, selectedLanguage)
        .then((response) => {
          const type = response?.headers['content-type'];
          const blob = new Blob([response?.data], { type: type });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'file.xlsx';
          link.click();
        })
        .catch(function (error) {
          // handle message
          console.error('getTimetableExcel', error);
        })
        .finally(() => {});
    } catch (e) {
      //To do: handle error.
      console.log(e);
    }
  };

  useEffect(() => {
    if (round !== undefined && base !== undefined && round.id !== 0 && base.id !== 0) {
      fetchTimetable(round);
    }
  }, [round, base]);

  useEffect(() => {
    if (location.state !== null) {
      let state: any = location.state;
      setBase(state.base);
      setRound(state.round);
    }
  }, [location]);

  useEffect(() => {
    if (addedFA && round !== undefined) {
      fetchTimetable(round);
      setAddedFA(false);
    }
  }, [addedFA, round]);

  return (
    <>
      <Content
        includeHeader={true}
        title={t('menu.cpm.bases')}
        subtitle={base?.name ? base.initials + ' - ' + t(`bases.names.${base.name}`) : ''}>
        <div className="row no_print">
          <div className="col-md-4 col-sm-12 align-self-center mb-2 mb-md-0">
            <span className="fs-5 fw-bold text-champagne">
              {t('bases.timetable.title', { roundNo: round.roundNo })}
            </span>
          </div>
          <div className="col-md-8 col-sm-12 d-flex justify-content-md-end">
            <button
              type="button"
              className="btn btn-outline-primary btn-outline-champagne me-2"
              data-bs-toggle="modal"
              data-bs-target="#addBidTimeModal">
              <i className="bi bi-person-plus"></i>
              {t('general.buttons.add_fa_bidding_time')}
            </button>

            <button
              type="button"
              className="btn btn-primary btn-champagne me-2"
              data-bs-toggle="modal"
              data-bs-target="#impersonateFaModal">
              <i className="bi bi-people"></i>
              {t('menu.cpm.impersonate_fa')}
            </button>

            {/*<button type="button" className="btn btn-outline-primary btn-outline-champagne" onClick={downloadFile }>*/}
            {/*    <i className="bi bi-file-earmark-arrow-down me-0"></i>*/}
            {/*</button>*/}
          </div>
        </div>
        <AddFaTimetableModal
          timetable={timetable}
          tBase={base}
          tRound={round}
          setSuccessMessage={setSuccessMessage}
          setAddedFA={setAddedFA}></AddFaTimetableModal>
        <TimetableTable
          isPreview={false}
          timetableVisible={timetableVisible}
          generatingTimetable={generatingTimetable}
          timetable={timetable}
          base={base}></TimetableTable>
        <ResultAlert
          resultMessage={successMessage}
          setResultMessage={setSuccessMessage}
          resultRef={successRef}
          isSuccess={true}></ResultAlert>
      </Content>
    </>
  );
};
