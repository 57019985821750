//Module imports
import { useIsAuthenticated } from '@azure/msal-react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useEntitlementsForEmployee } from '../../../../hooks/EntitlementHooks';
import { useCurrentFlightAttendant } from '../../../../hooks/FlightAttendantHooks';
import { useActiveRoundForBase } from '../../../../hooks/RoundHooks';
import { useRoundTimetable } from '../../../../hooks/TimetableHooks';
import { FA_PATHS, INACTIVE, ROUND_1, ROUND_2 } from '../Constants';
//Resources imports
//Component imports
import AccessValidation from '../role_based_access/AccessValidation';

interface FAItemsProps {}

export const FAItems = (props: FAItemsProps) => {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const { data: currAtt } = useCurrentFlightAttendant();
  const { data: activeRound } = useActiveRoundForBase(currAtt?.base.initials);
  const { data: entitlement } = useEntitlementsForEmployee(
    currAtt?.employeeNo,
    activeRound ? activeRound?.year : undefined
  );
  const { data: timetable } = useRoundTimetable(currAtt?.employeeNo, activeRound?.id);

  return (
    <>
      <div className={`nav_list ${isAuthenticated ? '' : 'd-none'}`}>
        <NavLink to={FA_PATHS.home} className="nav_link">
          <i className="fs-3 bi bi-house nav_icon"></i>
          <span className="nav_name">{t('menu.fa.home')}</span>
        </NavLink>
        <div
          className={` ${
            currAtt === undefined ||
            entitlement === undefined ||
            timetable === undefined ||
            currAtt.status === INACTIVE
              ? 'd-none'
              : ''
          }`}>
          <NavLink
            to={FA_PATHS.vac_bid}
            className={`nav_link ${activeRound?.roundNo === ROUND_1 ? '' : 'd-none'}`}>
            <i className="fs-3 bi bi-calendar-range nav_icon"></i>
            <span className="nav_name">{t('menu.fa.vacation_bid')}</span>
          </NavLink>
          <NavLink
            to={FA_PATHS.stat_bid}
            className={`nav_link ${activeRound?.roundNo === ROUND_2 ? '' : 'd-none'}`}>
            <i className="fs-3 bi bi-calendar-date nav_icon"></i>
            <span className="nav_name">{t('menu.fa.statutory_days_bid')}</span>
          </NavLink>
          <NavLink to={FA_PATHS.days_availability} className="nav_link text-wrap">
            <i className="fs-3 bi bi-calendar3 nav_icon"></i>
            <span className="nav_name">{t('menu.days_availability')}</span>
          </NavLink>
        </div>

        <NavLink to={FA_PATHS.faq} className="nav_link">
          <i className="fs-3 bi bi-question-circle nav_icon"></i>
          <span className="nav_name">{t('menu.fa.faq')}</span>
        </NavLink>
      </div>
    </>
  );
};
