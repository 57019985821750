import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { CPM_PATHS } from '../Constants';
import { useGeneralContext } from '../context/general-context-provider';
import { Header } from '../header/header';
import { Callout } from './callout';
import styles from './content.module.scss';

interface FloatingAlertProps {
  children?: React.ReactNode;
}

export const FloatingAlert = (props: FloatingAlertProps) => {
  const generalCtxt = useGeneralContext();
  const { t } = useTranslation();
  const { children } = props;

  const [visible, setVisible] = useState<boolean>(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className={`alert alert-danger alert-dismissible border-start border-4 border-end-0 border-bottom-0 border-top-0 border-danger rounded-0 m-4 position-fixed bottom-0 start-0  w-sm-100 w-md-50 offcanvas bg-danger-subtle ${
          visible ? 'show' : ''
        }`}
        role="alert">
        <h1 className="alert-heading text-jazz-red display-1">
          <i className="bi bi-megaphone me-2"></i>
        </h1>
        {children}
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            setVisible(false);
          }}
          aria-label="Close"></button>
      </div>
    </>
  );
};
