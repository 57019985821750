import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useGetBase } from '../../../../hooks/BaseHooks';
import { useEntitlementsForEmployee } from '../../../../hooks/EntitlementHooks';
import { useCurrentFlightAttendant } from '../../../../hooks/FlightAttendantHooks';
import { useActiveRoundForBase } from '../../../../hooks/RoundHooks';
import { useSplitsForEmployee } from '../../../../hooks/SplitHooks';
import {
  useProcessingSBLForEmployee,
  useSblsForEmployee,
} from '../../../../hooks/StandingBidLineHooks';
import { BID_YEAR, ROUND_1, ROUND_2 } from '../../../components/utils/Constants';
import { R2EnrollmentAlert } from '../../../components/utils/alerts/r2-enrollment-alert';
import { Content } from '../../../components/utils/content/content';
import { Loading } from '../../../components/utils/loading/loading';
import { BidResultGroup } from '../../../fa/common/bid-result/bid-result-group';
import { TimetableAlert } from '../../../fa/common/timetable-alert';
import { BackupResultRow } from '../../../fa/feature/standing-bid/backup-result-row';

export const BidSummary = () => {
  const { t } = useTranslation();
  let location = useLocation();

  // TODO: if at some point we want non-static years get them from the location state
  const year = BID_YEAR;

  const { faId } = ((location && location.state) || {
    faId: undefined,
  }) as { faId: number };

  // Get data
  const { data: currAtt } = useCurrentFlightAttendant(faId);
  const { data: base } = useGetBase(currAtt?.base.id);
  const { data: entitlement } = useEntitlementsForEmployee(currAtt?.employeeNo, year);
  const { data: activeRound } = useActiveRoundForBase(currAtt?.base.initials);
  const { data: splits } = useSplitsForEmployee(faId, year);
  const { data: processingSBL } = useProcessingSBLForEmployee(
    faId,
    BID_YEAR,
    activeRound?.roundNo || ROUND_2
  );
  const { data: r1Backups } = useSblsForEmployee(faId, year, ROUND_1);
  const { data: r2Backups } = useSblsForEmployee(faId, year, ROUND_2);
  const round1 = base!.rounds.filter((r) => r.roundNo === 1).at(0);
  const round2 = base!.rounds.filter((r) => r.roundNo === 2).at(0);

  if (currAtt === undefined || entitlement === undefined) {
    return (
      <Content includeHeader={true} title={t('menu.cpm.bid_summary')}>
        <Loading />
      </Content>
    );
  }

  return (
    <Content
      includeHeader={true}
      title={t('bases.bid_summary.page_title')}
      subtitle={
        <Trans
          i18nKey={'bases.bid_summary.page_subtitle'}
          components={{ bold: <strong /> }}
          values={{
            faName: currAtt.name,
            faId: currAtt.employeeNo,
            baseInitials: base?.initials,
            year: year,
          }}
        />
      }>
      <div className="row">
        {/*Round 1*/}
        <div className={`row ${round1?.submitted ? '' : 'd-none'}`}>
          <div className="col-12">
            <h4 className="text-champagne fw-bold">
              <Trans i18nKey="vacation.round_1_subtitle" />
            </h4>
            <TimetableAlert round={round1!} currAtt={currAtt!}></TimetableAlert>
            <R2EnrollmentAlert
              currAtt={currAtt}
              entitlement={entitlement!}
              showChangeButton={false}
            />
            {(currAtt && r1Backups && r1Backups.length > 0 && (
              <div className="accordion" id="round1sbAccordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne">
                      <Trans components={{ bold: <b /> }} i18nKey="standing.results_header" />
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#round1sbAccordion">
                    <div className="accordion-body">
                      {r1Backups?.map((backup, _) => (
                        <BackupResultRow
                          key={backup.bidNo}
                          index={backup.bidNo}
                          backup={backup}
                          baseId={currAtt.baseId}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )) ||
              ''}
          </div>
        </div>

        {/*Round 2*/}
        <div className={`row mt-5 ${round2?.submitted ? '' : 'd-none'}`}>
          <div className="col-12">
            <h4 className="text-champagne fw-bold">
              <Trans i18nKey="statutory.round_2_subtitle" />
            </h4>
            <TimetableAlert round={round2!} currAtt={currAtt!}></TimetableAlert>
            {(currAtt && r2Backups && r2Backups.length > 0 && (
              <div className="accordion" id="round2sbAccordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo">
                      <Trans components={{ bold: <b /> }} i18nKey="standing.results_header" />
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#round2sbAccordion">
                    <div className="accordion-body">
                      {r2Backups?.map((backup, _) => (
                        <BackupResultRow
                          key={backup.bidNo}
                          index={backup.bidNo}
                          backup={backup}
                          baseId={currAtt.baseId}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )) ||
              ''}
          </div>
        </div>

        {(currAtt && splits && splits.length > 0 && (
          <div className={`row mt-5`}>
            <div className="col-12">
              <h4 className="text-champagne fw-bold">
                <Trans i18nKey="home.fa.splits_details" />
              </h4>
              {processingSBL && (
                <div className="alert alert-info" role="alert">
                  <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
                  <span role="status">{t('standing.wait_processing_backups')}</span>
                </div>
              )}
              {!processingSBL &&
                splits?.map((group, idx) => (
                  <BidResultGroup index={idx + 1} splits={group} baseId={currAtt.baseId} />
                ))}
            </div>
          </div>
        )) ||
          ''}
      </div>
    </Content>
  );
};
