//Module imports
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { loginRequest } from '../../../../config/auth-config';
import { useSetLanguagePref } from '../../../../hooks/LanguageHooks';
//Component imports
import { CPM, DEVELOPMENT, FA, SIGN_OUT_MODAL, STAGING } from '../Constants';
import Utils from '../Utils';
import { useGeneralContext } from '../context/general-context-provider';
import { useUserContext } from '../context/user-context-provider';
import { ConfirmationModal } from '../modals/confirmation-modal';
import { ImpersonateFAModal } from '../modals/impersonate-fa';
import { CPMItems } from './cpm-items';
import { FAItems } from './fa-items';

//Resources imports

interface MenuProps {
  setMenuToggled: (value: boolean) => void;
}

export const Menu = (props: MenuProps) => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const generalCtxt = useGeneralContext();
  const userCtxt = useUserContext();
  const { isMenuToggled, selectedLanguage, languages } = generalCtxt;
  const { name, employeeId, email } = userCtxt;
  const isAuthenticated = useIsAuthenticated();
  const { mutate: setLanguagePref } = useSetLanguagePref();
  const { role } = useUserContext();
  const reactAppEnv: string = process.env.REACT_APP_ENVIRONMENT ?? '';

  //Methods

  const handleSelectChange = (languageKey: string) => {
    setLanguagePref({ language: Utils.getLangNum(languageKey), employeeNo: Number(employeeId) });
  };

  const handleMenuToggle = () => {
    props.setMenuToggled(!isMenuToggled);
  };

  const handleLogout = () => {
    if (process.env.NODE_ENV === DEVELOPMENT) {
      instance
        .logoutPopup({
          postLogoutRedirectUri: null,
          mainWindowRedirectUri: '/', // redirects the top level app after logout
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      instance
        .logoutRedirect({
          postLogoutRedirectUri: null,
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const handleLoginRedirect = (instance: {
    loginRedirect: (arg0: { scopes: string[] }) => Promise<any>;
  }) => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error('handleLoginRedirect', e);
    });
  };

  const handleLoginPopup = (instance: {
    loginPopup: (arg0: { scopes: string[] }) => Promise<any>;
  }) => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.error('handleLoginPopup', e);
    });
  };

  return (
    <div>
      <header
        className={`header no_print ${
          reactAppEnv === DEVELOPMENT || reactAppEnv === STAGING ? 'bg-jazz' : ''
        }`}
        id="header">
        <div
          className={`header_toggle ${isMenuToggled ? 'body-pd' : ''}`}
          onClick={handleMenuToggle}>
          <i className={`${isMenuToggled ? 'bi bi-x' : 'bi bi-list'}`} id="header-toggle"></i>
        </div>
        <div className={`dropdown d-flex ${isAuthenticated ? '' : 'd-none'}`}>
          <button
            className="btn btn-link link-light dropdown-toggle justify-content-end d-flex align-items-center text-light-grey text-decoration-none"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false">
            <i className={`fs-3 bi-person-circle me-2`}></i>
            <span className="fs-6 ms-1 d-none d-sm-inline align-middle text-nowrap">
              {reactAppEnv === DEVELOPMENT ? 'DEV - ' : ''}
              {reactAppEnv === STAGING ? 'UAT - ' : ''}
              {name}
            </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end">
            <li>
              <h6 className="dropdown-header">
                <i className="bi bi-translate nav_icon me-2"></i>
                {t('menu.language')}
              </h6>
            </li>
            {Object.keys(languages).map((key: string) => (
              <li key={key}>
                <span
                  className={`dropdown-item ${selectedLanguage === key ? 'active' : ''}`}
                  onClick={() => handleSelectChange(key)}>
                  {languages[key].name}
                </span>
              </li>
            ))}
            <li>
              <h6 className="dropdown-header">
                <i className="bi bi-person-gear nav_icon me-2"></i>
                {t('menu.account')}
              </h6>
            </li>
            <li className={`${reactAppEnv !== DEVELOPMENT ? 'd-none' : ''}`}>
              <span className={`dropdown-header pt-0`}>{email}</span>
            </li>
            <li>
              <span
                className={`dropdown-item btn btn-link ${role === CPM ? '' : 'd-none'}`}
                data-bs-toggle="modal"
                data-bs-target="#impersonateFaModal">
                {t('menu.cpm.impersonate_fa')}
              </span>
            </li>
            <li>
              <span
                className={`dropdown-item btn btn-link `}
                data-bs-toggle="modal"
                data-bs-target={`#${SIGN_OUT_MODAL}Modal`}>
                {t('auth.sign_out')}
              </span>
            </li>
          </ul>
        </div>
        <button
          className={`btn btn-link link-light justify-content-end d-flex align-items-center text-light-grey text-decoration-none  ${
            !isAuthenticated ? '' : 'd-none'
          }`}
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onClick={() => handleLoginRedirect(instance)}>
          <i className={`fs-3 bi-box-arrow-in-left me-2`}></i>
          <span className="fs-6 ms-1 d-none d-sm-inline align-middle text-nowrap">
            {t('auth.sign_in')}
          </span>
        </button>
      </header>
      <div className="header-spacer" />

      <ConfirmationModal
        modalType={SIGN_OUT_MODAL}
        submitAction={() => handleLogout()}
        includeHeader={false}>
        <div className="modal-body text-center">{t('general.modals.body.sign_out')}</div>
      </ConfirmationModal>
      <ImpersonateFAModal></ImpersonateFAModal>
      <div className={`l-navbar  no_print ${isMenuToggled ? 'expand' : ''}`} id="nav-bar">
        <nav className="nav">
          <div>
            <div className="nav_link">
              <i className="fs-3 bi bi-calendar-fill nav_logo-icon"></i>
              <span className="nav_logo-name">{t('menu.title')}</span>
            </div>
            {userCtxt.role === FA ? <FAItems></FAItems> : ''}
            {userCtxt.role === CPM ? <CPMItems></CPMItems> : ''}
          </div>
        </nav>
      </div>
    </div>
  );
};
