import { Round } from '../model/Round';
import RoundsService from '../services/RoundsService';

export class RoundsController {
  private rounds: Round[] = [];
  private roundsService: RoundsService;

  constructor() {
    this.roundsService = new RoundsService();
  }

  public async updateRound(round: Round) {
    try {
      return this.roundsService.updateRound(round);
    } catch (error) {
      console.log('RoundsController Error - ', error);
    }
  }

  public async extendRound(round: Round) {
    try {
      return this.roundsService.extendRound(round);
    } catch (error) {
      console.log('RoundsController Error - ', error);
    }
  }

  public async getActiveRoundForBase(baseInitials: string) {
    try {
      const response = await this.roundsService.getActiveRoundForBase(baseInitials);
      if (response !== undefined || response !== null) return response?.data as Round;
    } catch (error) {
      console.log('RoundsController Error - ', error);
    }
  }
}
