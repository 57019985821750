import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Split } from '../../../../model/Split';
import Utils from '../../../components/utils/Utils';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';

type BidResultRowProps = {
  split: Split;
  baseId: number;
  committed: boolean;
};

export function BidResultRow({ split, baseId, committed }: BidResultRowProps) {
  const { t } = useTranslation();
  const { selectedLanguage } = useGeneralContext();

  const attachedDays = split.attachedDaysEnd + split.attachedDaysStart;
  const vacDays = split.totalDays - attachedDays;

  const data: {
    transkey: string;
    content: ReactNode;
    defaultSize?: number;
    mdSize?: number;
    xxlSize?: number;
  }[] = [
    {
      transkey: 'statutory.table.start_date',
      content: <>{Utils.formatDate(split.startDate.toString(), baseId, selectedLanguage)}</>,
    },
    {
      transkey: 'statutory.table.end_date',
      content: <>{Utils.formatDate(split.endDate.toString(), baseId, selectedLanguage)}</>,
    },
    {
      transkey: 'statutory.table.total_days',
      content: (
        <Trans
          i18nKey="statutory.table.total_days_content"
          values={{ r1Total: vacDays, r2Total: attachedDays, total: split.totalDays }}
        />
      ),
    },
    {
      transkey: 'general.modals.body.is_credit_carry',
      content: <>{t(split.isCreditCarryIn ? 'general.buttons.yes' : 'general.buttons.no')}</>,
    },
    {
      transkey: 'statutory.table.status',
      content: (
        <span className={`badge ${committed ? 'text-bg-success' : 'text-bg-champagne'} p-2`}>
          {t(committed ? 'general.status.committed' : 'general.status.modifying')}
        </span>
      ),
    },
  ];

  return (
    <div className="alert alert-secondary print-break-inside-avoid">
      <div className="row">
        {data.map((d, idx) => (
          <div
            key={idx}
            className={`col-${d.defaultSize || 6} col-md-${d.mdSize || 4} col-xxl-${
              d.xxlSize || 2
            } text-xxl-center mb-2 mb-xxl-0`}>
            <span className="fw-bold d-block">
              <Trans i18nKey={d.transkey} />
            </span>
            {d.content}
          </div>
        ))}
      </div>
    </div>
  );
}
