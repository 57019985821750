import moment from 'moment';
import { Moment } from 'moment-timezone';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useAwardedVacationForEmployee } from '../../../../hooks/AwardedVacationHooks';
import {
  useEntitlementsForEmployee,
  useUpdateR2Preference,
} from '../../../../hooks/EntitlementHooks';
import { useCurrentFlightAttendant } from '../../../../hooks/FlightAttendantHooks';
import { useActiveRoundForBase } from '../../../../hooks/RoundHooks';
import { useSplitsForEmployee } from '../../../../hooks/SplitHooks';
import { useRoundTimetable } from '../../../../hooks/TimetableHooks';
import {
  BID_YEAR,
  CHANGE_R2_MODAL,
  CPM,
  CPM_PATHS,
  FA_PATHS,
  QUESTIONS_EMAIL,
  ROUND_1,
} from '../../../components/utils/Constants';
import Utils from '../../../components/utils/Utils';
import { R2EnrollmentAlert } from '../../../components/utils/alerts/r2-enrollment-alert';
import { R2FloatingAlert } from '../../../components/utils/alerts/r2-floating-alert';
import { Content } from '../../../components/utils/content/content';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';
import { useUserContext } from '../../../components/utils/context/user-context-provider';
import { Loading } from '../../../components/utils/loading/loading';
import { ConfirmationModal } from '../../../components/utils/modals/confirmation-modal';
import { EarlyAlert } from '../../common/early-alert/early-alert';
import { VacationTable } from './vacation-table';
import styles from './vacation.module.scss';

interface VacationProps {}

export const Vacation = (props: VacationProps) => {
  const { t } = useTranslation();
  const { role } = useUserContext();
  const { selectedLanguage } = useGeneralContext();
  const location = useLocation();
  const impersonateFaEmpNo =
    location.state !== null
      ? (location.state as { impersonateFaEmpNo: number }).impersonateFaEmpNo
      : undefined;
  const { data: currAtt } = useCurrentFlightAttendant(impersonateFaEmpNo!);
  const { data: activeRound } = useActiveRoundForBase(currAtt?.base.initials);
  const { data: timetable } = useRoundTimetable(currAtt?.employeeNo, activeRound?.id);
  const { data: entitlement, isFetching: fetchingEntitlement } = useEntitlementsForEmployee(
    currAtt?.employeeNo,
    BID_YEAR
  );
  const { data: splits } = useSplitsForEmployee(currAtt?.employeeNo, BID_YEAR);
  const { data: awardedVacation } = useAwardedVacationForEmployee(currAtt?.employeeNo, BID_YEAR);
  const {
    mutate: updateR2Preference,
    isError: isR2PrefUpdateError,
    error: r2PrefUpdateError,
    isLoading: r2PrefUpdateInProgress,
  } = useUpdateR2Preference();

  const r2PrefLoading = r2PrefUpdateInProgress || fetchingEntitlement;
  const impersonateFa = {
    isImpersonate: impersonateFaEmpNo !== undefined,
    faName: currAtt?.name!,
    faEmpNo: currAtt?.employeeNo!,
  };

  /* Time-Updated States / Handling Refresh Interval */

  const [currentTime, setCurrentTime] = useState<Moment>(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const startMoment =
    (timetable?.startTime !== undefined && moment(timetable?.startTime)) || undefined;
  const endMoment = (timetable?.endTime !== undefined && moment(timetable?.endTime)) || undefined;
  const minutesLeft =
    endMoment && !endMoment.isBefore(currentTime)
      ? endMoment.diff(currentTime, 'minutes')
      : undefined;
  const secondsLeft =
    endMoment && !endMoment.isBefore(currentTime)
      ? endMoment.diff(currentTime, 'seconds')
      : undefined;
  const tooEarly = startMoment !== undefined && startMoment.isAfter(currentTime);
  const bidEnded = endMoment !== undefined && endMoment.isBefore(currentTime);

  if (role === CPM && location.state === null) {
    return <Navigate to={CPM_PATHS.home} replace={true} />;
  }

  if (
    activeRound?.id === undefined ||
    activeRound?.roundNo !== ROUND_1 ||
    !activeRound?.submitted
  ) {
    return (
      <Content
        includeHeader={true}
        title={t('menu.fa.vacation_bid')}
        subtitle={t('vacation.round_1_subtitle')}
        impersonateFA={impersonateFa}>
        <div className="alert alert-secondary" role="alert">
          <i className="bi bi-info-circle-fill me-2"></i>
          {t('general.errors.no_active_round')}
        </div>
        <VacationTable
          bidEnded={bidEnded}
          entitlement={entitlement}
          splits={splits || []}
          awardedVacation={awardedVacation || []}
          currAtt={currAtt}
          activeRound={activeRound}
        />
      </Content>
    );
  }

  if (currAtt !== undefined && timetable === undefined) {
    return (
      <Content
        includeHeader={true}
        title={t('menu.fa.vacation_bid')}
        subtitle={t('vacation.round_1_subtitle')}
        impersonateFA={impersonateFa}>
        <div className="alert alert-secondary text-secondary" role="alert">
          <i className="bi bi-info-circle-fill me-2"></i>
          <Trans
            components={{ strong: <strong className="text-secondary" /> }}
            values={{
              roundNum: activeRound?.roundNo,
              email: QUESTIONS_EMAIL,
            }}
            i18nKey="home.fa.info_body_error"
          />
        </div>
      </Content>
    );
  }

  if (currAtt === undefined || timetable === undefined) {
    return (
      <Content
        includeHeader={true}
        title={t('menu.fa.vacation_bid')}
        subtitle={t('vacation.round_1_subtitle')}
        impersonateFA={{
          isImpersonate: impersonateFaEmpNo !== undefined,
          faName: currAtt?.name!,
          faEmpNo: currAtt?.employeeNo!,
        }}>
        <Loading />
      </Content>
    );
  }

  if (tooEarly) {
    return (
      <Content
        includeHeader={true}
        title={t('menu.fa.vacation_bid')}
        subtitle={t('vacation.round_1_subtitle')}
        impersonateFA={impersonateFa}>
        <EarlyAlert
          headerKey="vacation.early_alert_header"
          bodyKey="vacation.early_alert_body"
          linkTo={FA_PATHS.vac_bid_standing}
          linkKey="vacation.go_to_standing_bid"
          values={{
            scheduledDate:
              Utils.formatDate(
                timetable?.startTime?.toString(),
                currAtt?.base.id,
                selectedLanguage
              ) || '...',
            startTime:
              Utils.formatTime(timetable?.startTime?.toString(), currAtt?.base.id) || '...',
            endTime: Utils.formatTime(timetable?.endTime?.toString(), currAtt?.base.id) || '...',
            currentTime:
              selectedLanguage === 'fr'
                ? Utils.formatTime(currentTime.toDate().toUTCString(), currAtt?.base.id) +
                  ' le ' +
                  Utils.formatOnlyDateUTC(
                    currentTime.toDate().toUTCString(),
                    currAtt?.base.id,
                    selectedLanguage
                  )
                : Utils.formatDateTime(
                    currentTime.toDate().toUTCString(),
                    currAtt?.base.id,
                    selectedLanguage
                  ),
          }}
          roundNo={ROUND_1}
          impersonateFaEmpNo={impersonateFaEmpNo}
        />
      </Content>
    );
  }

  return (
    <>
      <Content
        includeHeader={true}
        title={t('menu.fa.vacation_bid')}
        subtitle={t('vacation.round_1_subtitle')}
        impersonateFA={impersonateFa}>
        {bidEnded && (
          <div className="alert alert-danger d-print-none" role="alert">
            <i className={`fa-solid fa-circle-xmark  ${styles.alert_icon}`}></i>
            <Trans i18nKey="vacation.allotted_time_passed" />
          </div>
        )}
        <div className="row">
          <div className="col">
            {!bidEnded && (
              <div
                className="alert alert-champagne d-flex d-md-block d-print-none flex-column"
                role="alert">
                <div className="row">
                  <div className="col-12 col-xl-6">
                    <Trans
                      components={{
                        bold: <b />,
                      }}
                      values={{
                        minutes: minutesLeft,
                        seconds: secondsLeft,
                        allottedMinutes: activeRound ? activeRound.allottedTime : '...',
                      }}
                      i18nKey={
                        minutesLeft !== undefined
                          ? minutesLeft > 0
                            ? 'statutory.time_left_template'
                            : 'statutory.time_seconds_left_template'
                          : 'statutory.time_left_complete'
                      }
                    />
                  </div>
                  <div className="col-12 col-xl-6 text-xl-end">
                    {(currAtt?.base?.initials && (
                      <Trans
                        components={{ bold: <b /> }}
                        values={{
                          base: currAtt?.base?.initials,
                          currentTime:
                            currAtt?.base !== undefined
                              ? Utils.formatDateTime(
                                  currentTime.toDate().toUTCString(),
                                  currAtt.base.id,
                                  selectedLanguage
                                )
                              : null,
                        }}
                        i18nKey="vacation.base_time_template"
                      />
                    )) ||
                      t('vacation.base_load_fail')}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <R2EnrollmentAlert
          currAtt={currAtt}
          entitlement={entitlement!}
          showChangeButton={
            activeRound !== undefined && activeRound.roundNo === ROUND_1
          }></R2EnrollmentAlert>
        <ConfirmationModal
          modalType={CHANGE_R2_MODAL}
          employeeId={currAtt.employeeNo}
          submitAction={() => {
            updateR2Preference({
              employeeId: currAtt!.employeeNo,
              year: activeRound!.year,
              r2Preference: !entitlement?.partR2,
              baseId: currAtt!.baseId,
            });
          }}
          includeHeader={true}>
          <span>
            <Trans
              i18nKey={
                entitlement?.partR2
                  ? 'general.modals.body.r2_opt_out_confirm'
                  : 'general.modals.body.r2_opt_in_confirm'
              }
            />
          </span>
        </ConfirmationModal>

        <VacationTable
          bidEnded={bidEnded}
          entitlement={entitlement}
          splits={splits || []}
          awardedVacation={awardedVacation || []}
          currAtt={currAtt}
          activeRound={activeRound}
        />
        {(!entitlement?.partR2 && activeRound.roundNo === ROUND_1 && (
          <R2FloatingAlert currAtt={currAtt}></R2FloatingAlert>
        )) ||
          ''}
      </Content>
    </>
  );
};
