import moment, { Moment } from 'moment-timezone';

import { FormError } from '../../../model/FormError';
import { Split } from '../../../model/Split';
import { CALGARY, HALIFAX, MONTREAL, TORONTO, VANCOUVER } from './Constants';

export default class Utils {
  public static getLangNum(langKey: string) {
    switch (langKey) {
      case 'en':
        return 0;
      case 'fr':
        return 1;
      default:
        return 0;
    }
  }

  public static getLangKey(langNum: number) {
    switch (langNum) {
      case 0:
        return 'en';
      case 1:
        return 'fr';
      default:
        return 'en';
    }
  }

  public static getTimetableStatus(status: number | undefined) {
    switch (status) {
      case 0:
        return 'not_submitted';
      case 1:
        return 'submitted';
      default:
        return 'not_submitted';
    }
  }

  public static getBaseTZ(baseId: number) {
    let timezone = 'America/Halifax';
    switch (baseId) {
      case HALIFAX:
        timezone = 'America/Halifax';
        break;
      case MONTREAL:
        timezone = 'America/Montreal';
        break;
      case TORONTO:
        timezone = 'America/Toronto';
        break;
      case VANCOUVER:
        timezone = 'America/Vancouver';
        break;
      case CALGARY:
        timezone = 'America/Edmonton';
        break;
      default:
    }

    return timezone;
  }

  public static getBaseInitials(baseId: number) {
    let initials = 'YHZ';
    switch (baseId) {
      case HALIFAX:
        initials = 'YHZ';
        break;
      case MONTREAL:
        initials = 'YUL';
        break;
      case TORONTO:
        initials = 'YYZ';
        break;
      case VANCOUVER:
        initials = 'YVR';
        break;
      case CALGARY:
        initials = 'YYC';
        break;
      default:
    }

    return initials;
  }

  public static formatDate(
    date: string | undefined,
    baseId: number | undefined,
    selectedLang: string
  ) {
    if (date !== undefined && baseId !== undefined) {
      let timezone = this.getBaseTZ(baseId);
      let format = selectedLang === 'fr' ? 'Do MMM YYYY' : 'MMM Do, YYYY';
      return moment.tz(date, timezone).format(format); //TO DO, ADD VAR FOR LANGUAGE TO BE ABLE TO REMOVE COMMA AND SWITCH ORDER
    } else {
      return '';
    }
  }

  public static formatDateTimeOffsetIgnoreTZ(date: string, selectedLang: string) {
    let format = selectedLang === 'fr' ? 'Do MMM YYYY' : 'MMM Do, YYYY';
    return this.momentFromDateTimeOffsetIgnoreTZ(date).format(format);
  }

  public static formatDateTime(
    date: string | undefined,
    baseId: number | undefined,
    selectedLang: string
  ) {
    if (date !== undefined && baseId !== undefined) {
      let timezone = this.getBaseTZ(baseId);
      let format = selectedLang === 'fr' ? 'Do MMM YYYY HH:mm' : 'MMM Do, YYYY HH:mm';
      return moment.tz(date, timezone).format(format); //TO DO, ADD VAR FOR LANGUAGE TO BE ABLE TO REMOVE COMMA AND SWITCH ORDER
    } else {
      return '';
    }
  }

  public static formatOnlyDateUTC(
    date: string | undefined,
    baseId: number | undefined,
    selectedLang: string
  ) {
    if (date !== undefined && baseId !== undefined) {
      let timezone = this.getBaseTZ(baseId);
      let format = selectedLang === 'fr' ? 'Do MMM YYYY' : 'MMM Do, YYYY';
      return moment.tz(date, timezone).format(format); //TO DO, ADD VAR FOR LANGUAGE TO BE ABLE TO REMOVE COMMA AND SWITCH ORDER
    } else {
      return '';
    }
  }

  public static formatTime(date: string | undefined, baseId: number | undefined) {
    if (date !== undefined && baseId !== undefined) {
      let timezone = this.getBaseTZ(baseId);
      return moment.tz(date, timezone).format('HH:mm');
    } else {
      return '';
    }
  }

  public static getTimezoneAbbr(baseId: number) {
    let timezone = this.getBaseTZ(baseId);
    return moment.tz(timezone).zoneAbbr();
  }

  public static formatDateOnly(date: Date | undefined) {
    if (date === undefined) return null;

    // Note that we elect to use local below as considering this date to be UTC will likely cause
    // conflicts with consuming these values between timezones
    return moment(date, 'YYYY-MM-DD').toDate();
  }

  public static momentFromDateTimeOffsetIgnoreTZ(date: string) {
    return moment(date, 'YYYY-MM-DDTHH:mm:ss[Z]');
  }

  public static formatDateForCalendar(date: Date | undefined, baseId: number | undefined) {
    if (date !== undefined && baseId !== undefined) {
      let timezone = this.getBaseTZ(baseId);
      let formattedDate = moment(new Date(date).toISOString(), moment.ISO_8601)
        .tz(timezone)
        .toDate();
      return formattedDate;
    } else {
      return null;
    }
  }

  public static setBaseZone(date: Date | null, baseId: number | undefined) {
    if (date !== undefined && date !== null && baseId !== undefined) {
      let timezone = this.getBaseTZ(baseId);
      const dateWithoutZone = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
      const otherZone = moment.tz(date, timezone).format('Z');
      const dateWithOtherZone = [dateWithoutZone, otherZone].join('');

      return new Date(dateWithOtherZone);
    } else {
      return null;
    }
  }

  public static setLocalZone(date: Date | null, baseId: number | undefined) {
    if (date !== undefined && baseId !== undefined) {
      let timezone = this.getBaseTZ(baseId);
      const dateWithoutZone = moment.tz(date, timezone).format('YYYY-MM-DDTHH:mm:ss.SSS');
      const localZone = moment(dateWithoutZone).format('Z');
      const dateWithLocalZone = [dateWithoutZone, localZone].join('');

      return new Date(dateWithLocalZone);
    } else {
      return null;
    }
  }

  public static getTime(dateTime: Moment): Moment {
    return moment({ h: dateTime.hours(), m: dateTime.minutes() });
  }

  public static getTimeInBaseZone(date: Date | null, baseId: number | undefined) {
    if (date !== undefined && date !== null && baseId !== undefined) {
      let timezone = this.getBaseTZ(baseId);
      const dateWithoutZone = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
      const otherZone = moment.tz(date, timezone).format('Z');
      const dateWithOtherZone = [dateWithoutZone, otherZone].join('');

      let dateTime = moment.tz(date, timezone).format('HH:mm');
      // return moment({ h: dateTime.hours(), m: dateTime.minutes() });
      return dateTime;
    } else {
      return null;
    }
  }

  public static getFirstName(displayName: string | undefined) {
    let firstName = '';
    if (displayName !== undefined) {
      let tmp = displayName.split(',');
      firstName = tmp[1];
    }

    return firstName;
  }

  public static isSameOrBefore(startBidTime: string | null, endBidTime: string | null): boolean {
    if (startBidTime !== null && endBidTime !== null) {
      let startBidTimeArray = startBidTime.split(':');
      let startTimeHour = startBidTimeArray[0];
      let startTimeMin = startBidTimeArray[1];

      let endBidTimeArray = endBidTime.split(':');
      let endTimeHour = endBidTimeArray[0];
      let endTimeMin = endBidTimeArray[1];

      if (
        (startTimeHour === endTimeHour && startTimeMin === endTimeMin) ||
        (startTimeHour === endTimeHour && startTimeMin > endTimeMin) ||
        startTimeHour > endTimeHour
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public static loadFrenchMoment() {
    moment.locale('fr', {
      months:
        'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
          '_'
        ),
      monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
      monthsParseExact: true,
      weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
      weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
      weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
      weekdaysParseExact: true,
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
      },
      calendar: {
        sameDay: '[Aujourd’hui à] LT',
        nextDay: '[Demain à] LT',
        nextWeek: 'dddd [à] LT',
        lastDay: '[Hier à] LT',
        lastWeek: 'dddd [dernier à] LT',
        sameElse: 'L',
      },
      relativeTime: {
        future: 'dans %s',
        past: 'il y a %s',
        s: 'quelques secondes',
        m: 'une minute',
        mm: '%d minutes',
        h: 'une heure',
        hh: '%d heures',
        d: 'un jour',
        dd: '%d jours',
        M: 'un mois',
        MM: '%d mois',
        y: 'un an',
        yy: '%d ans',
      },
      //dayOfMonthOrdinalParse: /\d{1,2}/,
      //ordinal: function (number) {
      //  return number + (number === 1 ? 'er' : 'e');
      //},
      meridiemParse: /PD|MD/,
      isPM: function (input) {
        return input.charAt(0) === 'M';
      },
      // In case the meridiem units are not separated around 12, then implement
      // this function (look at locale/id.js for an example).
      // meridiemHour : function (hour, meridiem) {
      //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
      // },
      meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
      },
      week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // Used to determine first week of the year.
      },
    });
  }

  public static getMaxSplitsNumber(vacation: number): number {
    let splits: number = 0;

    if (vacation >= 0 && vacation <= 7) {
      splits = 1;
    } else if (vacation >= 8 && vacation <= 14) {
      splits = 2;
    } else if (vacation >= 15 && vacation <= 21) {
      splits = 3;
    } else if (vacation >= 22 && vacation <= 28) {
      splits = 4;
    } else if (vacation >= 29 && vacation <= 35) {
      splits = 5;
    } else if (vacation >= 36) {
      splits = 7;
    }

    return splits;
  }

  public static isNotNull(value: string | Date | number | undefined) {
    return value !== undefined && value !== null;
  }

  //Forms related
  public static isFieldNull(value: any) {
    return value === undefined || value === null;
  }

  public static hasFieldError(errors: FormError[], field: string) {
    return errors.some((err) => err.field === field);
  }

  public static getFieldError(errors: FormError[], field: string) {
    return errors.find((err) => err.field === field);
  }

  public static getVarName(varObj: any) {
    if (varObj !== null && varObj !== undefined) {
      return Object.keys(varObj)[0];
    } else {
      return '';
    }
  }

  public static extractSplitDates(existingSplits: Split[][]) {
    let excludedDays: { start: Date; end: Date }[] = [];
    (existingSplits || []).forEach((group) => {
      (group || []).forEach((split) => {
        excludedDays.push({
          start: Utils.formatDateOnly(split.startDate)!,
          end: Utils.formatDateOnly(split.endDate)!,
        });
      });
    });
    return excludedDays;
  }

  public static extractSplitDatesAttachSplit(existingSplits: Split[][], split: Split) {
    let excludedDays: { start: Date; end: Date; addedStart: number; addedEnd: number }[] = [];
    (existingSplits || []).forEach((group) => {
      (group || [])
        .filter((s) => s.id !== split?.id)
        .forEach((split) => {
          excludedDays.push({
            start: Utils.formatDateOnly(split.startDate)!,
            end: Utils.formatDateOnly(split.endDate)!,
            addedStart: split.attachedDaysStart,
            addedEnd: split.attachedDaysEnd,
          });
        });
    });
    return excludedDays;
  }

  public static extractSplitDatesSplitModal(existingSplits: Split[][], split: Split) {
    let excludedDays: { start: Date; end: Date; addedStart: number; addedEnd: number }[] = [];
    (existingSplits || []).forEach((group) => {
      (group || [])
        .filter((s) => s.id !== split?.id)
        .forEach((split) => {
          excludedDays.push({
            start: Utils.formatDateOnly(split.startDate)!,
            end: Utils.formatDateOnly(split.endDate)!,
            addedStart: split.attachedDaysStart,
            addedEnd: split.attachedDaysEnd,
          });
        });
    });
    return excludedDays;
  }
}
