import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { FAController } from '../../../../controller/FAController';
import { useActiveRoundForBase } from '../../../../hooks/RoundHooks';
import { Base } from '../../../../model/Base';
import { FlightAttendant } from '../../../../model/FlightAttendant';
import { Round } from '../../../../model/Round';
import {
  BID_YEAR,
  CPM,
  CPM_PATHS,
  EMP_NO_LENGTH,
  FA_PATHS,
  NOT_FOUND,
  SUCCESS_CODE,
} from '../Constants';
import { useUserContext } from '../context/user-context-provider';

interface ImpersonateFAModalProps {}

export const ImpersonateFAModal = (props: ImpersonateFAModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { role } = useUserContext();
  const faCtrl = new FAController();

  const [employeeId, setEmployeeId] = useState<string>('');
  const [selectedFA, setSelectedFA] = useState<FlightAttendant | null>();
  const [activeRound, setActiveRound] = useState<Round>();
  const [searching, setSearching] = useState<boolean>(false);

  const [errorModalMessage, setErrorModalMessage] = useState<string>('');

  const empIdOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setEmployeeId(e.target.value);
      setErrorModalMessage('');
      setSelectedFA(null);
    }
  };
  const getActiveRoundsForBase = (fa: FlightAttendant) => {
    faCtrl
      .getActiveRoundsForBase(fa.base.initials!)
      .then((response) => {
        let _activeRound = response.data as Round;
        setActiveRound(_activeRound);
      })
      .catch((error) => {
        setErrorModalMessage(t('general.errors.server_error'));
      });
  };
  const handleSearch = (e: any) => {
    e.preventDefault && e.preventDefault();
    setSearching(true);
    if (employeeId.length === EMP_NO_LENGTH) {
      faCtrl
        .getFA(Number(employeeId))
        .then((response) => {
          if (response.status === NOT_FOUND) {
            setErrorModalMessage(
              t('general.modals.errors.no_fa_found', {
                empNo: employeeId,
              })
            );
          } else {
            let resultFa = response?.data as FlightAttendant;
            setSelectedFA(resultFa);
            getActiveRoundsForBase(resultFa);
          }
        })
        .catch((error) => {
          setErrorModalMessage(t('general.errors.server_error'));
        })
        .finally(() => {
          setSearching(false);
        });
    } else {
      setErrorModalMessage(t('general.modals.errors.short_emp_no'));
      setSearching(false);
    }
  };

  const onKeyEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  const goToBid = () => {
    discardChanges();
    if (activeRound?.roundNo === 1 || activeRound?.roundNo === 2) {
      navigate(activeRound?.roundNo === 1 ? FA_PATHS.vac_bid : FA_PATHS.stat_bid, {
        state: { impersonateFaEmpNo: selectedFA?.employeeNo },
      });
    } else {
      setErrorModalMessage(t('general.errors.no_active_round'));
    }
  };

  const discardChanges = () => {
    setEmployeeId('');
    setErrorModalMessage('');
    setSearching(false);
    setSelectedFA(null);
  };

  useEffect(() => {
    discardChanges();
  }, []);

  if (role === CPM) {
    return (
      <>
        <div
          className="modal fade"
          id="impersonateFaModal"
          tabIndex={-1}
          aria-labelledby="impersonateFaModalLabel"
          aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5 text-start" id="impersonateFaModalLabel">
                  {t('general.modals.titles.impersonate_fa')}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={discardChanges}></button>
              </div>
              <div className="modal-body pb-0">
                <form className="row">
                  <div className="col-12 mb-3 text-start d-flex align-items-center">
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        value={employeeId}
                        placeholder={t('general.modals.body.search_by_emp_no')}
                        aria-label={t('general.modals.body.search_by_emp_no')}
                        aria-describedby="button-addon2"
                        maxLength={EMP_NO_LENGTH}
                        onChange={(e) => {
                          empIdOnChange(e);
                        }}
                        onKeyUp={(e) => {
                          onKeyEnter(e);
                        }}
                      />
                      <button
                        className="btn btn-secondary"
                        type="submit"
                        id="button-addon2"
                        onClick={(e) => {
                          handleSearch(e);
                        }}
                        disabled={searching || employeeId.length === 0}>
                        <i className="bi bi-search"></i>
                      </button>
                    </div>
                  </div>
                </form>
                <div className={`row ${errorModalMessage === '' ? '' : 'd-none'}`}>
                  <div className="col-12 mb-3 text-start d-flex align-items-center">
                    <div
                      className={`table-responsive w-100 ${
                        selectedFA !== undefined && selectedFA !== null ? '' : 'd-none'
                      }`}>
                      <table className="table table-hover align-middle">
                        <thead>
                          <tr className="text-center">
                            <th scope="col">{t('bases.fa_list.table.employee_no')}</th>
                            <th scope="col" className="text-start">
                              {t('bases.fa_list.table.name')}
                            </th>
                            <th scope="col" className="text-center">
                              {t('bases.fa_list.table.seniority')}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="table-group-divider">
                          <tr key={selectedFA?.employeeNo} className={`text-center`}>
                            <th scope="row">{selectedFA?.employeeNo}</th>
                            <td className="text-capitalize text-start">{selectedFA?.name}</td>
                            <td>{selectedFA?.seniority}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className={`row ${errorModalMessage === '' ? 'd-none' : ''}`}>
                  <div className="col-12 mb-3 text-start d-flex align-items-center">
                    <div className="alert alert-danger w-100" role="alert">
                      <i className="bi bi-exclamation-circle-fill me-2"></i>
                      {errorModalMessage}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row me-auto w-100">
                  <div className="col-12 text-end p-0 d-flex">
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-outline-jazz-red me-2 w-50"
                      data-bs-dismiss="modal"
                      onClick={discardChanges}>
                      {t(`general.buttons.cancel`)}
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-jazz-red me-2 w-50"
                      disabled={selectedFA === null}
                      data-bs-dismiss={
                        activeRound?.roundNo === 1 || activeRound?.roundNo === 2 ? 'modal' : ''
                      }
                      onClick={goToBid}>
                      {t(`general.buttons.go_to_bid`)}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};
