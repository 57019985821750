import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import axiosInstance from '../config/axios-instance';
import {
  DeleteFrenchTrainingDates,
  GetFrenchTrainingDates,
  GetFrenchTrainingDatesByYear,
  PostFrenchTrainingDates,
  PostFrenchTrainingDatesExcel,
} from '../config/endpoints-config';
import { FrenchTrainingDate } from '../model/FrenchTrainingDate';
import { FrenchTrainingExcel } from '../model/FrenchTrainingExcel';
import { BID_YEAR } from '../view/components/utils/Constants';

export const usePostFrenchTrainingDatesExcel = (
  setErrorMessage: (value: string) => void,
  setSuccessMessage: (value: string) => void
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse, AxiosError, FrenchTrainingExcel[]>(
    (excelSheet) => axiosInstance.post(PostFrenchTrainingDatesExcel, excelSheet),
    {
      onSuccess: (resp, pref) => {
        setSuccessMessage(t('general.status.success'));
      },
    }
  );
};

interface FrenchTrainingDateData {
  employeeId: number;
  startDate: string;
  endDate: string;
}

export const usePostFrenchTrainingDates = (
  setErrorMessage: (value: string) => void,
  setSuccessMessage: (value: string) => void
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse, AxiosError, FrenchTrainingDateData>(
    (ftd) =>
      axiosInstance.post(PostFrenchTrainingDates, null, {
        params: {
          employeeId: ftd.employeeId,
          startDate: ftd.startDate,
          endDate: ftd.endDate,
        },
      }),
    {
      onSuccess: (resp, pref) => {
        queryClient.invalidateQueries(['frenchTrainingDatesByFaYear', BID_YEAR]);
        setSuccessMessage(t('general.status.success'));
      },
      onError: (error, query) => {
        setErrorMessage(t('general.errors.server_error'));
      },
    }
  );
};

interface FrenchTrainingDateRemovalData {
  employeeId: number;
  id: number;
  baseId: number;
}

export const useDeleteFrenchTrainingDates = (
  setErrorMessage: (value: string) => void,
  setSuccessMessage: (value: string) => void
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse, AxiosError, FrenchTrainingDateRemovalData>(
    (ftd) => axiosInstance.delete(DeleteFrenchTrainingDates(ftd.id)),
    {
      onSuccess: (resp, ftd) => {
        queryClient.invalidateQueries(['frenchTrainingDatesByFaYear', BID_YEAR, ftd.baseId]);
        setSuccessMessage(t('general.status.success'));
      },
      onError: (error, query) => {
        setErrorMessage(t('general.errors.server_error'));
      },
    }
  );
};

export const useFrenchTrainingDatesByFaYearAndEmpNo = (year?: number, employeeNo?: number) =>
  useQuery<FrenchTrainingDate[], AxiosError>(
    ['frenchTrainingDatesByFaYear', year, employeeNo],
    () => axiosInstance.get(GetFrenchTrainingDates(employeeNo!, year!)).then((resp) => resp.data),
    {
      enabled: !!year && !!employeeNo, // Require all 2 params before query will execute
      placeholderData: [],
      refetchInterval: 5000, // Refresh the days availability data every 5 seconds to ensure we're always seeing latest
    }
  );

export const useFrenchTrainingDatesByFaYearAndBase = (year?: number, baseId?: number) =>
  useQuery<FrenchTrainingDate[], AxiosError>(
    ['frenchTrainingDatesByFaYear', year, baseId],
    () => axiosInstance.get(GetFrenchTrainingDatesByYear(year!, baseId!)).then((resp) => resp.data),
    {
      enabled: !!year && !!baseId, // Require params before query will execute
      placeholderData: [],
      refetchInterval: 5000, // Refresh the days availability data every 5 seconds to ensure we're always seeing latest
    }
  );
