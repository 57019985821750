import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useBasesList } from '../../../../hooks/BaseHooks';
import { useChangeBase } from '../../../../hooks/FlightAttendantHooks';
import { useGetFAChangeBaseDeadline } from '../../../../hooks/TimetableHooks';
import { Base } from '../../../../model/Base';
import { Entitlement } from '../../../../model/Entitlement';
import { FlightAttendant } from '../../../../model/FlightAttendant';
import { BID_YEAR } from '../Constants';

interface ChangeBaseModalProps {
  fa: FlightAttendant;
  currentBase: Base;
  bases: Base[] | undefined;
}

export const ChangeBaseModal = (props: ChangeBaseModalProps) => {
  const { t } = useTranslation();
  const { fa, currentBase, bases } = props;
  const [selectedBaseId, setSelectedBaseId] = useState<number>(fa.baseId);
  const [savedChanges, setSavedChanges] = useState<boolean>(true);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [newInitials, setNewInitials] = useState<string>('');
  const { data: changeBaseDeadline } = useGetFAChangeBaseDeadline(fa.employeeNo, fa.baseId);

  const [currentTime, setCurrentTime] = useState<Moment>(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const startMoment = (changeBaseDeadline !== undefined && moment(changeBaseDeadline)) || undefined;
  const changeBasesAllowed = startMoment !== undefined && startMoment.isAfter(currentTime);

  const {
    mutate: changeBase,
    isError,
    isSuccess,
    isLoading,
    reset,
  } = useChangeBase(setErrorMessage);

  const handleChangeStation = (employeeNo: number, currentBaseId: number, destBaseId: number) => {
    changeBase({
      employeeId: employeeNo,
      year: BID_YEAR,
      currentBaseId: currentBaseId,
      destBaseId: destBaseId,
    });
  };

  const handleChange = (e: any) => {
    setSelectedBaseId(e.target.value);
    let targetBase: Base = bases?.find((obj) => obj.id.toString() === e.target.value) as Base;
    setNewInitials(targetBase.initials);
    setSavedChanges(false);
  };

  const handleConfirmation = (e: any) => {
    setConfirmed(!confirmed);
  };

  const saveChanges = () => {
    setSavedChanges(true);
    handleChangeStation(fa.employeeNo, currentBase.id, selectedBaseId);
  };

  const discardChanges = () => {
    setSavedChanges(true);
    setSelectedBaseId(fa.baseId);
    setNewInitials('');
    setConfirmed(false);
    reset();
  };

  useEffect(() => {
    discardChanges();
  }, [fa]);

  return (
    <>
      <div
        className="modal fade"
        id={`changeBaseModal`}
        tabIndex={-1}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-start" id="exampleModalLabel">
                {t('bases.fa_list.change_base')}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={discardChanges}></button>
            </div>
            <div className="modal-body pb-0">
              <div className="row">
                <div className="col-12 mb-3 text-start">
                  <div
                    className={`alert alert-champagne w-100 ${
                      changeBasesAllowed ? 'd-none' : 'd-inline-block'
                    }`}
                    role="alert">
                    <i className={`fa-solid fa-circle-info me-2`}></i>
                    <Trans
                      components={{ bold: <b /> }}
                      values={{
                        faName: fa?.name,
                      }}
                      i18nKey="general.modals.body.fa_cant_change_base"
                    />
                  </div>
                  <h5 className="text-danger">{fa?.name}</h5>
                  <label className="text-dark">
                    <Trans
                      components={{ bold: <b /> }}
                      values={{
                        currentBase: currentBase?.initials,
                      }}
                      i18nKey="general.modals.body.fa_current_base"
                    />
                  </label>
                </div>
              </div>
              <form className="row">
                <div className="col-12 mb-3 text-start">
                  <label className="form-label">
                    <Trans components={{ bold: <b /> }} i18nKey="general.modals.body.fa_new_base" />
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={selectedBaseId}
                    onChange={handleChange}
                    disabled={!changeBasesAllowed}>
                    {bases!.map((base: Base) => (
                      <option key={base.id} value={base.id} disabled={base.id === fa.baseId}>
                        {base.initials}
                      </option>
                    ))}
                  </select>
                  <div className="form-check mt-3">
                    <input
                      className="form-check-input me-2 custom-control-input-red"
                      type="checkbox"
                      checked={confirmed}
                      id="flexCheckDefault"
                      onChange={handleConfirmation}
                      disabled={!changeBasesAllowed}
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      <Trans
                        i18nKey="general.modals.body.confirm_change_base"
                        values={{
                          currentBase: currentBase?.initials,
                          newBase: newInitials === '' ? '___' : newInitials,
                        }}
                      />
                    </label>
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-12 text-start mt-2">
                  {/*Discard changes alert*/}
                  <div
                    className={`form-small-text alert alert-secondary w-100 ${
                      savedChanges ? 'd-none' : 'd-inline-block'
                    }`}>
                    {t('general.modals.body.unsaved_changes')}
                    <button
                      className="btn btn-link link-secondary btn-sm"
                      onClick={() => {
                        discardChanges();
                      }}>
                      {t(`general.buttons.discard_changes`)}
                    </button>
                  </div>
                  {/*Success alert*/}
                  <div
                    className={`alert alert-success w-100 ${
                      isSuccess ? 'd-inline-block' : 'd-none'
                    }`}
                    role="alert">
                    <i className={`fa-solid fa-circle-check me-2`}></i>
                    <Trans i18nKey="general.status.success" />
                  </div>

                  {/*Error alert*/}
                  <div
                    className={`alert alert-danger w-100 ${isError ? 'd-inline-block' : 'd-none'}`}
                    role="alert">
                    <i className={`fa-solid fa-circle-xmark me-2`}></i>
                    {errorMessage}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row me-auto w-100">
                <div className="col-12 text-end p-0 d-flex">
                  {isSuccess ? (
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      className="btn btn-outline-danger btn-outline-jazz-red w-100"
                      onClick={discardChanges}>
                      {t(`general.buttons.close`)}
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="btn btn-outline-danger btn-outline-jazz-red me-2 w-50"
                        onClick={discardChanges}>
                        {t(`general.buttons.cancel`)}
                      </button>
                      <button
                        type="button"
                        className={`btn btn-danger btn-jazz-red w-50 `}
                        onClick={saveChanges}
                        disabled={fa.baseId === selectedBaseId || !confirmed || isLoading}>
                        {t(`general.buttons.save`)}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
