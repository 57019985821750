import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Base } from '../../../../model/Base';
import { Timetable } from '../../../../model/Timetable';
import { CPM_PATHS } from '../../../components/utils/Constants';
import Utils from '../../../components/utils/Utils';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';
import { Loading } from '../../../components/utils/loading/loading';

interface TimetableTableProps {
  isPreview: boolean;
  timetableVisible: boolean;
  generatingTimetable: boolean;
  timetable: Timetable[] | undefined;
  base: Base;
}

export const TimetableTable = (props: TimetableTableProps) => {
  const { t } = useTranslation();
  const { selectedLanguage } = useGeneralContext();
  const { timetableVisible, timetable, base, isPreview } = props;
  const [query, setQuery] = useState('');

  const clearSearchQuery = () => {
    setQuery('');
  };

  return (
    <>
      <div className={`row pb-4 ${timetableVisible ? '' : 'd-none'}`}>
        <div className="row pb-1 no_print">
          <div className="col-12">
            <div className="input-group mt-4 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder={t('bases.fa_list.search_by_name_emp_no')}
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                disabled={timetable?.length === 0}
                aria-label={t('bases.fa_list.search_by_name_emp_no')}
                aria-describedby="button-search-flightAttendant-by-name"
              />
              <button
                className="btn btn-secondary"
                type="button"
                id="button-addon2"
                onClick={clearSearchQuery}
                disabled={timetable?.length === 0}>
                {t('general.buttons.clear')}
              </button>
            </div>
          </div>
        </div>

        <div className="row timetable-row pb-1">
          <div className="col-12">
            <div className="table-responsive h-100">
              {timetable !== undefined && timetable.length > 0 ? (
                <table className="table table-hover align-middle">
                  <thead>
                    <tr className="text-center">
                      <th scope="col"></th>
                      <th scope="col">{t('bases.timetable.table.emp_no')}</th>
                      <th scope="col" className="text-start">
                        {t('bases.timetable.table.name')}
                      </th>
                      <th scope="col" className="text-center">
                        {t('bases.timetable.table.seniority')}
                      </th>
                      <th scope="col" className={`text-center ${isPreview ? 'd-none' : ''}`}>
                        {t('bases.timetable.table.vacation')}
                      </th>
                      <th scope="col" className={`text-center ${isPreview ? 'd-none' : ''}`}>
                        {t('bases.timetable.table.statutory')}
                      </th>
                      <th scope="col" className={`text-center ${isPreview ? 'd-none' : ''}`}>
                        {t('bases.timetable.table.splits')}
                      </th>
                      <th scope="col" className="text-start">
                        {t('bases.timetable.table.start_time')}
                      </th>
                      <th scope="col" className="text-start">
                        {t('bases.timetable.table.end_time')}
                      </th>
                      <th
                        scope="col"
                        className={`text-center no_print ${isPreview ? 'd-none' : ''}`}>
                        {t('bases.timetable.table.status')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {timetable
                      .filter((time) => {
                        if (query === '') {
                          return time;
                        } else if (
                          time.flightAttendantEmpNo.toString().includes(query.toLowerCase()) ||
                          time.flightAttendantName.toLowerCase().includes(query.toLowerCase())
                        ) {
                          return time;
                        }
                      })
                      .map((time, index) => (
                        <tr key={time.flightAttendantEmpNo} className="text-center">
                          <th scope="row">{index + 1}</th>
                          <th scope="row">{time.flightAttendantEmpNo}</th>
                          <td className="text-start">{time.flightAttendantName}</td>
                          <td className="text-center">{time.flightAttendantSeniority}</td>
                          <td className={`text-center ${isPreview ? 'd-none' : ''}`}>
                            {time.vacation}
                          </td>
                          <td className={`text-center ${isPreview ? 'd-none' : ''}`}>
                            {time.statutory}
                          </td>
                          <td className={`text-center ${isPreview ? 'd-none' : ''}`}>
                            {time.splits}
                          </td>
                          <td className="text-capitalize text-start">
                            {Utils.formatDateTime(
                              time.startTime.toString(),
                              base.id,
                              selectedLanguage
                            )}
                          </td>
                          <td className="text-capitalize text-start">
                            {Utils.formatDateTime(
                              time.endTime.toString(),
                              base.id,
                              selectedLanguage
                            )}
                          </td>
                          <td className={`${isPreview ? 'd-none' : ''} text-center no_print`}>
                            <span
                              className={`badge ${
                                time.bidStatus === 1 ? 'bg-success' : 'bg-light text-dark'
                              }`}>
                              {t(
                                `bases.timetable.table.${Utils.getTimetableStatus(time.bidStatus)}`
                              )}
                            </span>
                          </td>
                          <td className={`${isPreview ? 'd-none' : ''}`}>
                            <div className="dropdown">
                              <button
                                className="btn btn-outline-secondary dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"></button>
                              <ul className="dropdown-menu">
                                <NavLink
                                  to={CPM_PATHS.bid_summary}
                                  className="nav_link p-0 m-0 d-block text-center"
                                  state={{ faId: time.flightAttendantEmpNo, baseId: base.id }}>
                                  <li className="dropdown-item">
                                    {t('bases.bid_summary.page_title')}
                                  </li>
                                </NavLink>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : timetable?.length === 0 ? (
                <div className="alert alert-secondary mt-2" role="alert">
                  <i className="time-solid time-circle-info me-2"></i>
                  {t('bases.fa_list.no_records')}
                </div>
              ) : (
                <Loading></Loading>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
