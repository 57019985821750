import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CANCEL,
  DELETE_BACKUP,
  DELETE_SPLIT,
  SIGN_OUT_MODAL,
  YES,
  YES_SIGN_OUT,
} from '../Constants';

interface ConfirmationModalProps {
  modalType: string;
  includeHeader: boolean;
  includeFooter?: boolean;
  employeeId?: number;
  submitAction?: (value?: any) => void;
  children?: React.ReactNode;
  values?: {
    index?: number;
  };
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { t } = useTranslation();
  const { modalType, includeHeader, includeFooter, submitAction, employeeId, values, children } =
    props;

  const [submitBtnText, setSubmitBtnText] = useState('placeholder');
  const [closeBtnText, setCloseBtnText] = useState('placeholder');
  const [modalTitle, setModalTitle] = useState('placeholder');
  const [modalIdentifier, setModalIdentifier] = useState('');
  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    if (includeFooter !== undefined) {
      setShowFooter(includeFooter);
    }

    if (modalType === DELETE_BACKUP || modalType === DELETE_SPLIT) {
      setModalIdentifier(
        modalType + (employeeId ? employeeId : '') + (values?.index ? values?.index : '')
      );
    } else {
      setModalIdentifier(modalType + (employeeId ? employeeId : ''));
    }

    setModalTitle(t(`general.modals.titles.${modalType}`));
    if (modalType === SIGN_OUT_MODAL) {
      setSubmitBtnText(YES_SIGN_OUT);
    } else {
      setSubmitBtnText(YES);
    }
    setCloseBtnText(CANCEL);
  }, []);

  return (
    <>
      <div
        className={`modal fade text-dark`}
        id={`${modalIdentifier}Modal`}
        tabIndex={-1}
        aria-labelledby={`${modalType}ModalLabel`}
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className={`modal-content ${modalType === SIGN_OUT_MODAL ? '' : 'text-start'}`}>
            <div className={`modal-header ${!includeHeader ? 'd-none' : ''}`}>
              <h1 className="modal-title fs-5" id={`${modalType}ModalLabel`}>
                {modalTitle}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            {children && <div className="modal-body">{children}</div>}
            <div className={`modal-footer ${showFooter ? '' : 'd-none'}`}>
              <div className="row me-auto w-100">
                <div className="col-12 text-end p-0 d-flex">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-outline-jazz-red me-2 w-50"
                    data-bs-dismiss="modal">
                    {t(`general.buttons.${closeBtnText}`)}
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-jazz-red w-50"
                    onClick={submitAction}
                    data-bs-dismiss="modal">
                    {t(`general.buttons.${submitBtnText}`)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
