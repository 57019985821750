//Component imports
import { GetActiveRoundsForBase, PutExtendRound, Rounds } from '../config/endpoints-config';
import { Round } from '../model/Round';
import GeneralService from './GeneralService';

export default class RoundsService extends GeneralService {
  async updateRound(round: Round) {
    let result = this.putWithBodyId(Rounds, round, round.id);
    return result;
  }

  async extendRound(round: Round) {
    let result = this.putWithBodyId(PutExtendRound, round, round.id);
    return result;
  }

  async getActiveRoundForBase(baseInitials: string) {
    let result = this.getWithParams(GetActiveRoundsForBase(baseInitials), null);
    return result;
  }
}
