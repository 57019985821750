import { Trans, useTranslation } from 'react-i18next';

import { useUpdateR2 } from '../../../../../hooks/FlightAttendantHooks';
import { Base } from '../../../../../model/Base';
import { FlightAttendant } from '../../../../../model/FlightAttendant';
import { BID_YEAR, CHANGE_R2_MODAL, INACTIVE } from '../../../../components/utils/Constants';
import { ConfirmationModal } from '../../../../components/utils/modals/confirmation-modal';

interface R2OptInControlProps {
  fa: FlightAttendant;
  base: Base;
  setErrorMessage: (value: string) => void;
  setSuccessMessage: (value: string) => void;
}

export const R2OptInControl = ({
  fa,
  base,
  setErrorMessage,
  setSuccessMessage,
}: R2OptInControlProps) => {
  const { t } = useTranslation();

  const {
    mutate: updateR2,
    isError: isR2UpdateError,
    error: r2UpdateError,
    isLoading: r2UpdateInProgress,
  } = useUpdateR2(setErrorMessage, setSuccessMessage);

  return (
    <>
      <input
        id={`r2-${fa.employeeNo}`}
        className="form-check-input"
        type="checkbox"
        checked={fa.entitlement.partR2}
        disabled={fa.status === INACTIVE || r2UpdateInProgress}
        data-bs-toggle="modal"
        data-bs-target={`#${CHANGE_R2_MODAL + fa.employeeNo}Modal`}
        onChange={(e) => e.stopPropagation()}
        aria-label="Checkbox for Round 2 opt-in"
      />

      <ConfirmationModal
        modalType={CHANGE_R2_MODAL}
        employeeId={fa.employeeNo}
        submitAction={() => {
          updateR2({
            employeeId: fa.employeeNo,
            year: BID_YEAR,
            r2Preference: !fa.entitlement.partR2,
            baseId: base!.id,
          });
        }}
        includeHeader={true}>
        <div className="modal-body">{t('general.modals.body.change_r2')}</div>
      </ConfirmationModal>
    </>
  );
};
