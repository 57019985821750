import { AuditLog } from '../model/AuditLog';
import { Entitlement } from '../model/Entitlement';
import { FlightAttendant } from '../model/FlightAttendant';
import FAService from '../services/FAService';

export class FAController {
  private fas: FlightAttendant[] = [];
  private faService: FAService;

  constructor() {
    this.faService = new FAService();
  }

  public async getActiveRoundsForBase(baseInitials: string) {
    try {
      return this.faService.getActiveRoundsForBase(baseInitials);
    } catch (error) {
      console.log('FAController Error - ', error);
      return error.response;
    }
  }

  public async getFAsByBaseCount(baseId: number) {
    try {
      const response = await this.faService.getFAsByBaseCount(baseId);

      if (response !== undefined || response !== null) {
        return response?.data as number;
      }
    } catch (error) {
      console.log('FAController Error - ', error);
    }
  }

  public async getActiveFAsByBaseCount(baseId: number) {
    try {
      const response = await this.faService.getActiveFAsByBaseCount(baseId);

      if (response !== undefined || response !== null) {
        return response?.data as number;
      }
    } catch (error) {
      console.log('FAController Error - ', error);
    }
  }

  public async getActiveFAsByBase(baseId: number) {
    try {
      const response = await this.faService.getActiveFAsByBase(baseId);

      if (response !== undefined || response != null) {
        return response?.data as FlightAttendant[];
      }
    } catch (error) {
      console.log('FAController Error - ', error);
    }
  }

  public async getR2FlightAttendantsCountByBase(baseId: number) {
    try {
      const response = await this.faService.getR2FlightAttendantsCountByBase(baseId);

      if (response !== undefined || response !== null) {
        return response?.data as number;
      }
    } catch (error) {
      console.log('FAController Error - ', error);
    }
  }

  public async getR2FlightAttendantsByBase(baseId: number) {
    try {
      const response = await this.faService.getR2FlightAttendantsByBase(baseId);

      if (response !== undefined || response != null) {
        return response?.data as FlightAttendant[];
      }
    } catch (error) {
      console.log('FAController Error - ', error);
    }
  }

  public async updateFA(fa: FlightAttendant) {
    try {
      const response = await this.faService.updateFA(fa);

      if (response !== undefined || response !== null) {
        return response?.data as FlightAttendant;
      }
    } catch (error) {
      console.log('FAController Error - ', error);
    }
  }

  public async updateEntitlement(ent: Entitlement, employeeId: string) {
    try {
      return this.faService.updateEntitlement(ent, employeeId);
    } catch (error) {
      console.log('FAController Error - ', error);
    }
  }

  public async getLastUpdatedFAList(baseId: number) {
    try {
      const response = await this.faService.getLastUpdatedFAList(baseId);

      if (response !== undefined || response !== null) {
        return response?.data as AuditLog;
      }
    } catch (error) {
      console.log('FAController Error - ', error);
    }
  }

  public async getFA(id: number) {
    try {
      return await this.faService.getFA(id);
    } catch (error) {
      console.log('FAController Error - ', error);
      return error.response;
    }
  }

  public async importFlightAttendantByEmployeeId(employeeId: string, baseId: number, year: number) {
    try {
      return this.faService.importFlightAttendantByEmployeeId(employeeId, baseId, year);
    } catch (error) {
      console.log('FAController Error - ', error);
    }
  }

  public async getFlightAttendantByEmployeeId(employeeId: string, baseId: number, year: number) {
    try {
      return this.faService.getFlightAttendantByEmployeeId(employeeId, baseId, year);
    } catch (error) {
      console.log('FAController Error - ', error);
    }
  }

  public async getFAWithoutTimetableByBase(roundId: number, baseId: number) {
    try {
      return this.faService.getFAWithoutTimetableByBase(roundId, baseId);
    } catch (error) {
      console.log('FAController Error - ', error);
    }
  }

  public async getCurrent() {
    try {
      const response = await this.faService.getCurrent();
      if (response !== undefined || response !== null) return response?.data as FlightAttendant;
    } catch (error) {
      console.log('FAController Error - ', error);
    }
  }
}
