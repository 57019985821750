import fr from 'date-fns/locale/fr';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import React from 'react';
import { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
// the locale you want
import { useLocation, useNavigate } from 'react-router-dom';

import { useFlightAttendantsForBase } from '../../../../hooks/FlightAttendantHooks';
import {
  useDeleteFrenchTrainingDates,
  useFrenchTrainingDatesByFaYearAndBase,
} from '../../../../hooks/FrenchTrainingDatesHooks';
import { Base } from '../../../../model/Base';
import { FlightAttendant } from '../../../../model/FlightAttendant';
import { FrenchTrainingDate } from '../../../../model/FrenchTrainingDate';
import { Round } from '../../../../model/Round';
import { BID_YEAR, CPM_PATHS, INACTIVE, REMOVE_FTD } from '../../../components/utils/Constants';
import Utils from '../../../components/utils/Utils';
import { ResultAlert } from '../../../components/utils/alerts/result-alert';
import { Content } from '../../../components/utils/content/content';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';
import { Loading } from '../../../components/utils/loading/loading';
import { AddFrenchTrainingDatesModal } from '../../../components/utils/modals/add-ftd-modal';
import { ConfirmationModal } from '../../../components/utils/modals/confirmation-modal';

interface FrenchTrainingDatesProps {}

export const FrenchTrainingDates = (props: FrenchTrainingDatesProps) => {
  const { t } = useTranslation();
  let location = useLocation();
  registerLocale('fr', fr);

  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const successRef = useRef<null | HTMLDivElement>(null);
  const errorRef = useRef<null | HTMLDivElement>(null);

  const [employeeIdToRemove, setEmployeeIdToRemove] = useState<number>();
  const [dateToRemove, setDateToRemove] = useState<number>();

  const [header1Width, setHeader1Width] = useState<number | undefined>();

  const [round, setRound] = useState<Round>(new Round());
  const [activeFaCount, setActiveFaCount] = useState(0);
  const [r2FaCount, setR2FaCount] = useState(0);
  const [base, setBase] = useState<Base>(new Base());
  const [query, setQuery] = useState('');
  const { data: flightAtts, isFetching: fetchingFlightAtts } = useFlightAttendantsForBase(
    base!.id,
    BID_YEAR
  );

  const { data: frenchTrainingDates, isFetched: frenchTrainingDatesFetched } =
    useFrenchTrainingDatesByFaYearAndBase(BID_YEAR, base.id);

  const clearSearchQuery = () => {
    setQuery('');
  };

  const {
    mutate: removeFTD,
    isError: isRemoveFTDError,
    error: removeFTDrror,
    isLoading: removeFTDInProgress,
  } = useDeleteFrenchTrainingDates(setErrorMessage, setSuccessMessage);

  const { selectedLanguage } = useGeneralContext();

  useEffect(() => {
    if (location.state !== null) {
      let state: any = location.state;
      if (state.round !== null && state.base !== null) {
        setRound(state.round);
        setBase(state.base);
        setActiveFaCount(state.activeFaCount);
        setR2FaCount(state.r2FaCount);
      } else {
        navigate(CPM_PATHS.bases);
      }
    } else {
      navigate(CPM_PATHS.bases);
    }
  }, [location]);

  const setInnerHeaderSize = (width: number) => {
    setHeader1Width(width);
  };

  const handleRemoveInformation = (employeeId: number, id: number) => {
    setEmployeeIdToRemove(employeeId);
    setDateToRemove(id);
  };

  const resizeRef = useCallback((node: HTMLTableHeaderCellElement) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      setInnerHeaderSize(node.clientWidth);
    });
    resizeObserver.observe(node);
  }, []);

  if (flightAtts === undefined || frenchTrainingDates === undefined) {
    return (
      <Content
        includeHeader={true}
        title={t('menu.cpm.bases')}
        subtitle={base?.name ? base.initials + ' - ' + t(`bases.names.${base.name}`) : ''}>
        <div className="vh-100">
          <Loading />
        </div>
      </Content>
    );
  }

  if (
    flightAtts !== undefined &&
    flightAtts?.length === 0 &&
    frenchTrainingDates !== undefined &&
    frenchTrainingDates?.length === 0
  ) {
    return (
      <Content
        includeHeader={true}
        title={t('menu.cpm.bases')}
        subtitle={base?.name ? base.initials + ' - ' + t(`bases.names.${base.name}`) : ''}>
        <div className="alert alert-secondary mt-2" role="alert">
          <i className="fa-solid fa-circle-info me-2"></i>
          {t('bases.fa_list.no_records')}
        </div>
      </Content>
    );
  }

  return (
    <>
      <Content
        includeHeader={true}
        title={t('menu.cpm.bases')}
        subtitle={base?.name ? base.initials + ' - ' + t(`bases.names.${base.name}`) : ''}>
        <div className="row">
          <div className="col-8">
            <span className="fs-5 fw-bold text-champagne">
              {t('home.fa.french_training_dates')}
            </span>
          </div>
          <div className="col-4 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary btn-champagne"
              data-bs-toggle="modal"
              data-bs-target="#addFrenchTrainingDatesModal">
              <i className="bi bi-person-plus"></i>
              {t('general.buttons.add_dates')}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="input-group mt-4 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder={t('bases.fa_list.search_by_name_emp_no')}
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                disabled={flightAtts?.length === 0}
                aria-label={t('bases.fa_list.search_by_name_emp_no')}
                aria-describedby="button-search-flightAttendant-by-name"
              />
              <button
                className="btn btn-outline-secondary rounded-end"
                type="button"
                id="button-addon2"
                onClick={clearSearchQuery}
                disabled={flightAtts?.length === 0}>
                {t('general.buttons.clear')}
              </button>
              <ResultAlert
                resultMessage={successMessage}
                setResultMessage={setSuccessMessage}
                resultRef={successRef}
                isSuccess={true}></ResultAlert>
              <ResultAlert
                resultMessage={errorMessage}
                setResultMessage={setErrorMessage}
                resultRef={errorRef}
                isSuccess={false}></ResultAlert>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="overflow-hidden">
            <table className="table accordion ftd align-middle m-0">
              <thead>
                <tr className="text-center" id="headerRow">
                  <th ref={resizeRef} scope="col" id="header1">
                    {t('bases.fa_list.table.employee_no')}
                  </th>
                  <th scope="col" className="text-start" id="header2">
                    {t('bases.fa_list.table.name')}
                  </th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {flightAtts!
                  .filter((fa: FlightAttendant) => {
                    if (query === '') {
                      return fa;
                    } else if (
                      fa.name.toLowerCase().includes(query.toLowerCase()) ||
                      fa.employeeNo.toString().includes(query.toLowerCase())
                    ) {
                      return fa;
                    }
                  })
                  .map((fa: FlightAttendant, index) => (
                    <React.Fragment key={fa.employeeNo + '-fa-' + index}>
                      <tr
                        key={fa.employeeNo + '-fa-' + index}
                        className={`${
                          frenchTrainingDates!.filter((ftd: FrenchTrainingDate) => {
                            if (ftd.flightAttendantId === fa.employeeNo) {
                              return ftd;
                            }
                          }).length === 0
                            ? ''
                            : 'accordion-button'
                        } ftd-button collapsed text-center ${
                          fa.status === INACTIVE ? 'text-body-tertiary bg-secondary-subtle' : ''
                        }`}
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${fa.employeeNo}`}
                        aria-expanded="false">
                        <th scope="row">{fa.employeeNo}</th>
                        <td className="text-capitalize text-start">{fa.name}</td>
                      </tr>
                      <tr
                        className={
                          frenchTrainingDates!.filter((ftd: FrenchTrainingDate) => {
                            if (ftd.flightAttendantId === fa.employeeNo) {
                              return ftd;
                            }
                          }).length === 0
                            ? 'd-none'
                            : ''
                        }>
                        <td colSpan={3} className="hiddenRow">
                          <div
                            className="accordion-body collapse overflow-visible"
                            id={`collapse-${fa.employeeNo}`}>
                            <table className="table table-hover inner-table align-middle m-0">
                              <thead>
                                <tr className="text-center">
                                  <th
                                    scope="col"
                                    className="inner-header1"
                                    style={{ width: header1Width }}>
                                    #
                                  </th>
                                  <th scope="col" className="inner-header2 text-start">
                                    {t('bases.ftd.table.training_date')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="table-group-divider">
                                {frenchTrainingDates!
                                  .filter((ftd: FrenchTrainingDate) => {
                                    if (ftd.flightAttendantId === fa.employeeNo) {
                                      return ftd;
                                    }
                                  })
                                  .sort(
                                    (a, b) =>
                                      new Date(a.trainingDate).getTime() -
                                      new Date(b.trainingDate).getTime()
                                  )
                                  .map((ftd: FrenchTrainingDate, index) => (
                                    <tr
                                      className="text-center"
                                      key={ftd.flightAttendantId + '-sub-' + index}>
                                      <th scope="row">{index + 1}</th>
                                      <td className="text-capitalize text-start">
                                        {Utils.formatDate(
                                          ftd.trainingDate.toString(),
                                          base.id,
                                          selectedLanguage
                                        )}
                                      </td>
                                      <td className="text-capitalize text-end p-0">
                                        <button
                                          className="btn btn-sm btn-x btn-no-border"
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target={`#${REMOVE_FTD}Modal`}
                                          onClick={(e) =>
                                            handleRemoveInformation(fa.employeeNo, ftd.id)
                                          }>
                                          <i className="bi bi-trash-fill"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <ConfirmationModal
          modalType={REMOVE_FTD}
          submitAction={() => {
            removeFTD({
              employeeId: employeeIdToRemove!,
              id: dateToRemove!,
              baseId: base.id,
            });
          }}
          includeHeader={true}>
          <div className="modal-body">{t('general.modals.body.remove_ftd')}</div>
        </ConfirmationModal>
        <AddFrenchTrainingDatesModal
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          flightAtts={flightAtts}
          frenchTrainingDates={frenchTrainingDates}
          base={base}></AddFrenchTrainingDatesModal>
      </Content>
    </>
  );
};
