//Component imports
import {
  Bases,
  GenerateDaysAvailabilityForBase,
  GetBasesAndRoundsByYear,
} from '../config/endpoints-config';
import GeneralService from './GeneralService';

export default class BasesService extends GeneralService {
  async getBasesAndRoundsByYear(year: number) {
    let params = {
      year: year,
    };
    let result = this.getWithParams(GetBasesAndRoundsByYear, params);
    return result;
  }

  async getBases() {
    let result = this.getWithParams(Bases, {});
    return result;
  }
  async getBaseById(id: number) {
    let result = this.getWithId(Bases, id);
    return result;
  }

  async postGenerateDaysAvailabilityForBase(year: number, initials: string) {
    return this.postFromBody(GenerateDaysAvailabilityForBase(year, initials), 0);
  }
}
