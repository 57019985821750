import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { ReportsController } from '../../../../controller/ReportsController';
import { TimetableController } from '../../../../controller/TimetablesController';
import { BID_YEAR, CPM, ROUND_1, ROUND_2 } from '../Constants';
import { useGeneralContext } from '../context/general-context-provider';
import { useUserContext } from '../context/user-context-provider';

interface DownloadFilesModalProps {}

export const DownloadFilesModal = (props: DownloadFilesModalProps) => {
  const { t } = useTranslation();
  const { role, employeeId } = useUserContext();
  const { selectedLanguage } = useGeneralContext();

  const timetableCtrl = new TimetableController();
  const reportsCtrl = new ReportsController();

  const [selectedReport, setSelectedReport] = useState('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasDateError, setHasDateError] = useState<boolean>(false);
  const [hasSuccess, setHasSuccess] = useState<boolean>(false);
  const [r1AllowExport, setR1AllowExport] = useState<boolean>(false);
  const [r2AllowExport, setR2AllowExport] = useState<boolean>(false);
  const [exportingFile, setExportingFile] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  let minDate = useMemo(() => (BID_YEAR ? new Date(BID_YEAR, 0, 1) : undefined), [BID_YEAR]);
  let maxDate = useMemo(() => (BID_YEAR ? new Date(BID_YEAR, 11, 31) : undefined), [BID_YEAR]);

  const getDownloadAllowed = async (roundNo: number) => {
    const response = await timetableCtrl.getDownloadAllowed(Number(employeeId), roundNo, BID_YEAR);
    if (response !== undefined) {
      if (roundNo === ROUND_1) {
        setR1AllowExport(response);
      } else {
        if (roundNo === ROUND_2) {
          setR2AllowExport(response);
        }
      }
    }
  };

  const downloadTimetableExcel = async (roundNo: number) => {
    try {
      setExportingFile(true);
      timetableCtrl
        .getTimetablesExcel(Number(employeeId), roundNo, BID_YEAR, selectedLanguage)
        .then((response) => {
          const type = response?.headers['content-type'];
          const blob = new Blob([response?.data], { type: type });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'timetables_round' + roundNo + '.xlsx';
          link.click();
          setHasSuccess(true);
          setHasError(false);
        })
        .catch(function (error) {
          // handle message
          console.error('getTimetableExcel', error);
          setHasError(true);
        })
        .finally(() => {
          setExportingFile(false);
        });
    } catch (e) {
      //To do: handle error.
      console.log(e);
      setHasError(true);
    }
  };

  const downloadEntitlementReport = async () => {
    try {
      setExportingFile(true);
      reportsCtrl
        .getEntitleReport(BID_YEAR)
        .then((response) => {
          const type = response?.headers['content-type'];
          const blob = new Blob([response?.data], { type: type });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = '00001_EntitleLoad.txt';
          link.click();
          setHasSuccess(true);
          setHasError(false);
        })
        .catch(function (error) {
          // handle message
          console.error('downloadEntitlementReport', error);
          setHasError(true);
        })
        .finally(() => {
          setExportingFile(false);
        });
    } catch (e) {
      //To do: handle error.
      console.log(e);
      setHasError(true);
    }
  };

  const downloadCrewTracReport = async () => {
    try {
      setExportingFile(true);

      if (startDate !== null && endDate !== null) {
        let getReport =
          selectedReport === 'vacation'
            ? reportsCtrl.getCrewTracVAC(
                moment(startDate).format('YYYY-MM-DD') as unknown as Date,
                moment(endDate).format('YYYY-MM-DD') as unknown as Date
              )
            : reportsCtrl.getCrewTracBST(
                moment(startDate).format('YYYY-MM-DD') as unknown as Date,
                moment(endDate).format('YYYY-MM-DD') as unknown as Date
              );
        let filename =
          selectedReport === 'vacation' ? '00001_CTUpload_VAC.txt' : '00001_CTUpload_BST.txt';

        getReport
          .then((response) => {
            const type = response?.headers['content-type'];
            const blob = new Blob([response?.data], { type: type });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            setHasSuccess(true);
            setHasError(false);
          })
          .catch(function (error) {
            // handle message
            console.error('downloadCrewTracReport', error);
            setHasError(true);
          })
          .finally(() => {
            setExportingFile(false);
          });
      } else {
        setHasDateError(true);
      }
    } catch (e) {
      //To do: handle error.
      console.log(e);
      setHasError(true);
    }
  };

  const onExport = () => {
    switch (selectedReport) {
      case 'round1':
        downloadTimetableExcel(ROUND_1);
        break;
      case 'round2':
        downloadTimetableExcel(ROUND_2);
        break;
      case 'entitlement':
        downloadEntitlementReport();
        break;
      case 'vacation':
      case 'bst':
        downloadCrewTracReport();
        break;
      default:
    }
  };

  const discardChanges = () => {
    setHasError(false);
    setHasDateError(false);
    setHasSuccess(false);
    setSelectedReport('');
    setStartDate(null);
    setEndDate(null);
  };

  const reset = () => {
    setHasError(false);
    setHasDateError(false);
    setHasSuccess(false);
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    discardChanges();
    getDownloadAllowed(ROUND_1);
    getDownloadAllowed(ROUND_2);
  }, []);

  if (role === CPM) {
    return (
      <>
        <div
          className="modal fade"
          id="downloadFilesModal"
          tabIndex={-1}
          aria-labelledby="downloadFilesModalLabel"
          aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5 text-start" id="downloadFilesModalLabel">
                  {t('general.modals.titles.export_reports')}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={discardChanges}></button>
              </div>
              <div className="modal-body pb-0">
                <form className="row">
                  <div className="col-12 mb-3 text-start d-flex align-items-center">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={selectedReport}
                      onChange={(e) => {
                        setSelectedReport(e.target.value);
                        reset();
                      }}>
                      <option value="" disabled>
                        {t('general.modals.body.select_report')}
                      </option>
                      <optgroup label={t('general.modals.body.timetables')}>
                        <option value="round1" disabled={!r1AllowExport}>
                          {r1AllowExport
                            ? t('bases.round', { roundNo: ROUND_1 })
                            : `${t('bases.round', { roundNo: ROUND_1 })} ${t(
                                'bases.timetable.incomplete'
                              )}`}
                        </option>
                        <option value="round2" disabled={!r2AllowExport}>
                          {r2AllowExport
                            ? t('bases.round', { roundNo: ROUND_2 })
                            : `${t('bases.round', { roundNo: ROUND_2 })} ${t(
                                'bases.timetable.incomplete'
                              )}`}
                        </option>
                      </optgroup>
                      <optgroup label={t('general.modals.body.crew_trac')}>
                        <option value="entitlement">{t('general.modals.body.entitlement')}</option>
                        <option value="vacation">{t('general.modals.body.vacation')}</option>
                        <option value="bst">{t('general.modals.body.statutory')}</option>
                      </optgroup>
                    </select>
                  </div>
                </form>
                <div
                  className={`row mb-2 ${
                    selectedReport === 'vacation' || selectedReport === 'bst' ? '' : 'd-none'
                  }`}>
                  <div className="col-12 col-md-6 py-2">
                    <label className="form-label">{t('general.modals.body.start_date')}</label>
                    <DatePicker
                      className={`form-control ${
                        startDate === null && hasDateError ? 'is-invalid' : ''
                      }`}
                      placeholderText={t('general.date_format').toLowerCase()}
                      dateFormat={t('general.date_format')}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      minDate={minDate}
                      maxDate={endDate || maxDate}
                      isClearable={true}
                      locale={selectedLanguage}
                      selected={startDate}
                      openToDate={minDate}
                      onChange={(date: any) => {
                        setStartDate(date);
                      }}
                    />
                    <div
                      className={`invalid-feedback ${
                        startDate === null && hasDateError ? 'd-block' : 'd-none'
                      }`}>
                      {t('general.modals.errors.empty_field')}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 py-2">
                    <label className="form-label">{t('general.modals.body.end_date')}</label>
                    <DatePicker
                      className={`form-control ${
                        endDate === null && hasDateError ? 'is-invalid' : ''
                      }`}
                      placeholderText={t('general.date_format').toLowerCase()}
                      dateFormat={t('general.date_format')}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate || minDate}
                      maxDate={endDate || maxDate}
                      isClearable={true}
                      locale={selectedLanguage}
                      selected={endDate}
                      openToDate={minDate}
                      onChange={(date: any) => {
                        setEndDate(date);
                      }}
                    />
                    <div
                      className={`invalid-feedback ${
                        endDate === null && hasDateError ? 'd-block' : 'd-none'
                      }`}>
                      {t('general.modals.errors.empty_field')}
                    </div>
                  </div>
                </div>
                <div className={`row ${hasError ? '' : 'd-none'}`}>
                  <div className="col-12 mb-1 text-start d-flex align-items-center">
                    <div className="alert alert-danger w-100" role="alert">
                      <i className="bi bi-exclamation-circle-fill me-2"></i>
                      {t('general.errors.server_error')}
                    </div>
                  </div>
                </div>
                <div className={`row ${hasSuccess ? '' : 'd-none'}`}>
                  <div className="col-12 mb-1 text-start d-flex align-items-center">
                    <div className="alert alert-success w-100" role="alert">
                      <i className="bi bi-exclamation-circle-fill me-2"></i>
                      {t('general.modals.success.success_export')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row me-auto w-100">
                  <div className="col-12 text-end p-0 d-flex">
                    <button
                      type="button"
                      className={`btn me-2  ${
                        hasSuccess
                          ? 'btn-danger btn-jazz-red w-100'
                          : 'btn-outline-danger btn-outline-jazz-red w-50'
                      }`}
                      data-bs-dismiss="modal"
                      onClick={discardChanges}>
                      {hasSuccess ? t(`general.buttons.close`) : t(`general.buttons.cancel`)}
                    </button>
                    <button
                      type="button"
                      className={`btn btn-danger btn-jazz-red me-2 w-50 ${
                        hasSuccess ? 'd-none' : ''
                      }`}
                      onClick={onExport}>
                      {t(`general.buttons.export`)}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};
