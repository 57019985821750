export interface StandingBidLine {
  id: number;
  flightAttendantId: number;
  roundNo: number;
  bidNo: number;
  year: number;
  startDate: Date;
  endDate: Date;
  minDays: number;
  prefDays: number;
  dateEntered: Date;
  isCreditCarryIn: boolean;
  isAttach: boolean;
  processed: number;
  status: number;
  outcome: TranslationString | null;
}

export interface TranslationString {
  key: string;
  parameters: string[] | null;
}

export class StandingBidLine {
  id: number = 0;
  flightAttendantId: number = 0;
  roundNo: number = 0;
  bidNo: number = 0;
  year: number = 0;
  startDate: Date = new Date();
  endDate: Date = new Date();
  minDays: number = 0;
  prefDays: number = 0;
  dateEntered: Date = new Date();
  isCreditCarryIn: boolean = false;
  isAttach: boolean = false;
  processed: number = 0;
  status: number = 0;
  outcome: TranslationString | null = null;
}

export const SblDefaults: StandingBidLine = {
  id: 0,
  flightAttendantId: 0,
  roundNo: 0,
  bidNo: 0,
  year: 0,
  startDate: new Date(),
  endDate: new Date(),
  dateEntered: new Date(),
  minDays: 0,
  prefDays: 0,
  isCreditCarryIn: false,
  isAttach: false,
  processed: 0,
  status: 0,
  outcome: null,
} as const;
