import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { FAController } from '../../../../controller/FAController';
import { AuditLog } from '../../../../model/AuditLog';
import { Base } from '../../../../model/Base';
import { CPM_PATHS } from '../../../components/utils/Constants';
import Utils from '../../../components/utils/Utils';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';
import { RoundCard } from './round-card';

interface BaseAccordionItemProps {
  base: Base;
}

export const BaseAccordionItem = (props: BaseAccordionItemProps) => {
  const { t } = useTranslation();
  const { selectedLanguage } = useGeneralContext();

  const { base } = props;
  const faCtrl = new FAController();
  const [faCount, setFaCount] = useState(0);
  const [activeFaCount, setActiveFaCount] = useState(0);
  const [r2FaCount, setR2FaCount] = useState(0);
  const [lastUpdatedLog, setLastUpdatedLog] = useState<AuditLog>();

  const fetchFACount = async (baseId: number) => {
    try {
      const response = await faCtrl.getFAsByBaseCount(baseId);
      setFaCount(response ? response : 0);
    } catch (e) {
      //To do: handle error.
      console.log(e);
    }
  };

  const fetchActiveFACount = async (baseId: number) => {
    try {
      const response = await faCtrl.getActiveFAsByBaseCount(baseId);
      setActiveFaCount(response ? response : 0);
    } catch (e) {
      //To do: handle error.
      console.log(e);
    }
  };

  const fetchR2FACount = async (baseId: number) => {
    try {
      const response = await faCtrl.getR2FlightAttendantsCountByBase(baseId);
      setR2FaCount(response ? response : 0);
    } catch (e) {
      //To do: handle error.
      console.log(e);
    }
  };

  const getLastUpdatedFAList = async (baseId: number) => {
    try {
      const response = await faCtrl.getLastUpdatedFAList(baseId);
      setLastUpdatedLog(response);
    } catch (e) {
      //To do: handle error.
      console.log(e);
    }
  };

  useEffect(() => {
    if (base !== undefined) {
      fetchFACount(base.id);
      fetchActiveFACount(base.id);
      fetchR2FACount(base.id);
      getLastUpdatedFAList(base.id);
    }
  }, [base]);

  return (
    <>
      <div className="accordion-item border-0 border-bottom">
        <h2 className="accordion-header" id={`flush-heading${base.initials}`}>
          <button
            className="accordion-button collapsed fw-bold"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#flush-collapse${base.initials}`}
            aria-expanded="false"
            aria-controls={`flush-collapse${base.initials}`}>
            {base.initials}
          </button>
        </h2>
        <div
          id={`flush-collapse${base.initials}`}
          className="accordion-collapse collapse"
          aria-labelledby={`flush-heading${base.initials}`}>
          <div className="accordion-body">
            <div className="row row-cols-1 row-cols-md-3 g-4">
              <div className="col">
                <div className="card h-100">
                  <div className="card-header fw-bold">General</div>
                  <div className="card-body">
                    <div className="card-text text-dark">
                      <div className="row">
                        <div className="col-12">
                          <span className="fw-bold d-block">{t('bases.card.name')}</span>
                          <span className="d-block">{t(`bases.names.${base.name}`)}</span>

                          <span className="fw-bold d-block">{t('bases.card.location')}</span>
                          <span className="d-block">
                            <span className="text-capitalize">{base.city}</span>,
                            <span className="text-uppercase">{base.province}</span>
                          </span>

                          <span className="fw-bold d-block">{t('bases.card.active_fas')}</span>
                          <span className="d-block">
                            {t('bases.card.out_of', { activeFa: activeFaCount, faCount: faCount })}
                          </span>

                          <span className="fw-bold d-block">{t('bases.card.r2_fas')}</span>
                          <span className="d-block">{r2FaCount}</span>
                        </div>
                      </div>
                      <div className="row mt-3 mb-1">
                        <div className="col-12 d-flex">
                          <NavLink
                            to={CPM_PATHS.fa_list}
                            className="btn btn-primary w-auto btn-champagne flex-fill"
                            state={{ base: base }}>
                            <span className="nav_name">{t('general.buttons.fa_list')}</span>
                          </NavLink>
                          <NavLink
                            to={CPM_PATHS.french_training_dates}
                            className="btn btn-outline-primary ms-2 btn-outline-champagne"
                            state={{ base: base }}>
                            <span className="nav_name">
                              {t('general.buttons.french_training_dates')}
                            </span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-muted">
                    <div className={`${lastUpdatedLog?.id !== 0 ? '' : 'd-none'} `}>
                      {t('bases.card.last_updated', {
                        name: lastUpdatedLog?.issuerEmpName,
                        date: Utils.formatDate(
                          lastUpdatedLog?.logDate.toString(),
                          base.id,
                          selectedLanguage
                        ),
                      })}
                    </div>
                    <div className={`${lastUpdatedLog?.id === 0 ? '' : 'd-none'}`}>
                      {t('bases.card.no_updates')}
                    </div>
                  </div>
                </div>
              </div>

              {base.rounds !== undefined && base.rounds.length > 0
                ? base.rounds.map((round) => (
                    <div className="col" key={base.initials + round.id}>
                      <RoundCard
                        round={round}
                        base={base}
                        activeFaCount={activeFaCount}
                        r2FaCount={r2FaCount}></RoundCard>
                    </div>
                  ))
                : ''}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
