import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { FAController } from '../../../../controller/FAController';
import { useBasesList } from '../../../../hooks/BaseHooks';
import {
  useChangeBase,
  useFlightAttendantsForBase,
  useUpdateEntitlement,
} from '../../../../hooks/FlightAttendantHooks';
import { Base } from '../../../../model/Base';
import { Entitlement } from '../../../../model/Entitlement';
import { FlightAttendant } from '../../../../model/FlightAttendant';
import { BID_YEAR, INACTIVE } from '../../../components/utils/Constants';
import { ResultAlert } from '../../../components/utils/alerts/result-alert';
import { Content } from '../../../components/utils/content/content';
import { Loading } from '../../../components/utils/loading/loading';
import { ChangeBaseModal } from '../../../components/utils/modals/change-base-modal';
import { EditFAModal } from '../../../components/utils/modals/edit-fa-modal';
import { ImportFAModal } from '../../../components/utils/modals/import-fa-modal';
import { FrenchControl } from './controls/french-control';
import { R2OptInControl } from './controls/r2-opt-in-control';
import { StatusControl } from './controls/status-control';

interface FAListProps {}

export const FAList = (props: FAListProps) => {
  const { t } = useTranslation();
  let location = useLocation();

  const faCtrl = new FAController();
  const [selectedFa, setSelectedFa] = useState<FlightAttendant>(new FlightAttendant());
  const [query, setQuery] = useState('');

  const base = location.state !== null ? (location.state as { base: Base }).base : undefined;
  const { data: bases } = useBasesList();
  const { data: flighAtts, isFetching: fetchingFlighAtts } = useFlightAttendantsForBase(
    base!.id,
    BID_YEAR
  );

  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const successRef = useRef<null | HTMLDivElement>(null);
  const errorRef = useRef<null | HTMLDivElement>(null);

  const clearSearchQuery = () => {
    setQuery('');
  };

  if (flighAtts === undefined) {
    return (
      <Content
        includeHeader={true}
        title={t('menu.cpm.bases')}
        subtitle={base?.name ? base.initials + ' - ' + t(`bases.names.${base.name}`) : ''}>
        <div className="vh-100">
          <Loading />
        </div>
      </Content>
    );
  }

  if (flighAtts !== undefined && flighAtts?.length === 0) {
    return (
      <Content
        includeHeader={true}
        title={t('menu.cpm.bases')}
        subtitle={base?.name ? base.initials + ' - ' + t(`bases.names.${base.name}`) : ''}>
        <div className="alert alert-secondary mt-2" role="alert">
          <i className="fa-solid fa-circle-info me-2"></i>
          {t('bases.fa_list.no_records')}
        </div>
      </Content>
    );
  }

  return (
    <>
      <Content
        includeHeader={true}
        title={t('menu.cpm.bases')}
        subtitle={base?.name ? base.initials + ' - ' + t(`bases.names.${base.name}`) : ''}>
        <div className="row">
          <div className="col-8">
            <span className="fs-5 fw-bold text-champagne">{t('home.fa.fas')}</span>
          </div>
          <div className="col-4 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary btn-champagne"
              data-bs-toggle="modal"
              data-bs-target="#addfaModal">
              <i className="bi bi-person-plus"></i>
              {t('general.buttons.add_fa')}
            </button>
            <ImportFAModal
              fas={flighAtts}
              tBase={base!}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}></ImportFAModal>
            <ResultAlert
              resultMessage={successMessage}
              setResultMessage={setSuccessMessage}
              resultRef={successRef}
              isSuccess={true}></ResultAlert>
            <ResultAlert
              resultMessage={errorMessage}
              setResultMessage={setErrorMessage}
              resultRef={errorRef}
              isSuccess={false}></ResultAlert>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="input-group mt-4 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder={t('bases.fa_list.search_by_name_emp_no')}
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                disabled={flighAtts?.length === 0}
                aria-label={t('bases.fa_list.search_by_name_emp_no')}
                aria-describedby="button-search-flightAttendant-by-name"
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={clearSearchQuery}
                disabled={flighAtts?.length === 0}>
                {t('general.buttons.clear')}
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="table-responsive overflow-visible">
            <table className="table table-hover align-middle ">
              <thead>
                <tr className="text-center">
                  <th scope="col">{t('bases.fa_list.table.employee_no')}</th>
                  <th scope="col" className="text-start">
                    {t('bases.fa_list.table.name')}
                  </th>
                  <th scope="col">{t('bases.fa_list.table.vacation')}</th>
                  <th scope="col">{t('bases.fa_list.table.statutory')}</th>
                  <th scope="col">{t('bases.fa_list.table.splits')}</th>
                  <th scope="col">{t('bases.fa_list.table.round_2')}</th>
                  <th scope="col">{t('bases.fa_list.table.seniority')}</th>
                  <th scope="col">{t('bases.fa_list.table.french')}</th>
                  <th scope="col">{t('bases.fa_list.table.active')}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {flighAtts!
                  .filter((fa: FlightAttendant) => {
                    if (query === '') {
                      return fa;
                    } else if (
                      fa.name.toLowerCase().includes(query.toLowerCase()) ||
                      fa.employeeNo.toString().includes(query.toLowerCase())
                    ) {
                      return fa;
                    }
                  })
                  .map((fa: FlightAttendant) => (
                    <tr
                      key={fa.employeeNo}
                      className={`text-center ${
                        fa.status === INACTIVE ? 'text-body-tertiary bg-secondary-subtle' : ''
                      }`}>
                      <th scope="row">{fa.employeeNo}</th>
                      <td className="text-capitalize text-start">{fa.name}</td>
                      <td>{fa.entitlement.vacation}</td>
                      <td>{fa.entitlement.statutory}</td>
                      <td>{fa.entitlement.splits}</td>
                      <td>
                        <R2OptInControl
                          key={`r2-${fa.employeeNo}`}
                          fa={fa}
                          base={base!}
                          setSuccessMessage={setSuccessMessage}
                          setErrorMessage={setErrorMessage}></R2OptInControl>
                      </td>
                      <td>{fa.seniority}</td>
                      <td>
                        <FrenchControl
                          key={`fr-${fa.employeeNo}`}
                          fa={fa}
                          base={base!}
                          setSuccessMessage={setSuccessMessage}
                          setErrorMessage={setErrorMessage}></FrenchControl>
                      </td>
                      <td className="mx-auto">
                        <StatusControl
                          key={`status-${fa.employeeNo}`}
                          fa={fa}
                          base={base!}
                          setSuccessMessage={setSuccessMessage}
                          setErrorMessage={setErrorMessage}></StatusControl>
                      </td>
                      <td>
                        <div className="dropdown">
                          <button
                            className="btn btn-outline-secondary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            disabled={fa.status === INACTIVE}
                            onClick={() => {
                              setSelectedFa(fa);
                            }}></button>
                          <ul className="dropdown-menu">
                            <li
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target={`#editFAModal`}>
                              {t('bases.fa_list.edit')}
                            </li>
                            <li
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target={`#changeBaseModal`}>
                              {t('bases.fa_list.change_base')}
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <EditFAModal fa={selectedFa} currentBase={base!}></EditFAModal>
          <ChangeBaseModal fa={selectedFa} currentBase={base!} bases={bases}></ChangeBaseModal>
        </div>
      </Content>
    </>
  );
};
