import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from '../config/axios-instance';
import { GetFAChangeBaseDeadline, GetTimetableForEmployee } from '../config/endpoints-config';
import { Timetable } from '../model/Timetable';

export const useRoundTimetable = (employeeId?: number, roundId?: number) =>
  useQuery<Timetable, AxiosError>(
    ['roundTimetableForEmployee', employeeId, roundId],
    () =>
      axiosInstance.get(GetTimetableForEmployee(employeeId!, roundId!)).then((resp) => resp.data),
    {
      enabled: !!employeeId && !!roundId,
      refetchInterval: 5000,
    } // Query won't run until both args are provided
  );

export const useGetFAChangeBaseDeadline = (flightAttendantEmployeeNo?: number, baseId?: number) =>
  useQuery<Date, AxiosError>(
    ['FAChangeBaseDeadline', flightAttendantEmployeeNo, baseId],
    () =>
      axiosInstance
        .get(GetFAChangeBaseDeadline(flightAttendantEmployeeNo!, baseId!))
        .then((resp) => resp.data),
    {
      enabled: !!flightAttendantEmployeeNo && !!baseId,
      refetchInterval: 5000,
    } // Query won't run until both args are provided
  );
