import { Trans, useTranslation } from 'react-i18next';

import { useAwardedVacationForEmployee } from '../../../../hooks/AwardedVacationHooks';
import { useGetBase } from '../../../../hooks/BaseHooks';
import {
  useEntitlementsForEmployee,
  useUpdateR2Preference,
} from '../../../../hooks/EntitlementHooks';
import { useCurrentFlightAttendant } from '../../../../hooks/FlightAttendantHooks';
import { useActiveRoundForBase } from '../../../../hooks/RoundHooks';
import { useSplitsForEmployee } from '../../../../hooks/SplitHooks';
import {
  useProcessingSBLForEmployee,
  useSblsForEmployee,
} from '../../../../hooks/StandingBidLineHooks';
import { useRoundTimetable } from '../../../../hooks/TimetableHooks';
import {
  ACTIVE,
  BID_YEAR,
  CHANGE_R2_MODAL,
  INACTIVE,
  QUESTIONS_EMAIL,
  ROUND_1,
  ROUND_2,
} from '../../../components/utils/Constants';
import Utils from '../../../components/utils/Utils';
import { R2EnrollmentAlert } from '../../../components/utils/alerts/r2-enrollment-alert';
import { R2FloatingAlert } from '../../../components/utils/alerts/r2-floating-alert';
import { Content } from '../../../components/utils/content/content';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';
import { useUserContext } from '../../../components/utils/context/user-context-provider';
import { ConfirmationModal } from '../../../components/utils/modals/confirmation-modal';
import { BidResultGroup } from '../../common/bid-result/bid-result-group';
import { TimetableAlert } from '../../common/timetable-alert';
import { BackupResultRow } from '../standing-bid/backup-result-row';
import { StatRow } from '../statutory/stat-row';
import { WelcomeAlert } from './welcome-alert';

interface FAHomeArgs {}

export const FAHome = (props: FAHomeArgs) => {
  const { t } = useTranslation();
  const { selectedLanguage } = useGeneralContext();
  const { employeeId } = useUserContext();
  const { data: currAtt } = useCurrentFlightAttendant();
  const { data: base } = useGetBase(currAtt?.base.id);
  console.log('base', base);
  const { data: activeRound } = useActiveRoundForBase(currAtt?.base.initials);
  const { data: timetable } = useRoundTimetable(currAtt?.employeeNo, activeRound?.id);
  const { data: entitlement, isFetching: fetchingEntitlement } = useEntitlementsForEmployee(
    currAtt?.employeeNo,
    BID_YEAR
  );
  const { data: splits } = useSplitsForEmployee(currAtt?.employeeNo, BID_YEAR);
  const { data: awardedVacation } = useAwardedVacationForEmployee(currAtt?.employeeNo, BID_YEAR);
  const {
    mutate: updateR2Preference,
    isError: isR2PrefUpdateError,
    error: r2PrefUpdateError,
    isLoading: r2PrefUpdateInProgress,
  } = useUpdateR2Preference();
  const { data: processingSBL } = useProcessingSBLForEmployee(
    currAtt?.employeeNo,
    BID_YEAR,
    activeRound?.roundNo || ROUND_2
  );
  const { data: r1Backups } = useSblsForEmployee(currAtt?.employeeNo, BID_YEAR, ROUND_1);
  const { data: r2Backups } = useSblsForEmployee(currAtt?.employeeNo, BID_YEAR, ROUND_2);

  const r2PrefLoading = r2PrefUpdateInProgress || fetchingEntitlement;
  // Calculate stats
  let totalVacation = 0;
  let totalStat = 0;
  (splits || []).forEach((group) => {
    (group || []).forEach((split) => {
      const statAmount = split.attachedDaysStart + split.attachedDaysEnd;
      totalVacation += split.totalDays - statAmount;
      totalStat += statAmount;
    });
  });

  const remainingVacation = entitlement ? entitlement.vacation - totalVacation : 0;
  const remainingStat = entitlement ? entitlement.statutory - totalStat : 0;
  const remainingsplits = entitlement && splits ? entitlement.splits - splits.length : 0;

  const round1 = base!.rounds.filter((r) => r.roundNo === 1).at(0);
  const round2 = base!.rounds.filter((r) => r.roundNo === 2).at(0);

  if (currAtt === undefined || entitlement === undefined || currAtt.status === INACTIVE) {
    //Because FA and Entitlement data should be imported together.
    return (
      <Content includeHeader={false}>
        <WelcomeAlert>
          <p className="fs-5 text-danger">
            <Trans
              i18nKey="home.fa.welcome_subtitle_error"
              values={{
                year: BID_YEAR,
                email: QUESTIONS_EMAIL,
              }}
            />
          </p>
        </WelcomeAlert>
      </Content>
    );
  }

  return (
    <Content includeHeader={false}>
      <WelcomeAlert>
        <p className="fs-5 text-danger">
          <Trans
            i18nKey="home.fa.welcome_subtitle"
            values={{
              year: activeRound ? activeRound.year : BID_YEAR,
              email: QUESTIONS_EMAIL,
            }}
          />
        </p>
      </WelcomeAlert>
      <div className="p-4 py-2 mb-4 container-fluid">
        <div className="row">
          <div className="col-auto">
            <h3>
              <Trans i18nKey="home.fa.info_header" />
            </h3>
          </div>
        </div>
        {/*Employee details, Vacation, BST, Splits*/}
        <div className={`row gy-3 mt-1`}>
          <div className="col-xl-3 col-md-6 col-sm-12">
            <div className="card h-100">
              <h5 className="card-header">
                <Trans i18nKey="home.fa.emp_details" />
              </h5>
              <div className="card-body">
                <h6>
                  <Trans i18nKey="home.fa.employee_id" />
                </h6>
                <p className="card-text">{employeeId}</p>
                <h6>
                  <Trans i18nKey="home.fa.base" />
                </h6>
                <p className="card-text">{currAtt?.base?.initials || 'No base'}</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12">
            <div className="card h-100">
              <h5 className="card-header">
                <Trans i18nKey="home.fa.vacation_details" />
              </h5>
              <div className="card-body">
                <div className="mb-3">
                  <Trans i18nKey="home.fa.allowed" />
                  <span className="badge rounded=pill text-bg-secondary float-end px-3">
                    {entitlement?.vacation !== undefined ? entitlement?.vacation : '...'}
                  </span>
                </div>
                <div className="mb-3">
                  <Trans i18nKey="home.fa.remain" />
                  <span className="badge rounded=pill text-bg-secondary float-end px-3">
                    {remainingVacation}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12">
            <div className="card h-100">
              <h5 className="card-header">
                <Trans i18nKey="home.fa.stat_days_details" />
              </h5>
              <div className="card-body">
                <div className="mb-3">
                  <Trans i18nKey="home.fa.allowed" />
                  <span className="badge rounded=pill text-bg-secondary float-end px-3">
                    {entitlement?.statutory !== undefined ? entitlement?.statutory : '...'}
                  </span>
                </div>
                <div className="mb-3">
                  <Trans i18nKey="home.fa.remain" />
                  <span className="badge rounded=pill text-bg-secondary float-end px-3">
                    {remainingStat}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12">
            <div className="card h-100">
              <h5 className="card-header">
                <Trans i18nKey="home.fa.splits_details" />
              </h5>
              <div className="card-body">
                <div className="mb-3">
                  <Trans i18nKey="home.fa.allowed_splits" />
                  <span className="badge rounded=pill text-bg-secondary float-end px-3">
                    {entitlement?.splits !== undefined ? entitlement?.splits : '...'}
                  </span>
                </div>
                <div className="mb-3">
                  <Trans i18nKey="home.fa.remain_splits" />
                  <span className="badge rounded=pill text-bg-secondary float-end px-3">
                    {remainingsplits}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Round 1*/}
        <div className={`row mt-5 ${round1?.submitted ? '' : 'd-none'}`}>
          <div className="col-12">
            <h4 className="text-champagne fw-bold">
              <Trans i18nKey="vacation.round_1_subtitle" />
            </h4>
            <TimetableAlert round={round1!} currAtt={currAtt!}></TimetableAlert>
            <R2EnrollmentAlert
              currAtt={currAtt}
              entitlement={entitlement!}
              showChangeButton={activeRound !== undefined && activeRound.roundNo === ROUND_1}
            />
            {(currAtt && r1Backups && r1Backups.length > 0 && (
              <div className="accordion" id="round1sbAccordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne">
                      <Trans components={{ bold: <b /> }} i18nKey="standing.results_header" />
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#round1sbAccordion">
                    <div className="accordion-body">
                      {r1Backups?.map((backup, _) => (
                        <BackupResultRow
                          key={backup.bidNo}
                          index={backup.bidNo}
                          backup={backup}
                          baseId={currAtt.baseId}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )) ||
              ''}
          </div>
        </div>

        {/*Round 2*/}
        <div className={`row mt-5 ${round2?.submitted ? '' : 'd-none'}`}>
          <div className="col-12">
            <h4 className="text-champagne fw-bold">
              <Trans i18nKey="statutory.round_2_subtitle" />
            </h4>
            <TimetableAlert round={round2!} currAtt={currAtt!}></TimetableAlert>
            {(currAtt && r2Backups && r2Backups.length > 0 && (
              <div className="accordion" id="round2sbAccordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo">
                      <Trans components={{ bold: <b /> }} i18nKey="standing.results_header" />
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#round2sbAccordion">
                    <div className="accordion-body">
                      {r2Backups?.map((backup, _) => (
                        <BackupResultRow
                          key={backup.bidNo}
                          index={backup.bidNo}
                          backup={backup}
                          baseId={currAtt.baseId}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )) ||
              ''}
          </div>
        </div>

        {(currAtt && splits && splits.length > 0 && (
          <div className={`row mt-5`}>
            <div className="col-12">
              <h4 className="text-champagne fw-bold">
                <Trans i18nKey="home.fa.splits_details" />
              </h4>
              {processingSBL && (
                <div className="alert alert-info" role="alert">
                  <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
                  <span role="status">{t('standing.wait_processing_backups')}</span>
                </div>
              )}
              {!processingSBL &&
                splits?.map((group, idx) => (
                  <BidResultGroup index={idx + 1} splits={group} baseId={currAtt.baseId} />
                ))}
            </div>
          </div>
        )) ||
          ''}
      </div>

      {!entitlement?.partR2 && activeRound !== undefined && activeRound.roundNo === ROUND_1 && (
        <R2FloatingAlert currAtt={currAtt}></R2FloatingAlert>
      )}
      <ConfirmationModal
        modalType={CHANGE_R2_MODAL}
        employeeId={currAtt.employeeNo}
        submitAction={() => {
          updateR2Preference({
            employeeId: currAtt!.employeeNo,
            year: activeRound!.year || BID_YEAR,
            r2Preference: !entitlement?.partR2,
            baseId: currAtt!.baseId,
          });
        }}
        includeHeader={true}>
        <span>
          <Trans
            i18nKey={
              entitlement?.partR2
                ? 'general.modals.body.r2_opt_out_confirm'
                : 'general.modals.body.r2_opt_in_confirm'
            }
          />
        </span>
      </ConfirmationModal>
    </Content>
  );
};
