import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useDeleteSplit } from '../../../../hooks/SplitHooks';
import { Split } from '../../../../model/Split';
import { DELETE_SPLIT } from '../../../components/utils/Constants';
import Utils from '../../../components/utils/Utils';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';
import { ConfirmationModal } from '../../../components/utils/modals/confirmation-modal';

type VacationRowProps = {
  index: number;
  split: Split;
  baseId: number;
  committed: boolean;
  onEdit: (split: Split) => void;
  bidEnded: boolean;
  isActiveRound: boolean;
  processingSBL: boolean;
};

export function VacationRow({
  index,
  split,
  baseId,
  committed,
  onEdit,
  bidEnded: bidExpired,
  processingSBL,
  isActiveRound,
}: VacationRowProps) {
  const { mutate: deleteSplit, isLoading, status } = useDeleteSplit();
  const { t } = useTranslation();
  const { selectedLanguage } = useGeneralContext();

  const data: {
    transkey: string;
    content: ReactNode;
    defaultSize?: number;
    mdSize?: number;
    xxlSize?: number;
  }[] = [
    {
      transkey: 'vacation.table.start_date',
      content: <>{Utils.formatDate(split.startDate.toString(), baseId, selectedLanguage)}</>,
    },
    {
      transkey: 'vacation.table.end_date',
      content: <>{Utils.formatDate(split.endDate.toString(), baseId, selectedLanguage)}</>,
    },
    { transkey: 'vacation.table.total_days', content: <>{split.totalDays}</> },
    {
      transkey: 'general.modals.body.is_credit_carry',
      content: <>{t(split.isCreditCarryIn ? 'general.buttons.yes' : 'general.buttons.no')}</>,
    },
    {
      transkey: 'vacation.table.status',
      content: (
        <span className={`badge ${committed ? 'text-bg-success' : 'text-bg-champagne'} p-2`}>
          {t(committed ? 'general.status.committed' : 'general.status.modifying')}
        </span>
      ),
    },
  ];

  return (
    <div className="alert alert-secondary print-break-inside-avoid">
      <div className="row">
        {/*<div className="col-12 col-md-4 col-xxl-1 mb-2 mb-xxl-0">*/}
        {/*  <span className="fw-bold fs-5">*/}
        {/*    <Trans i18nKey="vacation.table.split" /> {index}*/}
        {/*  </span>*/}
        {/*</div>*/}
        {data.map((d, idx) => (
          <div
            key={idx}
            className={`col-${d.defaultSize || 6} col-md-${d.mdSize || 4} col-xxl-${
              d.xxlSize || 2
            } text-xxl-center mb-2 mb-xxl-0`}>
            <span className="fw-bold d-block">
              <Trans i18nKey={d.transkey} />
            </span>
            {d.content}
          </div>
        ))}
        <div className="col-12 col-xxl-1 text-xxl-center mb-xxl-0 justify-content-xxl-center align-items-xxl-center px-xxl-0 d-print-none d-flex">
          {isActiveRound && (
            <button
              disabled={isLoading || !isActiveRound || processingSBL}
              onClick={() => onEdit(split)}
              data-bs-toggle="modal"
              data-bs-target="#splitModal"
              className="btn btn-champagne mx-1 flex-grow-1 flex-xxl-grow-0 w-50 w-xl-auto">
              <i className="bi bi-pencil-fill me-0"></i>
              <span className="d-sm-inline-block d-xxl-none ms-1">
                {t('general.buttons.modify')}
              </span>
            </button>
          )}
          {!bidExpired && isActiveRound && (
            <>
              <button
                disabled={isLoading || processingSBL}
                data-bs-toggle="modal"
                data-bs-target={`#${DELETE_SPLIT + split.flightAttendantId + split.id}Modal`}
                className="btn btn-outline-champagne mx-1 flex-grow-1 flex-xxl-grow-0 w-50 w-xl-auto">
                <i className="bi bi-trash me-0"></i>
                <span className="d-sm-inline-block d-xxl-none ms-1">
                  {t('general.buttons.delete')}
                </span>
              </button>
              <ConfirmationModal
                modalType={DELETE_SPLIT}
                employeeId={split.flightAttendantId}
                submitAction={() =>
                  deleteSplit({
                    id: split.id,
                    year: split.year,
                    employeeId: split.flightAttendantId,
                  })
                }
                includeHeader={true}
                values={{ index: split.id }}>
                {t('general.modals.body.delete_split', {
                  startDate: Utils.formatDate(split.startDate.toString(), baseId, selectedLanguage),
                  endDate: Utils.formatDate(split.endDate.toString(), baseId, selectedLanguage),
                })}
              </ConfirmationModal>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
