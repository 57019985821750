import { AuthenticationResult, EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { msalConfig } from './config/auth-config';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './view/components/general/app';
import './view/components/general/i18n';
import { UserInfoProvider } from './view/components/utils/context/user-context-provider';
import { Loading } from './view/components/utils/loading/loading';

//Constants
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS &&
    (event.payload as AuthenticationResult)?.account
  ) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// Handle when re-login is required
msalInstance.addEventCallback((event) => {
  if (event.eventType !== EventType.ACQUIRE_TOKEN_FAILURE) return;
  msalInstance.loginRedirect();
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <UserInfoProvider>
        <QueryClientProvider client={queryClient}>
          <React.Suspense fallback={<Loading />}>
            <App />
          </React.Suspense>
        </QueryClientProvider>
      </UserInfoProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
