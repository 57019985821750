import { Base } from './Base';
import { Entitlement } from './Entitlement';

export interface FlightAttendant {
  employeeNo: number;
  baseId: number;
  name: string;
  seniority: number;
  frenchLang: number;
  status: number;
  entitlement: Entitlement;
  base: Base;
  lastManualEmail: Date;
  lastEmailSent: Date;
}

export class FlightAttendant {
  employeeNo: number = 0;
  baseId: number = 0;
  name: string = '';
  seniority: number = 0;
  frenchLang: number = 0;
  status: number = 0;
  entitlement: Entitlement = {
    id: 0,
    flightAttendantId: 0,
    year: 0,
    baseId: 0,
    base: { city: '', id: 0, initials: '', name: '', province: '', rounds: [] },
    vacation: 0,
    statutory: 0,
    banked: 0,
    splits: 0,
    partR2: false,
  };
  base: Base = { city: '', id: 0, initials: '', name: '', province: '', rounds: [] };
}
