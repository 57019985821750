import { useTranslation } from 'react-i18next';

import { useUpdateFA, useUpdateFaStatus } from '../../../../../hooks/FlightAttendantHooks';
import { Base } from '../../../../../model/Base';
import { FlightAttendant } from '../../../../../model/FlightAttendant';
import { ACTIVE, BID_YEAR, ENABLE_FA_MODAL } from '../../../../components/utils/Constants';
import { ConfirmationModal } from '../../../../components/utils/modals/confirmation-modal';

interface StatusControlProps {
  fa: FlightAttendant;
  base: Base;
  setSuccessMessage: (value: string) => void;
  setErrorMessage: (value: string) => void;
}

export const StatusControl = ({
  fa,
  base,
  setErrorMessage,
  setSuccessMessage,
}: StatusControlProps) => {
  const { t } = useTranslation();

  const {
    mutate: updateFA,
    isError: isFAUpdateError,
    error: faUpdateError,
    isLoading: faUpdateInProgress,
  } = useUpdateFaStatus(setErrorMessage, setSuccessMessage);

  return (
    <>
      <div className="form-check form-switch d-flex justify-content-center">
        <input
          className={`form-check-input ${fa.status === ACTIVE ? '' : 'form-check-inactive'}`}
          type="checkbox"
          checked={fa.status === ACTIVE}
          disabled={faUpdateInProgress}
          role="switch"
          data-bs-toggle="modal"
          data-bs-target={`#${ENABLE_FA_MODAL + fa.employeeNo}Modal`}
          onChange={(e) => e.stopPropagation()}
        />
      </div>

      <ConfirmationModal
        modalType={ENABLE_FA_MODAL}
        employeeId={fa.employeeNo}
        submitAction={() => {
          updateFA({
            employeeId: fa.employeeNo,
            status: Number(!fa.status),
            baseId: base!.id,
          });
        }}
        includeHeader={true}>
        <div className="modal-body">{t('general.modals.body.enable_fa')}</div>
      </ConfirmationModal>
    </>
  );
};
