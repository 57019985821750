import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import axiosInstance from '../config/axios-instance';
import { SendSummaryEmail } from '../config/endpoints-config';

interface SendSummaryEmailParameters {
  employeeId: number;
  year: number;
}

export const useSendSummaryForEmployee = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, SendSummaryEmailParameters>(
    (iParams) => axiosInstance.get(SendSummaryEmail(iParams.employeeId, iParams.year)),
    {
      onSuccess: (resp, _) => {
        queryClient.invalidateQueries(['current']);
      },
    }
  );
};
