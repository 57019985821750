import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import axiosInstance from '../config/axios-instance';
import { GetLanguagePreferenceByEmployeeId, LanguagePreferences } from '../config/endpoints-config';
import { LanguagePreference } from '../model/LanguagePreference';
import Utils from '../view/components/utils/Utils';

const DEFAULT_LANGUAGE = 'en';

export const useLanguagePrefByEmpId = (employeeId?: number) =>
  useQuery<string, AxiosError>(
    ['languagePref', employeeId],
    () =>
      axiosInstance
        .get<LanguagePreference>(GetLanguagePreferenceByEmployeeId, {
          params: { employeeNo: employeeId },
        })
        .then((resp) => Utils.getLangKey(!!resp.data ? resp.data?.language : 0)),
    { enabled: !!employeeId, placeholderData: DEFAULT_LANGUAGE }
  );

interface LanguagePreferenceSubmission {
  employeeNo: number;
  language: number;
}

export const useSetLanguagePref = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, LanguagePreferenceSubmission>(
    (pref) => axiosInstance.post(LanguagePreferences, pref),
    {
      onSuccess: (resp, pref) => {
        queryClient.invalidateQueries(['languagePref', pref.employeeNo]);
      },
    }
  );
};
