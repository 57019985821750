import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { StandingBidLine } from '../../../../model/StandingBidLine';
import { DELETE_BACKUP, FA_PATHS, ROUND_1, ROUND_2 } from '../../../components/utils/Constants';
import Utils from '../../../components/utils/Utils';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';

interface BackupResultRowProps {
  index: number;
  backup: StandingBidLine;
  baseId: number | undefined;
}

export const BackupResultRow = (props: BackupResultRowProps) => {
  const { t } = useTranslation();
  const { index, baseId, backup } = props;
  const { selectedLanguage } = useGeneralContext();

  return (
    <div className="print-break-inside-avoid">
      <div className="row">
        <div className="col-12 col-md-4 col-xxl-1 mb-2 mb-xxl-0">
          <span className="fw-bold fs-5 text-secondary">
            <Trans i18nKey="standing.table.backup" /> {index}
          </span>
        </div>
      </div>
      <div className="alert alert-secondary">
        <div className="row">
          <div className="col-6 col-xl-2 text-xl-center">
            <span className="fw-bold d-block">
              <Trans i18nKey="standing.table.start_date" />
            </span>{' '}
            {Utils.formatDateTimeOffsetIgnoreTZ(backup.startDate.toString(), selectedLanguage)}
          </div>
          <div className="col-6 col-xl-2 text-xl-center">
            <span className="fw-bold">
              <Trans i18nKey="standing.table.end_date" />
            </span>{' '}
            <br />
            {Utils.formatDateTimeOffsetIgnoreTZ(backup.endDate.toString(), selectedLanguage)}
          </div>
          <div className="col-6 col-xl-2 text-xl-center">
            <span className="fw-bold">
              <Trans i18nKey="standing.table.pref_days" />
            </span>{' '}
            <br /> {backup.prefDays}
          </div>
          <div className="col-6 col-xl-2 text-xl-center">
            <span className="fw-bold">
              <Trans i18nKey="standing.table.min_days" />
            </span>{' '}
            <br /> {backup.minDays}
          </div>
          <div className="col-6 col-xl-2 text-xl-center">
            <span className="fw-bold">
              <Trans i18nKey="general.modals.body.is_credit_carry" />
            </span>{' '}
            <br /> {t(backup.isCreditCarryIn ? 'general.buttons.yes' : 'general.buttons.no')}
          </div>
          <div className="col-6 col-xl-2 text-xl-center">
            <span className="fw-bold">
              <Trans i18nKey="standing.table.outcome" />
            </span>{' '}
            <br />{' '}
            {(backup.outcome && (
              <Trans
                i18nKey={backup.outcome.key}
                values={
                  (backup.outcome.parameters === null && {}) ||
                  backup.outcome.parameters?.reduce(
                    (acc: { [key: string]: string }, curr: string, idx: number) => {
                      acc[idx.toString()] = curr;
                      return acc;
                    },
                    {}
                  )
                }
              />
            )) || <Trans i18nKey="standingBid.outcome.unprocessed" />}
          </div>
        </div>
      </div>
    </div>
  );
};
