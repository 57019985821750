import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

interface ResultAlertProps {
  resultMessage: string;
  setResultMessage: (value: string) => void;
  resultRef: any;
  isSuccess: boolean;
}

export const ResultAlert = (props: ResultAlertProps) => {
  const { resultMessage, setResultMessage, resultRef, isSuccess } = props;

  const handleClose = () => {
    setResultMessage('');
  };

  return (
    <>
      <div
        className={`position-fixed bottom-0 end-0 m-4 position-in-front z-index-top ${
          resultMessage !== '' ? 'show' : 'd-none'
        }`}>
        <div
          ref={resultRef}
          className={`bd-callout  ${
            isSuccess ? 'bd-callout-success' : 'bd-callout-jazz-red text-jazz-red'
          } fade ${resultMessage !== '' ? 'show' : ''}`}
          role="alert">
          <i
            className={`bi ${
              isSuccess ? 'bi-check-circle-fill' : 'bi-exclamation-circle-fill'
            }  me-2`}></i>
          <span className="me-4">{resultMessage}</span>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}></button>
        </div>
      </div>
    </>
  );
};
