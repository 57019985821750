import { Base } from '../model/Base';
import BasesService from '../services/BasesService';

export class BasesController {
  private bases: Base[] = [];
  private basesService: BasesService;

  constructor() {
    this.basesService = new BasesService();
  }

  public async getBasesAndRoundsByYear(year: number) {
    try {
      const response = await this.basesService.getBasesAndRoundsByYear(year);

      if (response !== undefined || response !== null) {
        this.bases = response?.data;
        return this.bases as Base[];
      }
    } catch (error) {
      console.log('BasesController Error - ', error);
    }
  }

  public async getBases() {
    try {
      const response = await this.basesService.getBases();

      if (response !== undefined || response !== null) {
        return response?.data as Base[];
      }
    } catch (error) {
      console.log('BasesController Error - ', error);
    }
  }

  public async getBaseById(id: number) {
    try {
      const response = await this.basesService.getBaseById(id);

      if (response !== undefined || response !== null) {
        return response?.data as Base;
      }
    } catch (error) {
      console.log('BasesController Error - ', error);
    }
  }

  public async postGenerateDaysAvailabilityForBase(year: number, initials: string) {
    try {
      return this.basesService.postGenerateDaysAvailabilityForBase(year, initials);
    } catch (error) {
      console.log('BasesController Error - ', error);
    }
  }
}
