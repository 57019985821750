import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useUpdateEntitlement } from '../../../../hooks/FlightAttendantHooks';
import { Base } from '../../../../model/Base';
import { Entitlement } from '../../../../model/Entitlement';
import { FlightAttendant } from '../../../../model/FlightAttendant';
import { BID_YEAR } from '../Constants';
import Utils from '../Utils';

interface EditFAModalProps {
  fa: FlightAttendant;
  currentBase: Base;
}

export const EditFAModal = (props: EditFAModalProps) => {
  const { t } = useTranslation();
  const { fa, currentBase } = props;
  const [vacation, setVacation] = useState<number>(fa.entitlement.vacation || 0);
  const [statutory, setStatutory] = useState<number>(fa.entitlement.statutory || 0);
  const [splits, setSplits] = useState<number>(fa.entitlement.splits || 0);
  const [savedChanges, setSavedChanges] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    mutate: updateEntitlement,
    isError,
    isSuccess,
    error: entitlementUpdateError,
    isLoading: entitlementUpdateInProgress,
    reset,
  } = useUpdateEntitlement(setErrorMessage);

  const setIncomingValues = () => {
    if (fa !== undefined) {
      setVacation(fa.entitlement.vacation);
      setStatutory(fa.entitlement.statutory);
      setSplits(fa.entitlement.splits);
    }
  };

  const handleSaveModalChanges = (employeeNo: number, entitlement: Entitlement) => {
    entitlement.base = currentBase!;
    updateEntitlement({
      employeeId: employeeNo,
      year: BID_YEAR,
      entitlement: entitlement,
      baseId: currentBase!.id,
    });
  };

  const saveChanges = () => {
    let entitlement = {
      ...fa.entitlement,
    };
    entitlement.vacation = vacation;
    entitlement.statutory = statutory;
    entitlement.splits = splits;
    setSavedChanges(true);
    handleSaveModalChanges(fa.employeeNo, entitlement);
  };

  const discardChanges = () => {
    setIncomingValues();
    setSavedChanges(true);
    reset();
  };

  useEffect(() => {
    discardChanges();
  }, [fa]);

  return (
    <>
      <div
        className="modal fade"
        id={`editFAModal`}
        tabIndex={-1}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-start" id="exampleModalLabel">
                {t('bases.fa_list.edit')}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={discardChanges}></button>
            </div>
            <div className="modal-body pb-0">
              <div className="row">
                <div className="col-12 mb-3 text-start">
                  <h5 className="text-danger">{fa?.name}</h5>
                </div>
              </div>
              <form className="row">
                <div className="col-6 mb-3 text-start">
                  <label className="form-label">{t('general.modals.body.vacation')}</label>
                  <input
                    type="number"
                    className="form-control"
                    id="vacation"
                    min={1}
                    aria-describedby="vacation"
                    value={vacation}
                    onChange={(e) => {
                      setVacation(Number(e.target.value));
                      setSplits(Utils.getMaxSplitsNumber(Number(e.target.value) + statutory));
                      setSavedChanges(false);
                    }}
                  />
                </div>
                <div className="col-6 mb-3 text-start">
                  <label className="form-label">{t('general.modals.body.statutory')}</label>
                  <input
                    type="number"
                    className="form-control"
                    id="statutory"
                    min={1}
                    value={statutory}
                    onChange={(e) => {
                      setStatutory(Number(e.target.value));
                      setSplits(Utils.getMaxSplitsNumber(Number(e.target.value) + vacation));
                      setSavedChanges(false);
                    }}
                  />
                </div>
                <div className="col-6 mb-3 text-start">
                  <label className="form-label">{t('general.modals.body.splits')}</label>
                  <input
                    type="number"
                    className="form-control"
                    id="splits"
                    disabled={true}
                    value={splits}
                    onChange={() => {}}
                  />
                </div>
                <div className="col-6 mb-3 text-start">
                  <label className="form-label">{t('general.modals.body.seniority')}</label>
                  <input
                    type="number"
                    className="form-control"
                    id="seniority"
                    disabled={true}
                    value={fa.seniority}
                    onChange={() => {}}
                  />
                </div>
              </form>
              <div className="row">
                <div className="col-12 text-start mt-2">
                  <div
                    className={`form-small-text alert alert-secondary w-100 ${
                      savedChanges ? 'd-none' : 'd-inline-block'
                    }`}>
                    {t('general.modals.body.unsaved_changes')}
                    <button
                      className="btn btn-link link-secondary btn-sm"
                      onClick={() => {
                        discardChanges();
                      }}>
                      {t(`general.buttons.discard_changes`)}
                    </button>
                  </div>
                  {/*Success alert*/}
                  <div
                    className={`alert alert-success w-100 ${
                      isSuccess ? 'd-inline-block' : 'd-none'
                    }`}
                    role="alert">
                    <i className={`fa-solid fa-circle-check me-2`}></i>
                    <Trans i18nKey="general.status.success" />
                  </div>

                  {/*Error alert*/}
                  <div
                    className={`alert alert-danger w-100 ${isError ? 'd-inline-block' : 'd-none'}`}
                    role="alert">
                    <i className={`fa-solid fa-circle-check me-2`}></i>
                    {errorMessage}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row me-auto w-100">
                <div className="col-12 text-end p-0 d-flex">
                  {isSuccess ? (
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      className="btn btn-outline-danger btn-outline-jazz-red w-100"
                      onClick={discardChanges}>
                      {t(`general.buttons.close`)}
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="btn btn-outline-danger btn-outline-jazz-red me-2 w-50"
                        onClick={discardChanges}>
                        {t(`general.buttons.cancel`)}
                      </button>
                      <button
                        type="button"
                        className={`btn btn-danger btn-jazz-red w-50 `}
                        onClick={saveChanges}
                        disabled={savedChanges}>
                        {t(`general.buttons.save`)}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
