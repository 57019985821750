import axiosInstance from '../config/axios-instance';

export default class GeneralService {
  async get(endpoint: string) {
    return await axiosInstance.get(endpoint, {
      headers: {
        Accept: 'text/plain',
      },
    });
  }

  async getWithParams(endpoint: string, params: any) {
    return await axiosInstance.get(endpoint, {
      headers: {
        Accept: 'text/plain',
      },
      params: params,
    });
  }

  async getWithId(endpoint: string, id: number) {
    return await axiosInstance.get(endpoint + id, {
      headers: {
        Accept: 'text/plain',
      },
    });
  }

  async getFileWithParams(endpoint: string, params: any) {
    return await axiosInstance.get(endpoint, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json',
      },
      params: params,
    });
  }

  async putWithBodyIdAndParams(endpoint: string, body: any, id: number, params: any) {
    return await axiosInstance.put(endpoint + id, body, {
      headers: {
        Accept: 'text/plain',
      },
      params: params,
    });
  }

  async putWithBodyId(endpoint: string, body: any, id: number) {
    return await axiosInstance.put(endpoint + id, body, {
      headers: {
        Accept: 'text/plain',
      },
    });
  }

  async putWithBody(endpoint: string, body: any) {
    return await axiosInstance.put(endpoint, body, {
      headers: {
        Accept: 'text/plain',
      },
    });
  }

  async postWithBody(endpoint: string, body: any) {
    return await axiosInstance.post(endpoint, body, {
      headers: {
        Accept: 'text/plain',
      },
    });
  }

  async postFromBody(endpoint: string, parameter: any) {
    return await axiosInstance.post(endpoint, parameter, {
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json',
      },
    });
  }

  async postWithBodyAndParams(endpoint: string, body: any, params: any) {
    return await axiosInstance.post(endpoint, body, {
      headers: {
        Accept: 'text/plain',
      },
      params: params,
    });
  }

  async deleteWithParams(endpoint: string, params: any) {
    return await axiosInstance.delete(endpoint, {
      headers: {
        Accept: 'text/plain',
      },
      params: params,
    });
  }
}
