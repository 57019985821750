import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './early-alert.module.scss';

interface EarlyAlertProps {
  headerKey: string;
  bodyKey: string;
  linkTo: string;
  linkKey: string;
  roundNo: number;
  values?: any;
  impersonateFaEmpNo?: any;
}

export const EarlyAlert = (props: EarlyAlertProps) => {
  const { t } = useTranslation();

  return (
    <div className="alert alert-secondary row" role="alert">
      <div className="col-2 d-none d-xl-block text-center">
        <i className={`bi bi-exclamation-circle ${styles.early_icon}`} />
      </div>
      <div className="col d-flex flex-column justify-content-center align-items-start">
        <h2>{t(props.headerKey)}</h2>
        <div>
          <Trans
            i18nKey={props.bodyKey}
            components={{
              strong: <strong className="text-champagne" />,
              sbl: <Link to={props.linkTo} className="text-champagne" />,
            }}
            values={props.values}
          />
        </div>
        <Link
          to={props.linkTo}
          state={{ roundNo: props.roundNo, impersonateFaEmpNo: props.impersonateFaEmpNo }}
          className="btn btn-champagne p-3">
          {t(props.linkKey)}
        </Link>
      </div>
    </div>
  );
};
