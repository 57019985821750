import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useDeleteSbl } from '../../../../hooks/StandingBidLineHooks';
import { StandingBidLine } from '../../../../model/StandingBidLine';
import { DELETE_BACKUP, FA_PATHS, ROUND_1, ROUND_2 } from '../../../components/utils/Constants';
import Utils from '../../../components/utils/Utils';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';
import { ConfirmationModal } from '../../../components/utils/modals/confirmation-modal';

interface BackupRowProps {
  index: number;
  backup: StandingBidLine;
  baseId: number | undefined;
  onEdit: (backup: StandingBidLine) => void;
  setIsAttach: (isAttach: boolean) => void;
}

export const BackupRow = (props: BackupRowProps) => {
  const { t } = useTranslation();
  const { index, baseId, onEdit, backup, setIsAttach } = props;
  const { mutate: deleteSbl, isLoading, status } = useDeleteSbl();
  const { selectedLanguage } = useGeneralContext();

  return (
    <div className="alert alert-secondary">
      <div className="row">
        <div className="col-12 col-xl-12 mb-2">
          <h6 className="fw-bold fs-5">
            <Trans i18nKey="standing.table.backup" /> {index}
            {backup.processed === 0 ? (
              <span className="badge text-bg-secondary ms-1">
                <small>{t('standingBid.outcome.unprocessed')}</small>{' '}
              </span>
            ) : (
              <span className="badge text-bg-success ms-1">
                <small>{t('standingBid.outcome.processed')}</small>{' '}
              </span>
            )}
          </h6>
        </div>
        <div className="col-6 col-xl-2 text-xl-center">
          <span className="fw-bold d-block">
            <Trans i18nKey="standing.table.start_date" />
          </span>{' '}
          {Utils.formatDateTimeOffsetIgnoreTZ(backup.startDate.toString(), selectedLanguage)}
        </div>
        <div className="col-6 col-xl-2 text-xl-center">
          <span className="fw-bold">
            <Trans i18nKey="standing.table.end_date" />
          </span>{' '}
          <br />
          {Utils.formatDateTimeOffsetIgnoreTZ(backup.endDate.toString(), selectedLanguage)}
        </div>
        <div className="col-6 col-xl-2 text-xl-center">
          <span className="fw-bold">
            <Trans i18nKey="standing.table.pref_days" />
          </span>{' '}
          <br /> {backup.prefDays}
        </div>
        <div className="col-6 col-xl-2 text-xl-center">
          <span className="fw-bold">
            <Trans i18nKey="standing.table.min_days" />
          </span>{' '}
          <br /> {backup.minDays}
        </div>
        <div className="col-12 col-xl-2 text-xl-center">
          <span className="fw-bold">
            <Trans i18nKey="general.modals.body.is_credit_carry" />
          </span>{' '}
          <br /> {t(backup.isCreditCarryIn ? 'general.buttons.yes' : 'general.buttons.no')}
        </div>
        <div className="col-6 col-xl-1 text-xl-center mt-2 mb-xl-0 d-xl-flex justify-content-end px-xl-0">
          <button
            disabled={isLoading}
            onClick={() => {
              onEdit(backup);
              setIsAttach(backup.isAttach);
            }}
            data-bs-toggle="modal"
            data-bs-target="#addBackupModal"
            className="btn btn-secondary w-100 w-xl-auto me-xl-2">
            <i className="bi bi-pencil me-0"></i>
            <span className="d-xl-none ms-1">{t('general.buttons.modify')}</span>
          </button>
        </div>
        <div className="col-6 col-xl-1 text-xl-center mt-2 mb-xl-0 d-xl-flex justify-content-start px-xl-0">
          <button
            type="button"
            className="btn btn-outline-secondary w-100 w-xl-auto"
            disabled={isLoading || backup.processed === 1}
            data-bs-toggle="modal"
            data-bs-target={`#${DELETE_BACKUP + backup.flightAttendantId + backup.id}Modal`}>
            <i className="bi bi-trash"></i>{' '}
            <span className="d-xl-none ms-1">{t('general.buttons.delete')}</span>
          </button>
          <ConfirmationModal
            modalType={DELETE_BACKUP}
            employeeId={backup.flightAttendantId}
            submitAction={() => {
              deleteSbl({ id: backup.id, year: backup.year, employeeId: backup.flightAttendantId });
            }}
            includeHeader={true}
            values={{ index: backup.id }}>
            {t('general.modals.body.delete_backup', { index: index })}
          </ConfirmationModal>
        </div>
      </div>
    </div>
  );
};
