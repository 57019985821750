export interface Timetable {
  id?: number;
  roundId?: number;
  bidStatus?: number;
  needsExtension?: boolean;
  flightAttendantName: string;
  flightAttendantEmpNo: number;
  flightAttendantSeniority: number;
  flightAttendantPosition: number;
  vacation: number;
  statutory: number;
  splits: number;
  startTime: Date;
  endTime: Date;
}

export class Timetable {
  id?: number = 0;
  roundId?: number = 0;
  bidStatus?: number = 0;
  needsExtension?: boolean = false;
  flightAttendantName: string = '';
  flightAttendantEmpNo: number = 0;
  flightAttendantSeniority: number = 0;
  flightAttendantPosition: number = 0;
  vacation: number = 0;
  statutory: number = 0;
  splits: number = 0;
  startTime: Date = new Date();
  endTime: Date = new Date();
}
