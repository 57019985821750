import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { useRoundTimetable } from '../../../hooks/TimetableHooks';
import { Base } from '../../../model/Base';
import { FlightAttendant } from '../../../model/FlightAttendant';
import { Round } from '../../../model/Round';
import { Timetable } from '../../../model/Timetable';
import { BID_YEAR, QUESTIONS_EMAIL } from '../../components/utils/Constants';
import Utils from '../../components/utils/Utils';
import { useGeneralContext } from '../../components/utils/context/general-context-provider';

interface TimetableAlertProps {
  round: Round;
  currAtt: FlightAttendant;
}

export const TimetableAlert = (props: TimetableAlertProps) => {
  const { round, currAtt } = props;
  const { selectedLanguage } = useGeneralContext();
  const { data: timetable } = useRoundTimetable(currAtt?.employeeNo, round?.id);

  const [currentTime, setCurrentTime] = useState<Moment>(moment());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const endMoment = (timetable?.endTime !== undefined && moment(timetable?.endTime)) || undefined;
  const bidEnded = endMoment !== undefined && endMoment.isBefore(currentTime);

  return (
    <>
      <p className={`${timetable === undefined ? 'd-none' : ''}`}>
        <Trans
          i18nKey={!bidEnded ? 'home.fa.info_body' : 'home.fa.info_body_past'}
          values={{
            roundNum: round ? round.roundNo : '...',
            bidDate: timetable
              ? Utils.formatDate(
                  timetable.startTime?.toString(),
                  currAtt?.base.id,
                  selectedLanguage
                )
              : '...',
            bidTime: timetable
              ? Utils.formatTime(timetable.startTime?.toString(), currAtt?.base.id)
              : '...',
            allottedMinutes: round ? round.allottedTime : '...',
          }}
        />
      </p>
      <p className={`${timetable === undefined ? '' : 'd-none'}`}>
        <Trans
          i18nKey="home.fa.info_body_error"
          values={{
            roundNum: round ? round.roundNo : BID_YEAR,
            email: QUESTIONS_EMAIL,
          }}
        />
      </p>
    </>
  );
};
