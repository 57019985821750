import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BasesController } from '../../../../controller/BasesController';
import { useBasesList } from '../../../../hooks/BaseHooks';
import { useDaysAvailability } from '../../../../hooks/DaysAvailabilityHooks';
import { useCurrentFlightAttendant } from '../../../../hooks/FlightAttendantHooks';
import {
  useFrenchTrainingDatesByFaYearAndBase,
  useFrenchTrainingDatesByFaYearAndEmpNo,
} from '../../../../hooks/FrenchTrainingDatesHooks';
import { useActiveRoundForBase } from '../../../../hooks/RoundHooks';
import { DayAvailability } from '../../../../model/DayAvailability';
import { ErrorResponse } from '../../../../model/ErrorResponse';
import { BID_YEAR, CPM, FA, SUCCESS_CODE } from '../Constants';
import Utils from '../Utils';
import { ResultAlert } from '../alerts/result-alert';
import { useUserContext } from '../context/user-context-provider';
import styles from './days-availability-calendar.module.scss';

export function DaysAvailabilityCalendar() {
  const { t } = useTranslation();

  const { role } = useUserContext();
  const { data: currAtt } = useCurrentFlightAttendant();
  const { data: bases } = useBasesList();

  const [year, setYear] = useState<number>(BID_YEAR || moment().year());
  const [month, setMonth] = useState<number>(0);
  const [selectedBase, setSelectedBase] = useState<string>();
  const [resultMessage, setResultMessage] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const successRef = useRef<null | HTMLDivElement>(null);

  const startDate = moment([year, month]);
  const endDate = moment(startDate).endOf('month');
  const calendarStart = moment(startDate).add(-startDate.day(), 'days');
  const calendarEnd = moment(endDate).add(6 - endDate.day(), 'days');

  const { data: daysAvailabilityData } = useDaysAvailability(year, month + 1, selectedBase);
  const basesCtrl = new BasesController();
  const { data: activeRound, isFetched: roundFetched } = useActiveRoundForBase(
    currAtt?.base.initials
  );
  const { data: frenchTrainingDates, isFetched: frenchTrainingDatesFetched } =
    useFrenchTrainingDatesByFaYearAndEmpNo(
      activeRound ? activeRound.year : undefined,
      currAtt?.employeeNo
    );
  const ftd = frenchTrainingDates!.map((fr) =>
    Utils.momentFromDateTimeOffsetIgnoreTZ(fr.trainingDate).format('YYYY-MM-DD')
  );
  const daysAvailability = useMemo(() => {
    const newData: { [id: string]: DayAvailability } = {};
    (daysAvailabilityData || []).forEach(
      (day) =>
        (newData[Utils.momentFromDateTimeOffsetIgnoreTZ(day.vacDate).format('YYYY-MM-DD')] = day)
    );
    return newData;
  }, [daysAvailabilityData]);

  let dates = [];
  for (const day = moment(calendarStart); day.isBefore(calendarEnd); day.add(1, 'days')) {
    // See if we have availability data for this day
    const match = daysAvailability[day.format('YYYY-MM-DD')];

    dates.push({
      date: moment(day),
      availability: match !== undefined ? match.daysAvailable - match.daysAwarded : undefined,
      inMonth: day.isBetween(startDate, endDate, null, '[]'),
    });
  }

  const calculateDaysAvailabilityForBase = () => {
    basesCtrl
      .postGenerateDaysAvailabilityForBase(BID_YEAR, selectedBase || '')
      .then((response) => {
        if (response?.status === SUCCESS_CODE) {
          setResultMessage(t('availability.successful_da', { base: selectedBase }));
          setIsSuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
        let errorData = err.response?.data as ErrorResponse;
        setResultMessage(t(`general.modals.errors.${errorData.detail}`));
        setIsSuccess(false);
      });
  };

  useEffect(() => {
    if (currAtt !== undefined && role.match(FA)) {
      setSelectedBase(currAtt.base.initials);
    }
  }, [currAtt, role]);

  return (
    <div>
      <div className="row">
        <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
          <select
            className="form-select form-select-lg d-inline-block w-auto me-4"
            onChange={(e) => setMonth(parseInt(e.target.value))}
            value={month}>
            {moment.months().map((monthOpt, idx) => (
              <option key={`month-${idx}`} value={idx}>
                {monthOpt}
              </option>
            ))}
          </select>
          <span className="fs-4">{year}</span>
        </div>
        <div className="col-12 col-sm-6 d-grid align-items-center justify-content-center justify-content-sm-end mt-4 mt-sm-0 ">
          <div className="fs-4">
            <span className={`${role?.match(FA) ? '' : 'd-none'}`}>
              <Trans
                components={{ bold: <b /> }}
                values={{ base: selectedBase }}
                i18nKey={selectedBase ? 'availability.current_base' : 'availability.base_load_fail'}
              />
            </span>

            {/*select for base goes here*/}
            <select
              className={`form-select form-select-lg d-inline-block w-100 w-lg-auto me-4 ${
                role?.match(CPM) ? '' : 'd-none'
              }`}
              onChange={(e) => setSelectedBase(e.target.value)}
              defaultValue={0}>
              <option value={0} disabled>
                {t('availability.select_base')}
              </option>
              {bases &&
                bases.map((base) => (
                  <option key={`base-${base.id}`} value={base.initials}>
                    {base.initials}
                  </option>
                ))}
            </select>
            <button
              className={`${
                role?.match(CPM) ? '' : 'd-none'
              } btn btn-champagne fs-5 mt-2 mt-lg-0 w-100 w-lg-auto`}
              disabled={
                selectedBase === undefined ||
                (daysAvailabilityData ? daysAvailabilityData.length > 0 : false)
              }
              onClick={calculateDaysAvailabilityForBase}>
              {t('general.buttons.generate_availability', {
                base: selectedBase ? selectedBase : t('home.fa.base').toLowerCase(),
              })}
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-3">
          <div
            className={`alert alert-clouds ${ftd.length !== 0 && role === FA ? '' : 'd-none'}`}
            role="alert">
            <Trans
              i18nKey={'general.ftd_note'}
              values={{ year: activeRound?.year !== undefined ? activeRound.year : BID_YEAR }}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.calendarGrid} my-2`}>
        {/* Header for the calendar */}
        {moment.weekdaysShort().map((val, idx) => (
          <div className={`${styles.calendarDay}`} key={`dotw-${idx}`}>
            {val}
          </div>
        ))}
        {/* Actual calendar content */}
        {dates.map(
          (
            day //TO DO: check if day is on fdt
          ) => (
            <div
              className={`${styles.calendarDay} ${!day.inMonth ? styles.calendarDayDisabled : ''} ${
                day.inMonth && day.availability !== undefined && day.availability < 1
                  ? styles.calendarDayUnavailable
                  : ''
              } ${
                role === FA && ftd.includes(day.date.format('YYYY-MM-DD')) ? styles.calendarFDT : ''
              }`}
              key={day.date.format('YYYY-MM-DD')}>
              <div className={`${styles.calendarDateAnno} badge bg-secondary-light text-dark`}>
                {day.date.format('D')}
              </div>
              <span className={role === CPM ? '' : 'd-none'}>{day.availability}</span>
              <span className={role === FA ? '' : 'd-none'}>
                {ftd.includes(day.date.format('YYYY-MM-DD')) ? t('general.ftd') : day.availability}
              </span>
            </div>
          )
        )}
      </div>

      <ResultAlert
        resultMessage={resultMessage}
        setResultMessage={setResultMessage}
        resultRef={successRef}
        isSuccess={isSuccess}></ResultAlert>
    </div>
  );
}
