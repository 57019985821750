//Component imports
import {
  GetDownloadAllowed,
  GetPreviewFATimeSlot,
  GetRoundTimetable,
  GetTimetablesExcel,
  PostCalculateAllotedTime,
  PostRoundTimetable,
  PostTimetableDTO,
  PostTimetablePreview,
} from '../config/endpoints-config';
import { FlightAttendant } from '../model/FlightAttendant';
import { Round } from '../model/Round';
import { Timetable } from '../model/Timetable';
import GeneralService from './GeneralService';

export default class TimetableService extends GeneralService {
  async postTimetablePreview(round: Round) {
    let result = this.postWithBody(PostTimetablePreview, round);
    return result;
  }

  async postTimetables(timetables: Timetable) {
    let result = this.postWithBody(PostTimetableDTO, timetables);
    return result;
  }

  async postRoundTimetable(round: Round) {
    let result = this.postWithBody(PostRoundTimetable, round);
    return result;
  }

  async getRoundTimetable(employeeId: number, roundId: number) {
    let params = {
      employeeId: employeeId,
      roundId: roundId,
    };
    let result = this.getWithParams(GetRoundTimetable, params);
    return result;
  }

  async getPreviewFATimeSlot(employeeId: number, roundId: number) {
    let params = {
      employeeId: employeeId,
      roundId: roundId,
    };
    let result = this.getWithParams(GetPreviewFATimeSlot, params);
    return result;
  }

  async postCalculateAllotedTime(round: Round) {
    let result = this.postWithBody(PostCalculateAllotedTime, round);
    return result;
  }

  async getTimetablesExcel(employeeId: number, roundNo: number, year: number, language: number) {
    let params = {
      employeeId: employeeId,
      roundNo: roundNo,
      year: year,
      language: language,
    };
    let result = this.getFileWithParams(GetTimetablesExcel, params);
    return result;
  }

  async getDownloadAllowed(employeeId: number, roundNo: number, year: number) {
    let params = {
      employeeId: employeeId,
      roundNo: roundNo,
      year: year,
    };
    let result = this.getWithParams(GetDownloadAllowed, params);
    return result;
  }
}
