import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TimetableController } from '../../../../controller/TimetablesController';
import { useBasesAndRounds } from '../../../../hooks/BaseHooks';
import { BID_YEAR, ROUND_1, ROUND_2 } from '../../../components/utils/Constants';
import { ResultAlert } from '../../../components/utils/alerts/result-alert';
import { Content } from '../../../components/utils/content/content';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';
import { useUserContext } from '../../../components/utils/context/user-context-provider';
import { Loading } from '../../../components/utils/loading/loading';
import { DownloadFilesModal } from '../../../components/utils/modals/download-files-modal';
import { UploadFrenchTrainingModal } from '../../../components/utils/modals/upload-ftd-modal';
import { BaseAccordionItem } from './base-accordion-item';

interface BasesProps {}

export const Bases = (props: BasesProps) => {
  const { t } = useTranslation();
  const userCtxt = useUserContext();
  const generalCtx = useGeneralContext();

  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const successRef = useRef<null | HTMLDivElement>(null);
  const errorRef = useRef<null | HTMLDivElement>(null);

  const { employeeId } = userCtxt;
  const { selectedLanguage } = generalCtx;

  const { data: bases, isLoading, isSuccess, isError } = useBasesAndRounds(BID_YEAR);

  const timetableCtrl = new TimetableController();

  const [exportingFile, setExportingFile] = useState<boolean>(false);
  const [r1AllowExport, setR1AllowExport] = useState<boolean>(false);
  const [r2AllowExport, setR2AllowExport] = useState<boolean>(false);

  const getDownloadAllowed = async (roundNo: number) => {
    const response = await timetableCtrl.getDownloadAllowed(Number(employeeId), roundNo, BID_YEAR);
    if (response !== undefined) {
      if (roundNo === ROUND_1) {
        setR1AllowExport(response);
      } else {
        if (roundNo === ROUND_2) {
          setR2AllowExport(response);
        }
      }
    }
  };

  useEffect(() => {
    getDownloadAllowed(ROUND_1);
    getDownloadAllowed(ROUND_2);
  }, [bases]);

  return (
    <>
      <Content includeHeader={true} title={t('menu.cpm.bases')}>
        <div className="row mb-3">
          <div className="col-md-6 col-sm-12 align-self-center mb-2 mb-md-0">
            <span className="fs-5 fw-bold text-champagne">{t('bases.settings')}</span>
          </div>
          <div className="col-md-6 col-sm-12 d-flex gap-3 justify-content-md-end">
            <button
              className="btn btn-outline-primary btn-outline-champagne"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#uploadFrenchTrainingModal">
              <i className="bi bi-file-earmark-arrow-up"></i>
              {t('general.modals.titles.import_french_training_dates')}
            </button>
            <button
              className="btn btn-primary btn-champagne"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#downloadFilesModal">
              <i className="bi bi-file-earmark-arrow-down me-2"></i>
              {t('general.modals.titles.export_reports')}
            </button>
            <ResultAlert
              resultMessage={successMessage}
              setResultMessage={setSuccessMessage}
              resultRef={successRef}
              isSuccess={true}></ResultAlert>
            <ResultAlert
              resultMessage={errorMessage}
              setResultMessage={setErrorMessage}
              resultRef={errorRef}
              isSuccess={false}></ResultAlert>
          </div>
        </div>
        <div className="accordion" id="accordionFlushExample">
          {bases !== undefined && bases.length > 0 ? (
            bases.map((base) => (
              <BaseAccordionItem key={base.initials + base.id} base={base}></BaseAccordionItem>
            ))
          ) : (
            <Loading></Loading>
          )}
        </div>
        <DownloadFilesModal></DownloadFilesModal>
        <UploadFrenchTrainingModal
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}></UploadFrenchTrainingModal>
      </Content>
    </>
  );
};
