import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from '../config/axios-instance';
import { GetActiveRoundsForBase } from '../config/endpoints-config';
import { Round } from '../model/Round';

export const useActiveRoundForBase = (baseInitials?: string) =>
  useQuery<Round, AxiosError>(
    ['activeRoundForBase', baseInitials],
    () => axiosInstance.get(GetActiveRoundsForBase(baseInitials!)).then((resp) => resp.data),
    {
      enabled: !!baseInitials, // Query won't run until baseInitials has a value
      refetchInterval: 5000,
    }
  );
