import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import axiosInstance from '../config/axios-instance';
import {
  CreateSplit,
  DeleteSplit,
  GetSplitsForBase,
  GetSplitsForEmployee,
  ModifySplit,
} from '../config/endpoints-config';
import { Split } from '../model/Split';

export const useSplitsForEmployee = (employeeId?: number, year?: number) =>
  useQuery<Array<Split[]>, AxiosError>(
    ['getSplits', employeeId, year],
    () => axiosInstance.get(GetSplitsForEmployee(employeeId!, year!)).then((resp) => resp.data),
    {
      enabled: !!employeeId && !!year,
    }
  );

export const useSplitsForBase = (baseId: number, year?: number) =>
  useQuery<Split[], AxiosError>(
    ['getSplits', baseId, year],
    () => axiosInstance.get(GetSplitsForBase(baseId!, year!)).then((resp) => resp.data),
    {
      enabled: !!baseId && !!year,
    }
  );

type DeleteSplitData = {
  employeeId: number;
  year: number;
  id: number;
};

export const useDeleteSplit = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, DeleteSplitData>(
    (split) => axiosInstance.delete(DeleteSplit(split.id)),
    {
      onSuccess: (resp, split) => {
        queryClient.invalidateQueries(['getSplits', split.employeeId, split.year]);
      },
    }
  );
};

export const useCreateSplit = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, Split>(
    (split) => axiosInstance.post(CreateSplit, split),
    {
      onSuccess: (resp, split) => {
        queryClient.invalidateQueries(['getSplits', split.flightAttendantId, split.year]);
      },
    }
  );
};

export const useModifySplit = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, Split>(
    (split) => axiosInstance.put(ModifySplit(split.id), split),
    {
      onSuccess: (resp, split) => {
        queryClient.invalidateQueries(['getSplits', split.flightAttendantId, split.year]);
      },
    }
  );
};
