import { useTranslation } from 'react-i18next';

import { useUpdateFA, useUpdateFrench } from '../../../../../hooks/FlightAttendantHooks';
import { Base } from '../../../../../model/Base';
import { FlightAttendant } from '../../../../../model/FlightAttendant';
import { BID_YEAR, INACTIVE, MARK_FRENCH_MODAL } from '../../../../components/utils/Constants';
import { ConfirmationModal } from '../../../../components/utils/modals/confirmation-modal';

interface FrenchControlProps {
  fa: FlightAttendant;
  base: Base;
  setErrorMessage: (value: string) => void;
  setSuccessMessage: (value: string) => void;
}

export const FrenchControl = ({
  fa,
  base,
  setErrorMessage,
  setSuccessMessage,
}: FrenchControlProps) => {
  const { t } = useTranslation();

  const {
    mutate: updateFrench,
    isError: isFAUpdateError,
    error: faUpdateError,
    isLoading: faUpdateInProgress,
  } = useUpdateFrench(setErrorMessage, setSuccessMessage);

  return (
    <>
      <input
        className="form-check-input"
        type="checkbox"
        checked={fa.frenchLang === 1}
        disabled={fa.status === INACTIVE || faUpdateInProgress}
        data-bs-toggle="modal"
        data-bs-target={`#${MARK_FRENCH_MODAL + fa.employeeNo}Modal`}
        onChange={(e) => e.stopPropagation()}
        aria-label="Checkbox for french preference"
      />

      <ConfirmationModal
        modalType={MARK_FRENCH_MODAL}
        employeeId={fa.employeeNo}
        submitAction={() => {
          fa.base = base!;
          fa.frenchLang = Number(!fa.frenchLang);
          updateFrench({
            employeeId: fa.employeeNo,
            frenchLang: fa.frenchLang,
            baseId: base!.id,
          });
        }}
        includeHeader={true}>
        <div className="modal-body">{t('general.modals.body.mark_french')}</div>
      </ConfirmationModal>
    </>
  );
};
