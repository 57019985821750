import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

interface CalloutProps {
  jazzRed?: boolean;

  children?: React.ReactNode;
}

export const Callout = (props: CalloutProps) => {
  const { jazzRed, children } = props;

  return (
    <div className={`bd-callout ${jazzRed ? 'bd-callout-jazz-red' : 'bd-callout-champagne'}`}>
      {children}
    </div>
  );
};
