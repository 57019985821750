import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useDeleteSplit } from '../../../../hooks/SplitHooks';
import { Split } from '../../../../model/Split';
import { DELETE_SPLIT } from '../../../components/utils/Constants';
import Utils from '../../../components/utils/Utils';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';
import { ConfirmationModal } from '../../../components/utils/modals/confirmation-modal';

type StatRowProps = {
  index: number;
  split: Split;
  baseId: number;
  committed: boolean;
  onEdit: (split: Split) => void;
  onAttach: (split: Split) => void;
  bidEnded: boolean;
  isActiveRound: boolean;
  processingSBL: boolean;
};

export function StatRow({
  index,
  split,
  baseId,
  committed,
  onEdit,
  onAttach,
  bidEnded: bidExpired,
  isActiveRound,
  processingSBL,
}: StatRowProps) {
  const { mutate: deleteSplit, isLoading, status } = useDeleteSplit();
  const { t } = useTranslation();
  const { selectedLanguage } = useGeneralContext();

  // Determine if row includes vacation days or not, if it is pure R2 then we
  // simply treat it as a modifiable split, vs if it includes [immutable] vacation
  // days then we treat it as an attachable split
  const isPureR2 = split.attachedDaysEnd + split.attachedDaysStart === split.totalDays;

  const attachedDays = split.attachedDaysEnd + split.attachedDaysStart;
  const vacDays = split.totalDays - attachedDays;

  const data: {
    transkey: string;
    content: ReactNode;
    defaultSize?: number;
    mdSize?: number;
    xxlSize?: number;
  }[] = [
    {
      transkey: 'statutory.table.start_date',
      content: <>{Utils.formatDate(split.startDate.toString(), baseId, selectedLanguage)}</>,
    },
    {
      transkey: 'statutory.table.end_date',
      content: <>{Utils.formatDate(split.endDate.toString(), baseId, selectedLanguage)}</>,
    },
    {
      transkey: 'statutory.table.total_days',
      content: (
        <Trans
          i18nKey="statutory.table.total_days_content"
          values={{ r1Total: vacDays, r2Total: attachedDays, total: split.totalDays }}
        />
      ),
    },
    {
      transkey: 'general.modals.body.is_credit_carry',
      content: <>{t(split.isCreditCarryIn ? 'general.buttons.yes' : 'general.buttons.no')}</>,
    },
    {
      transkey: 'statutory.table.status',
      content: (
        <span className={`badge ${committed ? 'text-bg-success' : 'text-bg-champagne'} p-2`}>
          {t(committed ? 'general.status.committed' : 'general.status.modifying')}
        </span>
      ),
    },
  ];

  return (
    <div className="alert alert-secondary print-break-inside-avoid">
      <div className="row">
        {/*<div className="col-12 col-md-4 col-xxl-1 mb-2 mb-xxl-0">*/}
        {/*  <span className="fw-bold fs-5">*/}
        {/*    <Trans i18nKey="statutory.table.split" /> {index}.{subindex }*/}
        {/*  </span>*/}
        {/*</div>*/}
        {data.map((d, idx) => (
          <div
            key={idx}
            className={`col-${d.defaultSize || 6} col-md-${d.mdSize || 4} col-xxl-${
              d.xxlSize || 2
            } text-xxl-center mb-2 mb-xxl-0`}>
            <span className="fw-bold d-block">
              <Trans i18nKey={d.transkey} />
            </span>
            {d.content}
          </div>
        ))}
        <div className="col-12 col-xxl-1 text-xxl-center mb-xxl-0 justify-content-xxl-center align-items-xxl-center px-xxl-0 d-print-none d-flex">
          {isPureR2 ? (
            <>
              {isActiveRound && (
                <button
                  disabled={isLoading || !isActiveRound || processingSBL}
                  onClick={() => onEdit(split)}
                  data-bs-toggle="modal"
                  data-bs-target="#splitModal"
                  className="btn btn-secondary mx-1 flex-grow-1 flex-xxl-grow-0">
                  <i className="bi bi-pencil-fill me-0"></i>
                  <span className="d-xxl-none ms-1">{t('general.buttons.modify')}</span>
                </button>
              )}

              {!bidExpired && isActiveRound && (
                <>
                  <button
                    disabled={isLoading || processingSBL}
                    data-bs-toggle="modal"
                    data-bs-target={`#${DELETE_SPLIT + split.flightAttendantId + split.id}Modal`}
                    className="btn btn-outline-secondary mx-1 flex-grow-1 flex-xxl-grow-0">
                    <i className="bi bi-trash me-0"></i>
                    <span className="d-xxl-none ms-1">{t('general.buttons.delete')}</span>
                  </button>
                  <ConfirmationModal
                    modalType={DELETE_SPLIT}
                    employeeId={split.flightAttendantId}
                    submitAction={() =>
                      deleteSplit({
                        id: split.id,
                        year: split.year,
                        employeeId: split.flightAttendantId,
                      })
                    }
                    includeHeader={true}
                    values={{ index: split.id }}>
                    {t('general.modals.body.delete_split', {
                      startDate: Utils.formatDate(
                        split.startDate.toString(),
                        baseId,
                        selectedLanguage
                      ),
                      endDate: Utils.formatDate(split.endDate.toString(), baseId, selectedLanguage),
                    })}
                  </ConfirmationModal>
                </>
              )}
            </>
          ) : (
            isActiveRound && (
              <button
                disabled={isLoading}
                onClick={() => onAttach(split)}
                data-bs-toggle="modal"
                data-bs-target="#attachModal"
                className="btn btn-secondary mx-1 flex-grow-1 flex-xxl-grow-0">
                <i className="bi bi-calendar-plus me-0"></i>
                <span className="d-xxl-none ms-1">{t('general.buttons.attach')}</span>
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
}
