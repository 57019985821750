import { Trans } from 'react-i18next';

import { Split } from '../../../../model/Split';
import { BidResultRow } from './bid-result-row';

type BidResultGroupProps = {
  index: number;
  splits: Split[];
  baseId: number;
};

export function BidResultGroup({ splits, baseId, index }: BidResultGroupProps) {
  return (
    <div className="print-break-inside-avoid">
      <div className="row">
        <div className="col-12 col-md-4 col-xxl-1 mb-2 mb-xxl-0">
          <span className="fw-bold fs-5 text-secondary">
            <Trans i18nKey="vacation.table.split" /> {index}
          </span>
        </div>
      </div>
      {splits.map((split, _) => (
        <BidResultRow split={split} baseId={baseId} committed={true} />
      ))}
    </div>
  );
}
