import fr from 'date-fns/locale/fr';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useDaysAvailabilityByYear } from '../../../../hooks/DaysAvailabilityHooks';
import { useCurrentFlightAttendant } from '../../../../hooks/FlightAttendantHooks';
import { useFrenchTrainingDatesByFaYearAndEmpNo } from '../../../../hooks/FrenchTrainingDatesHooks';
import { useActiveRoundForBase } from '../../../../hooks/RoundHooks';
import { useSplitsForEmployee } from '../../../../hooks/SplitHooks';
import { useCreateSbl, useModifySbl } from '../../../../hooks/StandingBidLineHooks';
import { Entitlement } from '../../../../model/Entitlement';
import { FormError } from '../../../../model/FormError';
import { Split } from '../../../../model/Split';
import { SblDefaults, StandingBidLine } from '../../../../model/StandingBidLine';
import {
  AFTER,
  BEFORE,
  BID_YEAR,
  MIN_SPLIT_LENGTH,
  ROUND_1,
  SERVER_ERROR,
  SERVER_ERROR_CODE,
} from '../Constants';
import Utils from '../Utils';
// the locale you want
import { useGeneralContext } from '../context/general-context-provider';
import { Loading } from '../loading/loading';
import styles from './split-modal.module.scss';

interface AttachBackupModalProps {
  backup?: StandingBidLine | undefined;
  length: number;
  entitlement?: Entitlement;
  splitAttach?: Split;
  isAttach: boolean;
  attachCategory: string;
  onSuccess?: () => void;
}

export function AttachBackupModal(props: AttachBackupModalProps) {
  const { t } = useTranslation();
  const gralCtxt = useGeneralContext();
  registerLocale('fr', fr); // For datepicker
  const { backup, entitlement, onSuccess, length, isAttach, splitAttach, attachCategory } = props;
  const openToEndDate =
    splitAttach?.endDate !== undefined
      ? moment(splitAttach.endDate).add(1, 'd').format('YYYY/MM/DD')
      : undefined;
  const openToStartDate =
    splitAttach?.startDate !== undefined
      ? moment(splitAttach.startDate).subtract(1, 'd').format('YYYY/MM/DD')
      : undefined;

  const [errors, setErrors] = useState<FormError[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [minimumDays, setMinimumDays] = useState<number>(1);
  const [preferredDays, setPreferredDays] = useState<number>(1);
  const [totalDays, setTotalDays] = useState<number>(1);
  const [creditCarry, setCreditCarry] = useState<boolean>(false);

  const { selectedLanguage } = gralCtxt;
  const location = useLocation();
  const impersonateFaEmpNo =
    location.state !== null
      ? (location.state as { impersonateFaEmpNo: number }).impersonateFaEmpNo
      : undefined;

  const { data: currAtt, isFetched: attFetched } = useCurrentFlightAttendant(impersonateFaEmpNo!);
  const { data: activeRound, isFetched: roundFetched } = useActiveRoundForBase(
    currAtt?.base.initials
  );
  const { data: existingSplits, isFetched: existingSplitsFetched } = useSplitsForEmployee(
    currAtt?.employeeNo,
    activeRound ? activeRound.year : undefined
  );
  const { data: daysAvailability, isFetched: daysAvailabilityFetched } = useDaysAvailabilityByYear(
    activeRound?.year,
    currAtt?.base.initials
  );

  const { data: frenchTrainingDates, isFetched: frenchTrainingDatesFetched } =
    useFrenchTrainingDatesByFaYearAndEmpNo(
      activeRound ? activeRound.year : undefined,
      currAtt?.employeeNo
    );

  const {
    mutate: createSbl,
    error: createError,
    isSuccess: createSuccess,
    reset: createReset,
  } = useCreateSbl();
  const {
    mutate: modifySbl,
    error: modifyError,
    isSuccess: modifySuccess,
    reset: modifyReset,
  } = useModifySbl();

  const MAX_SPLIT_LENGTH =
    (activeRound?.roundNo === ROUND_1 ? entitlement?.vacation : entitlement?.statutory) || 0;

  // Combine ops
  const isSuccess = backup ? modifySuccess : createSuccess;
  const error = backup ? modifyError : createError;
  const reset = useCallback(() => {
    createReset();
    modifyReset();
  }, [createReset, modifyReset]);

  const saveChanges = () => {
    if (!currAtt || !activeRound) return;

    let dates = [];
    for (const day = moment(startDate); day.isBefore(endDate); day.add(1, 'days')) {
      dates.push(new Date(day.year(), day.month(), day.day()));
    }
    // Different save based on if this a modification or not
    if (backup) {
      modifySbl({
        ...backup!,
        startDate: moment(startDate).format('YYYY-MM-DD') as unknown as Date,
        endDate: moment(endDate).format('YYYY-MM-DD') as unknown as Date,
        minDays: minimumDays!,
        prefDays: preferredDays!,
        isCreditCarryIn: creditCarry!,
        isAttach: isAttach!,
      });
    } else {
      createSbl({
        ...SblDefaults,
        flightAttendantId: currAtt.employeeNo,
        year: activeRound.year,
        startDate: moment(startDate).format('YYYY-MM-DD') as unknown as Date,
        endDate: moment(endDate).format('YYYY-MM-DD') as unknown as Date,
        roundNo: activeRound.roundNo,
        bidNo: length + 1,
        minDays: minimumDays,
        prefDays: preferredDays,
        isCreditCarryIn: creditCarry,
        isAttach: isAttach!,
      });
    }
  };

  const resetModal = () => {
    if (attachCategory === BEFORE) {
      setStartDate(null);
    }
    if (attachCategory === AFTER) {
      setEndDate(null);
    }
    setMinimumDays(0);
    setPreferredDays(0);
    setCreditCarry(false);
    reset();
    onSuccess && onSuccess();
  };

  const excludedDaysFromSplits = useMemo(
    () => Utils.extractSplitDates(existingSplits!),
    [existingSplits]
  );

  const ftd = frenchTrainingDates!.map((fr) =>
    Utils.momentFromDateTimeOffsetIgnoreTZ(fr.trainingDate).toDate()
  );
  const excludedDaysFromAvailability = useMemo(
    () =>
      (daysAvailability || [])
        .filter((a) => a.daysAvailable <= a.daysAwarded)
        .filter(
          // We use !split here to just bypass this check if the split isn't provided [aka a new day]
          (a) =>
            !backup ||
            !Utils.momentFromDateTimeOffsetIgnoreTZ(a.vacDate).isBetween(
              backup?.startDate,
              backup?.endDate,
              undefined,
              '[]'
            )
        )
        .map((a) => Utils.momentFromDateTimeOffsetIgnoreTZ(a.vacDate).toDate())
        .concat(ftd),
    [daysAvailability, ftd, backup]
  );

  const loading = !(attFetched && roundFetched && daysAvailabilityFetched);

  const bidYear = activeRound?.year;
  let minDate = useMemo(() => (bidYear ? new Date(bidYear, 0, 1) : undefined), [bidYear]);
  let maxDate = useMemo(() => (bidYear ? new Date(bidYear, 11, 31) : undefined), [bidYear]);

  // Account for credit carry-in being disallowed in Dec 24 - 31 per collective agreement
  if (creditCarry) {
    maxDate = bidYear ? new Date(bidYear, 11, 23) : undefined;
  }

  // Account for vacation limits & existing splits
  if (startDate !== null) {
    for (const day of excludedDaysFromAvailability) {
      if (moment(day).isBetween(startDate, maxDate)) maxDate = day;
    }
  }
  if (endDate !== null) {
    for (const day of excludedDaysFromAvailability) {
      if (moment(day).isBetween(minDate, endDate)) minDate = day;
    }
  }

  useEffect(() => {
    setTotalDays(
      startDate !== null && endDate !== null ? moment(endDate).diff(startDate, 'days') + 1 : 0
    );
  }, [startDate, endDate]);

  useEffect(() => {
    reset();
    if (attachCategory === BEFORE) {
      setStartDate(null);
      setEndDate(
        moment(splitAttach?.startDate ? splitAttach?.startDate : null)
          .subtract(1, 'd')
          .toDate()
      );
    }

    if (attachCategory === AFTER) {
      setStartDate(
        moment(splitAttach?.endDate ? splitAttach?.endDate : null)
          .add(1, 'd')
          .toDate()
      );
      setEndDate(null);
    }

    setMinimumDays(backup?.minDays ? backup?.minDays : 0);
    setPreferredDays(backup?.prefDays ? backup?.prefDays : 0);
    setCreditCarry(backup?.isCreditCarryIn || false);
  }, [attachCategory, backup, reset, splitAttach]);

  const dayClassName = (day: Date) => {
    if (day.getFullYear() !== bidYear) return styles.outOfScopeDate;
    const dayMoment = moment(day);
    if (
      backup &&
      dayMoment.isBetween(startDate, endDate, undefined, '[]') &&
      dayMoment.isBetween(
        Utils.formatDateOnly(backup.startDate),
        Utils.formatDateOnly(backup.endDate),
        undefined,
        '[]'
      )
    )
      return styles.modifyingDate;
    if (excludedDaysFromAvailability.filter((a) => dayMoment.isSame(a, 'day')).length > 0)
      return styles.unavailableDate;
    return null;
  };

  // Determine if any selected dates are valid, and if so returns the first day that is invalid
  const hasInvalidDate: { result: boolean; date?: Date } = useMemo(() => {
    if (!startDate || !endDate) return { result: false };
    for (const day of excludedDaysFromAvailability) {
      if (moment(day).isBetween(startDate, endDate, undefined, '[]'))
        return { result: true, date: day };
    }
    for (const range of excludedDaysFromSplits || []) {
      if (moment(range.start).isBetween(startDate, endDate, undefined, '[]'))
        return { result: true, date: range.start };
      if (moment(range.end).isBetween(startDate, endDate, undefined, '[]'))
        return { result: true, date: range.end };
    }
    if (moment(startDate).isBefore(minDate)) return { result: true, date: startDate };
    if (moment(endDate).isAfter(maxDate)) return { result: true, date: endDate };

    return { result: false };
  }, [startDate, endDate, excludedDaysFromAvailability, excludedDaysFromSplits, minDate, maxDate]);

  // To DO: refactor other error validations to be included in this array.
  const immediateErrors: { key: string; isError: boolean; values?: {} }[] = [
    {
      key: 'general.modals.errors.split_invalid_availability',
      isError: !isSuccess && hasInvalidDate.result,
      values: { date: moment(hasInvalidDate.date).format('MMM Do, YYYY') },
    },
  ];
  const hasImmediateError = immediateErrors.filter((x) => x.isError).length > 0;

  return (
    <>
      <div id="attachBackupModal" className="modal fade" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-start">
                {attachCategory === BEFORE ? (
                  <Trans i18nKey="general.modals.titles.attach_backup_before" />
                ) : (
                  <Trans i18nKey="general.modals.titles.attach_backup_after" />
                )}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetModal}></button>
            </div>
            <div className="modal-body py-2">
              {(error && (
                <div className="alert alert-danger" role="alert">
                  <i className={`fa-solid fa-circle-xmark ${styles.alertIcon}`}></i>
                  {error.response?.status === SERVER_ERROR_CODE ||
                  error.response?.data === SERVER_ERROR ||
                  typeof error.response?.data !== 'string'
                    ? t('general.errors.server_error')
                    : t(`general.modals.errors.${error.response?.data}`)}
                </div>
              )) ||
                null}
              {immediateErrors
                .filter((x) => x.isError)
                .map((error) => (
                  <div className="alert alert-danger" role="alert" key={error.key}>
                    <i className={`fa-solid fa-circle-xmark ${styles.alertIcon}`}></i>
                    <Trans i18nKey={error.key} values={error.values} />
                  </div>
                ))}
              {(isSuccess && (
                <div className="alert alert-success" role="alert">
                  <i className={`fa-solid fa-circle-check ${styles.alertIcon}`}></i>
                  <Trans i18nKey="general.modals.success.commit_backup_success" />
                </div>
              )) ||
                null}

              {(isAttach && (
                <div className="bd-callout bd-callout-jazz-red">
                  <span className="text-dark d-block">
                    <Trans components={{ bold: <b /> }} i18nKey="standing.split_awarded" />
                  </span>
                  <span className="text-dark d-block">
                    {Utils.formatDate(
                      splitAttach!.startDate.toString(),
                      currAtt?.base.id,
                      selectedLanguage
                    )}
                    -
                    {Utils.formatDate(
                      splitAttach!.endDate.toString(),
                      currAtt?.base.id,
                      selectedLanguage
                    )}
                  </span>
                </div>
              )) ||
                null}

              {(loading && <Loading />) || (
                <>
                  <form className="row">
                    <div className="col-12 col-md-6 py-2">
                      <label className="form-label">{t('general.modals.body.start_date')}</label>
                      <DatePicker
                        className={`form-control ${
                          Utils.hasFieldError(errors, Utils.getVarName({ startDate }))
                            ? 'is-invalid'
                            : ''
                        } validate-form-date-input`}
                        placeholderText={t('general.date_format').toLowerCase()}
                        dateFormat={t('general.date_format')}
                        selectsStart
                        excludeDates={excludedDaysFromAvailability}
                        excludeDateIntervals={excludedDaysFromSplits}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        isClearable={!isSuccess && attachCategory !== AFTER}
                        locale={selectedLanguage}
                        dayClassName={dayClassName}
                        selected={startDate}
                        openToDate={
                          isAttach && openToStartDate !== undefined
                            ? new Date(moment(openToStartDate).format('YYYY/MM/DD'))
                            : undefined
                        }
                        onChange={(date: any) => {
                          setStartDate(date);
                          reset();
                        }}
                        disabled={isSuccess || attachCategory === AFTER}
                      />
                      <div
                        className={`invalid-feedback ${
                          Utils.hasFieldError(errors, Utils.getVarName({ startDate }))
                            ? 'd-block'
                            : 'd-none'
                        }`}>
                        {Utils.getFieldError(errors, Utils.getVarName({ startDate }))?.message}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 py-2">
                      <label className="form-label">{t('general.modals.body.end_date')}</label>
                      <DatePicker
                        className={`form-control ${
                          Utils.hasFieldError(errors, Utils.getVarName({ endDate }))
                            ? 'is-invalid'
                            : ''
                        } validate-form-date-input`}
                        placeholderText={t('general.date_format').toLowerCase()}
                        dateFormat={t('general.date_format')}
                        selectsEnd
                        excludeDates={excludedDaysFromAvailability}
                        excludeDateIntervals={excludedDaysFromSplits}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        isClearable={!isSuccess && attachCategory !== BEFORE}
                        locale={selectedLanguage}
                        dayClassName={dayClassName}
                        openToDate={
                          isAttach && openToEndDate !== undefined
                            ? new Date(new Date(moment(openToEndDate).format('YYYY/MM/DD')))
                            : undefined
                        }
                        selected={endDate}
                        onChange={(date: any) => {
                          setEndDate(date);
                          reset();
                        }}
                        disabled={isSuccess || attachCategory === BEFORE}
                      />
                      <div
                        className={`invalid-feedback ${
                          Utils.hasFieldError(errors, Utils.getVarName({ endDate }))
                            ? 'd-block'
                            : 'd-none'
                        }`}>
                        {Utils.getFieldError(errors, Utils.getVarName({ endDate }))?.message}
                      </div>
                    </div>
                    <p></p>
                    <div className="col-12 col-md-6 py-2">
                      <label className="form-label">{t('general.modals.body.pref_days')}</label>
                      <input
                        type="number"
                        className={`form-control ${
                          startDate !== null &&
                          endDate !== null &&
                          preferredDays < MIN_SPLIT_LENGTH &&
                          !isAttach
                            ? 'is-invalid'
                            : ''
                        } validate-form-date-input`}
                        id="preferred"
                        value={preferredDays}
                        onChange={(e) => {
                          setPreferredDays(Number(e.target.value));
                        }}
                        disabled={startDate === null || endDate === null || isSuccess}
                        min={isAttach ? 0 : MIN_SPLIT_LENGTH}
                        max={MAX_SPLIT_LENGTH}
                        required></input>
                      <div
                        className={`invalid-feedback ${
                          startDate !== null &&
                          endDate !== null &&
                          preferredDays < MIN_SPLIT_LENGTH &&
                          !isAttach
                            ? 'd-block'
                            : 'd-none'
                        }`}>
                        {t('general.modals.errors.minimum_days_greater_than', {
                          length: MIN_SPLIT_LENGTH,
                        })}
                      </div>
                      <div
                        className={`invalid-feedback ${
                          startDate !== null && endDate !== null && preferredDays > MAX_SPLIT_LENGTH
                            ? 'd-block'
                            : 'd-none'
                        }`}>
                        {t('general.modals.errors.sbl_pref_days_lower_than', {
                          length: MAX_SPLIT_LENGTH,
                        })}
                      </div>
                      <div
                        className={`invalid-feedback ${
                          totalDays < MAX_SPLIT_LENGTH
                            ? preferredDays > totalDays
                              ? 'd-block'
                              : 'd-none'
                            : 'd-none'
                        }`}>
                        {t('general.modals.errors.sbl_pref_days_lower_than_total', {
                          length: totalDays,
                        })}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 py-2">
                      <label className="form-label">{t('general.modals.body.min_days')}</label>
                      <input
                        type="number"
                        className={`form-control ${
                          startDate !== null &&
                          endDate !== null &&
                          minimumDays < MIN_SPLIT_LENGTH &&
                          !isAttach
                            ? 'is-invalid'
                            : ''
                        } validate-form-date-input`}
                        id="minimum"
                        value={minimumDays}
                        onChange={(e) => {
                          setMinimumDays(Number(e.target.value));
                        }}
                        disabled={startDate === null || endDate === null || isSuccess}
                        min={isAttach ? 0 : MIN_SPLIT_LENGTH}
                        max={MAX_SPLIT_LENGTH}
                        required></input>
                      <div
                        className={`invalid-feedback ${
                          startDate !== null &&
                          endDate !== null &&
                          minimumDays < MIN_SPLIT_LENGTH &&
                          !isAttach
                            ? 'd-block'
                            : 'd-none'
                        }`}>
                        {t('general.modals.errors.minimum_days_greater_than', {
                          length: MIN_SPLIT_LENGTH,
                        })}
                      </div>
                      <div
                        className={`invalid-feedback ${
                          startDate !== null && endDate !== null && minimumDays > preferredDays
                            ? 'd-block'
                            : 'd-none'
                        }`}>
                        {t('general.modals.errors.minimum_days_greater_than_pref_days', {
                          length: preferredDays,
                        })}
                      </div>
                    </div>
                    <div className="col-12 my-2 d-flex">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="creditCarryCheck"
                          checked={creditCarry}
                          disabled={isSuccess}
                          onChange={(e) => setCreditCarry(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="creditCarryCheck">
                          <Trans
                            components={{
                              uline: <u className="text-champagne" />,
                            }}
                            i18nKey="general.modals.body.is_credit_carry"
                          />
                        </label>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>
            <div className="modal-footer">
              <div className="row me-auto w-100">
                <div className="col-12 text-end p-0 d-flex">
                  {isSuccess ? (
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      className="btn btn-champagne w-100"
                      onClick={resetModal}>
                      {t(`general.buttons.close`)}
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="btn btn-outline-danger btn-outline-jazz-red me-2 w-50"
                        onClick={resetModal}>
                        {t(`general.buttons.cancel`)}
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger btn-jazz-red w-50"
                        onClick={saveChanges}
                        disabled={
                          loading ||
                          startDate === null ||
                          endDate === null ||
                          minimumDays === null ||
                          (minimumDays < MIN_SPLIT_LENGTH && !isAttach) ||
                          minimumDays > preferredDays ||
                          minimumDays === 0 ||
                          preferredDays === null ||
                          (preferredDays < MIN_SPLIT_LENGTH && !isAttach) ||
                          preferredDays > MAX_SPLIT_LENGTH ||
                          preferredDays > totalDays ||
                          preferredDays === 0 ||
                          hasImmediateError
                        }>
                        {t('general.buttons.commit')}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
