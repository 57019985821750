import { Trans, useTranslation } from 'react-i18next';

import { QUESTIONS_EMAIL } from '../../../components/utils/Constants';
import { Content } from '../../../components/utils/content/content';

interface FaqProps {}

const faqEntries: string[] = ['days_each_split'];

export const Faq = (props: FaqProps) => {
  const { t } = useTranslation();

  return (
    <Content
      includeHeader={true}
      title={t('menu.fa.faq')}
      subtitle={t('faq.subtitle', { email: QUESTIONS_EMAIL })}>
      {/* Manual FAQ entry here for the table, rest can be templated */}
      <div className="my-3">
        <h5>{t('faq.splits_num_header')}</h5>
        <p>{t('faq.splits_num_body')}</p>
        <div className="table-responsive d-inline-block">
          <table className="table table-striped table-hover align-middle border">
            <thead>
              <tr className="text-center">
                <th scope="col">
                  <Trans
                    i18nKey="faq.total_entitlement"
                    content={{
                      br: <br />,
                    }}
                  />
                </th>
                <th scope="col">{t('faq.splits')}</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              <tr>
                <td>0-7</td>
                <td>1</td>
              </tr>
              <tr>
                <td>8-14</td>
                <td>1-2</td>
              </tr>
              <tr>
                <td>15-21</td>
                <td>1-4</td>
              </tr>
              <tr>
                <td>22-28</td>
                <td>1-5</td>
              </tr>
              <tr>
                <td>29-35</td>
                <td>1-6</td>
              </tr>
              <tr>
                <td>36</td>
                <td>1-7</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {faqEntries.map((key) => (
        <div key={key} className="my-3">
          <h5>{t(`faq.${key}_header`)}</h5>
          <p>{t(`faq.${key}_body`)}</p>
        </div>
      ))}
    </Content>
  );
};
