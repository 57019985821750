import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import axiosInstance from '../config/axios-instance';
import {
  DeleteAwardedVacation,
  GetAwardedVacationsForEmployee,
  SubmitBid,
} from '../config/endpoints-config';
import { AwardedVacation } from '../model/AwardedVacation';

export const useAwardedVacationForEmployee = (employeeId?: number, year?: number) =>
  useQuery<AwardedVacation[], AxiosError>(
    ['getAwardedVacation', employeeId, year],
    () =>
      axiosInstance
        .get(GetAwardedVacationsForEmployee(employeeId!, year!))
        .then((resp) => resp.data),
    {
      enabled: !!employeeId && !!year,
    }
  );

type SubmitBidData = {
  employeeId: number;
  year: number;
  dates: Date[];
};

export const useSubmitBid = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, SubmitBidData>(
    (bid) => axiosInstance.post(SubmitBid(bid.employeeId, bid.year), bid.dates),
    {
      onSuccess: (resp, bid) => {
        queryClient.invalidateQueries(['getAwardedVacation', bid.employeeId, bid.year]);
      },
    }
  );
};

type DeleteBidData = {
  employeeId: number;
  year: number;
  id: number;
};

export const useDeleteBid = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, DeleteBidData>(
    (bid) => axiosInstance.delete(DeleteAwardedVacation(bid.id)),
    {
      onSuccess: (resp, bid) => {
        queryClient.invalidateQueries(['getAwardedVacation', bid.employeeId, bid.year]);
      },
    }
  );
};
