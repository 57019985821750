/*
  This Axios instance provides us the 'magic' behind automatically including MSAL into our REST API requests.
  Simply be sure to include the specific Axios instance instead of the normal Axios import in your consumers!
*/
import axios from 'axios';

import { msalInstance } from '..';
import { loginRequest } from './auth-config';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    const accounts = msalInstance.getAllAccounts();

    return msalInstance
      .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
      .then((resp) => {
        config.headers!.Authorization = `Bearer ${resp.accessToken}`;
        return Promise.resolve(config);
      });
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      // Clear local storage, redirect back to login
      window.location.href = '/login'; // TODO: Where should this actually direct to? Also consider if this should be /logout instead
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
