import { Round } from './Round';

export interface Base {
  id: number;
  name: string;
  initials: string;
  city: string;
  province: string;
  rounds: Round[];
}

export class Base {
  id: number = 0;
  name: string = '';
  initials: string = '';
  city: string = '';
  province: string = '';
  rounds: Round[] = [];
}
