import fr from 'date-fns/locale/fr';
import moment from 'moment-timezone';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useDaysAvailabilityByYear } from '../../../../hooks/DaysAvailabilityHooks';
import { useEntitlementsForEmployee } from '../../../../hooks/EntitlementHooks';
import { useCurrentFlightAttendant } from '../../../../hooks/FlightAttendantHooks';
import { useFrenchTrainingDatesByFaYearAndEmpNo } from '../../../../hooks/FrenchTrainingDatesHooks';
import { useActiveRoundForBase } from '../../../../hooks/RoundHooks';
import { useCreateSplit, useModifySplit, useSplitsForEmployee } from '../../../../hooks/SplitHooks';
import { FormError } from '../../../../model/FormError';
import { Split, SplitDefaults } from '../../../../model/Split';
import { MIN_SPLIT_LENGTH, SERVER_ERROR, SERVER_ERROR_CODE } from '../Constants';
import Utils from '../Utils';
import { useGeneralContext } from '../context/general-context-provider';
import { Loading } from '../loading/loading';
import styles from './split-modal.module.scss';

interface SplitModalProps {
  id: string;
  split?: Split | undefined;
  onSuccess?: () => void;
  isRound2?: boolean;
  bidEnded: boolean;
}

export function SplitModal({ split, onSuccess, id, isRound2, bidEnded }: SplitModalProps) {
  const { t } = useTranslation();
  const gralCtxt = useGeneralContext();
  registerLocale('fr', fr); // For datepicker

  const [errors, setErrors] = useState<FormError[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [creditCarry, setCreditCarry] = useState<boolean>(false);
  const location = useLocation();
  const impersonateFaEmpNo =
    location.state !== null
      ? (location.state as { impersonateFaEmpNo: number }).impersonateFaEmpNo
      : undefined;
  const { data: currAtt, isFetched: attFetched } = useCurrentFlightAttendant(impersonateFaEmpNo!);
  const { data: activeRound, isFetched: roundFetched } = useActiveRoundForBase(
    currAtt?.base.initials
  );
  const { data: existingSplits, isFetched: existingSplitsFetched } = useSplitsForEmployee(
    currAtt?.employeeNo,
    activeRound ? activeRound.year : undefined
  );

  const { data: entitlement, isFetched: entitlementFetched } = useEntitlementsForEmployee(
    currAtt?.employeeNo,
    activeRound ? activeRound.year : undefined
  );
  const { data: daysAvailability, isFetched: daysAvailabilityFetched } = useDaysAvailabilityByYear(
    activeRound ? activeRound.year : undefined,
    currAtt?.base.initials
  );
  const { data: frenchTrainingDates, isFetched: frenchTrainingDatesFetched } =
    useFrenchTrainingDatesByFaYearAndEmpNo(
      activeRound ? activeRound.year : undefined,
      currAtt?.employeeNo
    );
  const {
    mutate: createSplit,
    error: createError,
    isSuccess: createSuccess,
    isLoading: createLoading,
    reset: createReset,
  } = useCreateSplit();
  const {
    mutate: modifySplit,
    error: modifyError,
    isSuccess: modifySuccess,
    isLoading: modifyLoading,
    reset: modifyReset,
  } = useModifySplit();

  // Combine ops
  const isSuccess = split ? modifySuccess : createSuccess;
  const mutationInProgress = split ? modifyLoading : createLoading;
  const error = split ? modifyError : createError;

  const reset = useCallback(() => {
    createReset();
    modifyReset();
  }, [createReset, modifyReset]);

  // Calculate stats
  const immutableStart =
    split && bidEnded ? moment(Utils.formatDateOnly(split.startDate)) : undefined;
  const immutableEnd = split && bidEnded ? moment(Utils.formatDateOnly(split.endDate)) : undefined;
  let totalAllowed = 0; // TODO: distinguish between vacation vs stat/banked?
  (existingSplits || []).forEach((group) => {
    (group || []).forEach((split) => {
      totalAllowed += isRound2
        ? split.attachedDaysStart + split.attachedDaysEnd
        : split.totalDays - (split.attachedDaysStart + split.attachedDaysEnd);
    });
  });
  const remainingAllowed = entitlement
    ? (isRound2 ? entitlement.statutory : entitlement.vacation) -
      totalAllowed +
      (split
        ? isRound2
          ? split.attachedDaysStart + split.attachedDaysEnd
          : split.totalDays - (split.attachedDaysStart + split.attachedDaysEnd)
        : 0)
    : 0; // Ensure existing split is added to allowed total
  const totalDays =
    startDate !== null && endDate !== null ? moment(endDate).diff(startDate, 'days') + 1 : 0;

  const saveChanges = () => {
    if (!currAtt || !activeRound || mutationInProgress) return;

    // Different save based on if this a modification or not
    if (split) {
      modifySplit({
        ...split!,
        startDate: moment(startDate).format('YYYY-MM-DD') as unknown as Date,
        endDate: moment(endDate).format('YYYY-MM-DD') as unknown as Date,
        isCreditCarryIn: creditCarry,
      });
    } else {
      createSplit({
        ...SplitDefaults,
        flightAttendantId: currAtt.employeeNo,
        year: activeRound.year,
        startDate: moment(startDate).format('YYYY-MM-DD') as unknown as Date,
        endDate: moment(endDate).format('YYYY-MM-DD') as unknown as Date,
        isCreditCarryIn: creditCarry,
      });
    }
  };

  const resetModal = () => {
    setStartDate(null);
    setEndDate(null);
    setCreditCarry(false);
    reset();
    onSuccess && onSuccess();
  };

  const excludedDaysFromSplits = useMemo(
    () => Utils.extractSplitDatesSplitModal(existingSplits!, split!),
    [existingSplits, split]
  );

  const ftd = frenchTrainingDates!.map((fr) =>
    Utils.momentFromDateTimeOffsetIgnoreTZ(fr.trainingDate).toDate()
  );
  // NOTE: Ensure we don't exclude days from the existing split, if relevant, as that would
  // otherwise cause errors from availability!
  const excludedDaysFromAvailability = useMemo(
    () =>
      (daysAvailability || [])
        .filter((a) => a.daysAvailable <= a.daysAwarded)
        .filter(
          // We use !split here to just bypass this check if the split isn't provided [aka a new day]
          (a) =>
            !split ||
            !Utils.momentFromDateTimeOffsetIgnoreTZ(a.vacDate).isBetween(
              split?.startDate,
              split?.endDate,
              undefined,
              '[]'
            )
        )
        .map((a) => Utils.momentFromDateTimeOffsetIgnoreTZ(a.vacDate).toDate())
        .concat(ftd),
    [daysAvailability, ftd, split]
  );

  useEffect(() => {
    reset();
    setStartDate(Utils.formatDateOnly(split?.startDate));
    setEndDate(Utils.formatDateOnly(split?.endDate));
    setCreditCarry(split?.isCreditCarryIn || false);
  }, [split, reset]);

  const { selectedLanguage } = gralCtxt;

  const loading = !(
    attFetched &&
    roundFetched &&
    existingSplitsFetched &&
    entitlementFetched &&
    daysAvailabilityFetched
  );
  const bidYear = activeRound?.year;
  let minDate = useMemo(() => (bidYear ? new Date(bidYear, 0, 1) : undefined), [bidYear]);
  let maxDate = useMemo(() => (bidYear ? new Date(bidYear, 11, 31) : undefined), [bidYear]);

  // Account for credit carry-in being disallowed in Dec 24 - 31 per collective agreement
  if (creditCarry) {
    maxDate = bidYear ? new Date(bidYear, 11, 23) : undefined;
  }

  // Account for vacation limits & existing splits
  if (startDate !== null) {
    // First check for vacation limits
    const newMax = moment(startDate).add(remainingAllowed - 1 || 0, 'days');
    if (newMax.isBefore(maxDate)) maxDate = newMax.toDate();

    // Then check for any intersection with existing splits
    for (const split of excludedDaysFromSplits || []) {
      if (moment(split.start).isBetween(startDate, maxDate)) maxDate = split.start!;
    }

    // Finally check for day availability intersections
    for (const day of excludedDaysFromAvailability) {
      if (moment(day).isBetween(startDate, maxDate)) maxDate = day;
    }
  }
  if (endDate !== null) {
    // First check for vacation limits
    const newMin = moment(endDate).subtract(remainingAllowed - 1 || 0, 'days');
    if (newMin.isAfter(minDate)) minDate = newMin.toDate();

    // Then check for any intersection with existing splits
    for (const split of excludedDaysFromSplits || []) {
      if (moment(split.end).isBetween(minDate, endDate)) minDate = split.end!;
    }

    // Finally check for day availability intersections
    for (const day of excludedDaysFromAvailability) {
      if (moment(day).isBetween(minDate, endDate)) minDate = day;
    }
  }

  const dayClassName = (day: Date) => {
    if (day.getFullYear() !== bidYear) return styles.outOfScopeDate;
    const dayMoment = moment(day);
    if (
      split &&
      dayMoment.isBetween(startDate, endDate, undefined, '[]') &&
      dayMoment.isBetween(
        Utils.formatDateOnly(split.startDate),
        Utils.formatDateOnly(split.endDate),
        undefined,
        '[]'
      )
    )
      return styles.modifyingDate;
    for (const split of excludedDaysFromSplits || []) {
      if (dayMoment.isBetween(split.start, split.end, undefined, '[]')) return styles.awardedDate;
    }
    if (excludedDaysFromAvailability.filter((a) => dayMoment.isSame(a, 'day')).length > 0)
      return styles.unavailableDate;
    return null;
  };

  // Determine if any selected dates are valid, and if so returns the first day that is invalid
  const hasInvalidDate: { result: boolean; date?: Date } = useMemo(() => {
    if (!startDate || !endDate) return { result: false };
    for (const day of excludedDaysFromAvailability) {
      if (moment(day).isBetween(startDate, endDate, undefined, '[]'))
        return { result: true, date: day };
    }
    for (const range of excludedDaysFromSplits) {
      if (moment(range.start).isBetween(startDate, endDate, undefined, '[]'))
        return { result: true, date: range.start };
      if (moment(range.end).isBetween(startDate, endDate, undefined, '[]'))
        return { result: true, date: range.end };
    }
    if (moment(startDate).isBefore(minDate)) return { result: true, date: startDate };
    if (moment(endDate).isAfter(maxDate)) return { result: true, date: endDate };
    return { result: false };
  }, [startDate, endDate, excludedDaysFromAvailability, excludedDaysFromSplits, minDate, maxDate]);

  // Determine any errors to render based on clientside input
  const immediateErrors: { key: string; isError: boolean; values?: {} }[] = [
    {
      key: 'general.modals.errors.short_split',
      isError:
        startDate !== null &&
        endDate !== null &&
        !isRound2 &&
        !split?.IsAttach &&
        totalDays < MIN_SPLIT_LENGTH,
      values: { days: totalDays, count: totalDays, minDays: MIN_SPLIT_LENGTH },
    },
    {
      key: 'general.modals.errors.not_enough_allowance',
      isError: !isSuccess && totalDays > remainingAllowed,
      values: { days: totalDays, count: totalDays, remainingAllowed: remainingAllowed },
    },
    {
      key: 'general.modals.errors.split_invalid_availability',
      isError: !isSuccess && hasInvalidDate.result,
      values: { date: moment(hasInvalidDate.date).format('MMM Do, YYYY') },
    },
  ];
  const hasImmediateError = immediateErrors.filter((x) => x.isError).length > 0;

  useEffect(() => {
    if (bidEnded) {
      const btnClose = document.getElementById('close-splitModal');
      btnClose?.click();
    }
  }, [bidEnded]);

  return (
    <>
      <div id={id} className="modal fade" data-bs-backdrop="static" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-start">
                <Trans
                  i18nKey={
                    split ? 'general.modals.titles.modify_split' : 'general.modals.titles.new_split'
                  }
                />
              </h1>
              <button
                id="close-splitModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetModal}></button>
            </div>
            <div className="modal-body pb-0">
              {(error && (
                <div className="alert alert-danger" role="alert">
                  <i className={`fa-solid fa-circle-xmark ${styles.alertIcon}`}></i>
                  <Trans
                    i18nKey={`${
                      error.response?.status === SERVER_ERROR_CODE ||
                      error.response?.data === SERVER_ERROR
                        ? 'general.errors.server_error'
                        : 'general.modals.errors.commit_split_fail'
                    }`}
                    values={{
                      details:
                        typeof error.response?.data === 'string'
                          ? t(`general.modals.errors.${error.response?.data}`)
                          : error.message,
                    }}
                  />
                </div>
              )) ||
                null}
              {immediateErrors
                .filter((x) => x.isError)
                .map((error) => (
                  <div className="alert alert-danger" role="alert" key={error.key}>
                    <i className={`fa-solid fa-circle-xmark ${styles.alertIcon}`}></i>
                    <Trans i18nKey={error.key} values={error.values} />
                  </div>
                ))}
              {(isSuccess && (
                <div className="alert alert-success" role="alert">
                  <i className={`fa-solid fa-circle-check ${styles.alertIcon}`}></i>
                  <Trans i18nKey="general.modals.success.commit_split_success" />
                </div>
              )) ||
                null}
              {(loading && <Loading />) || (
                <>
                  <form className="row">
                    <div className="col-12 col-md-6">
                      <label className="form-label">{t('general.modals.body.start_date')}</label>
                      <DatePicker
                        className={`form-control ${
                          Utils.hasFieldError(errors, Utils.getVarName({ startDate }))
                            ? 'is-invalid'
                            : ''
                        } validate-form-date-input`}
                        placeholderText={t('general.date_format').toLowerCase()}
                        dateFormat={t('general.date_format')}
                        selectsStart
                        excludeDates={excludedDaysFromAvailability}
                        excludeDateIntervals={excludedDaysFromSplits}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={minDate}
                        maxDate={immutableStart?.toDate() || endDate || maxDate}
                        isClearable={!isSuccess}
                        locale={selectedLanguage}
                        dayClassName={dayClassName}
                        selected={startDate}
                        onChange={(date: any) => {
                          setStartDate(date);
                          reset();
                        }}
                        disabled={isSuccess}
                      />
                      <div
                        className={`invalid-feedback ${
                          Utils.hasFieldError(errors, Utils.getVarName({ startDate }))
                            ? 'd-block'
                            : 'd-none'
                        }`}>
                        {Utils.getFieldError(errors, Utils.getVarName({ startDate }))?.message}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mt-4 mt-md-0">
                      <label className="form-label">{t('general.modals.body.end_date')}</label>
                      <DatePicker
                        className={`form-control ${
                          Utils.hasFieldError(errors, Utils.getVarName({ endDate }))
                            ? 'is-invalid'
                            : ''
                        } validate-form-date-input`}
                        placeholderText={t('general.date_format').toLowerCase()}
                        dateFormat={t('general.date_format')}
                        selectsEnd
                        excludeDates={excludedDaysFromAvailability}
                        excludeDateIntervals={excludedDaysFromSplits}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={immutableEnd?.toDate() || startDate || minDate}
                        maxDate={maxDate}
                        isClearable={!isSuccess}
                        locale={selectedLanguage}
                        selected={endDate}
                        onChange={(date: any) => {
                          setEndDate(date);
                          reset();
                        }}
                        disabled={isSuccess}
                      />
                      <div
                        className={`invalid-feedback ${
                          Utils.hasFieldError(errors, Utils.getVarName({ endDate }))
                            ? 'd-block'
                            : 'd-none'
                        }`}>
                        {Utils.getFieldError(errors, Utils.getVarName({ endDate }))?.message}
                      </div>
                    </div>
                    <div className="col-12 mt-4 d-flex">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="creditCarryCheck"
                          checked={creditCarry}
                          disabled={isSuccess}
                          onChange={(e) => setCreditCarry(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="creditCarryCheck">
                          <Trans
                            components={{
                              uline: <u className="text-champagne" />,
                            }}
                            i18nKey="general.modals.body.is_credit_carry"
                          />
                        </label>
                      </div>
                    </div>
                  </form>
                  <div className="alert alert-secondary p-2 mt-4">
                    <div className="text-end">
                      <Trans
                        components={{ bold: <b /> }}
                        values={{
                          days: totalDays,
                        }}
                        i18nKey="general.modals.body.total_days"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <div className="row me-auto w-100">
                <div className="col-12 text-end p-0 d-flex">
                  {isSuccess ? (
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      className="btn btn-champagne w-100"
                      onClick={resetModal}>
                      {t(`general.buttons.close`)}
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        className="btn btn-outline-danger btn-outline-jazz-red me-2 w-50"
                        onClick={resetModal}>
                        {t(`general.buttons.cancel`)}
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger btn-jazz-red w-50"
                        onClick={saveChanges}
                        disabled={
                          loading ||
                          startDate === null ||
                          endDate === null ||
                          hasImmediateError ||
                          mutationInProgress
                        }>
                        {t('general.buttons.commit')}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
