import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { FAController } from '../../../../controller/FAController';
import { RoundsController } from '../../../../controller/RoundsController';
import { TimetableController } from '../../../../controller/TimetablesController';
import { Base } from '../../../../model/Base';
import { FlightAttendant } from '../../../../model/FlightAttendant';
import { Round } from '../../../../model/Round';
import { Timetable } from '../../../../model/Timetable';
import { EMP_NO_LENGTH } from '../Constants';
import Utils from '../Utils';
import { useGeneralContext } from '../context/general-context-provider';

interface AddFaTimetableModalProps {
  timetable: Timetable[] | undefined;
  tBase: Base;
  tRound: Round;
  setSuccessMessage: (value: string) => void;
  setAddedFA: (value: boolean) => void;
}

export const AddFaTimetableModal = (props: AddFaTimetableModalProps) => {
  const { t } = useTranslation();
  const { selectedLanguage } = useGeneralContext();
  const { timetable, tBase, tRound, setAddedFA, setSuccessMessage } = props;

  const faCtrl = new FAController();
  const timetableCtrl = new TimetableController();
  const roundsCtrl = new RoundsController();

  const [employeeId, setEmployeeId] = useState<string>('');
  const [searchResult, setSearchResult] = useState<FlightAttendant[]>();

  const [base, setBase] = useState<Base>(new Base());
  const [round, setRound] = useState<Round>(new Round());
  const [fas, setFas] = useState<FlightAttendant[]>();
  const [timetableResult, setTimetableResult] = useState<Timetable[]>([]);
  const [faResult, setFaResult] = useState<Timetable>();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);
  const [addDay, setAddDay] = useState<boolean>(false);

  const empIdOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setEmployeeId(e.target.value);
      setSearchResult([]);
      setTimetableResult([]);
      setErrorMessage('');
      setFaResult(new Timetable());
      setAddDay(false);
    }
  };

  const fetchFAs = async (roundId: number, baseId: number) => {
    faCtrl
      .getFAWithoutTimetableByBase(roundId, baseId)
      .then((response) => {
        if (response !== null) {
          let result = response?.data as FlightAttendant[];
          setFas(result);
        } else {
          throw new Error('Getting FAs response is null');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearch = (e: any) => {
    e.preventDefault && e.preventDefault();
    setSearching(true);
    if (employeeId.length === EMP_NO_LENGTH) {
      if (timetable !== undefined) {
        let timetableFiltered = timetable.filter((time) => {
          if (time.flightAttendantEmpNo.toString() === employeeId) {
            return time;
          }
        });

        if (timetableFiltered.length > 0) {
          setErrorMessage(
            t('general.modals.errors.already_added_timetable', {
              empNo: employeeId,
              baseInitials: base.initials,
            })
          );
        } else {
          if (fas !== undefined) {
            let result = fas.filter((fa) => {
              if (fa.employeeNo.toString() === employeeId) {
                return fa;
              }
            });
            if (result.length > 0) {
              setSearchResult(result);
              setErrorMessage('');

              let faResult = result?.at(0);
              if (faResult !== undefined) {
                faResult.base = base;
                timetableCtrl
                  .getPreviewFATimeSlot(faResult.employeeNo, round.id)
                  .then((response) => {
                    if (response !== undefined) {
                      let faTimetable = response?.data as Timetable;
                      setFaResult(faTimetable);
                      let ttPreview: Timetable[] = [];
                      ttPreview.push(faTimetable);
                      setTimetableResult(ttPreview);
                    } else {
                      throw new Error('faTimetable is undefined');
                    }
                  })
                  .catch((error) => {
                    console.error('Add FA Timetable - handleSearch', error);
                  });
              } else {
                throw new Error('faResult is undefined');
              }
            } else {
              setErrorMessage(
                t('general.modals.errors.no_fas_found', {
                  empNo: employeeId,
                  baseInitials: base.initials,
                })
              );
            }
            console.log('result', result);
          }
        }
      }
    } else {
      setErrorMessage(t('general.modals.errors.short_emp_no'));
      setSearching(false);
    }
    setSearching(false);
  };

  const onKeyEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  const handleAddDayCheck = () => {
    setAddDay(!addDay);
    console.log(faResult?.needsExtension);
    console.log(
      'faResult?.needsExtension ? addDay && (timetableResult.length === 0) : timetableResult.length === 0',
      faResult?.needsExtension
        ? addDay && timetableResult.length === 0
        : timetableResult.length === 0
    );
  };

  const saveChanges = () => {
    let timetable = timetableResult[0];
    if (faResult?.needsExtension && addDay) {
      //Call extend round first
      roundsCtrl
        .extendRound(round)
        .then(() => {
          postTimetables(timetable);
        })
        .catch(() => {
          throw new Error('extendRound response is undefined');
        });
    } else {
      postTimetables(timetable);
    }
  };

  const postTimetables = (timetable: Timetable) => {
    timetableCtrl
      .postTimetables(timetable)
      .then((response) => {
        if (response !== undefined) {
          console.log(response);
          let addedFa = response?.data as Timetable;
          setAddedFA(true);
          setSuccessMessage(
            t('general.modals.success.successfully_added_timetable', {
              empName: addedFa.flightAttendantName,
            })
          );
          discardChanges();
        } else {
          throw new Error('response is undefined');
        }
      })
      .catch((error) => {
        console.error('Add FA Timetable - saveChanges', error);
      });
  };

  const discardChanges = () => {
    setEmployeeId('');
    setSearchResult([]);
    setTimetableResult([]);
    setErrorMessage('');
    setFaResult(new Timetable());
    setAddDay(false);
  };

  useEffect(() => {
    setSearching(false);
  }, []);

  useEffect(() => {
    if (tBase !== null && tBase.id !== 0) {
      setBase(tBase);
    }
  }, [tBase]);

  useEffect(() => {
    if (tRound !== null && tRound.id !== 0) {
      setRound(tRound);
    }
  }, [tRound]);

  useEffect(() => {
    if (round.id !== 0 && base.id !== 0 && timetable !== undefined) {
      fetchFAs(round.id, base.id);
    }
  }, [base, round, timetable]);

  return (
    <>
      <div
        className="modal fade"
        id="addBidTimeModal"
        tabIndex={-1}
        aria-labelledby="addBidTimeModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-start" id="addBidTimeModalLabel">
                {t('general.buttons.add_fa_bidding_time')}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={discardChanges}></button>
            </div>
            <div className="modal-body pb-0">
              <form className="row">
                <div className="col-12 mb-3 text-start d-flex align-items-center">
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      className="form-control"
                      value={employeeId}
                      placeholder={t('general.modals.body.search_by_emp_no')}
                      aria-label={t('general.modals.body.search_by_emp_no')}
                      aria-describedby="button-addon2"
                      maxLength={EMP_NO_LENGTH}
                      onChange={(e) => {
                        empIdOnChange(e);
                      }}
                      onKeyUp={(e) => {
                        onKeyEnter(e);
                      }}
                    />
                    <button
                      className="btn btn-secondary"
                      type="submit"
                      id="button-addon2"
                      onClick={(e) => {
                        handleSearch(e);
                      }}
                      disabled={searching || employeeId.length === 0}>
                      <i className="bi bi-search"></i>
                    </button>
                  </div>
                </div>
              </form>
              <div className={`row ${errorMessage === '' ? '' : 'd-none'}`}>
                <div
                  className={`col-12 pt-3 ${
                    timetableResult !== undefined && timetableResult.length > 0 ? '' : 'd-none'
                  }`}>
                  <h6 className="fw-bold">
                    {t('general.modals.body.fa_details', {
                      timezone: Utils.getTimezoneAbbr(base.id),
                    })}
                  </h6>
                </div>
                <div className="col-12 mb-3 text-start d-flex align-items-center">
                  <div
                    className={`table-responsive w-100 ${
                      searchResult !== undefined && searchResult.length > 0 ? '' : 'd-none'
                    }`}>
                    <table className="table table-hover align-middle">
                      <thead>
                        <tr className="text-center">
                          <th scope="col">{t('bases.fa_list.table.employee_no')}</th>
                          <th scope="col" className="text-start">
                            {t('bases.fa_list.table.name')}
                          </th>
                          <th scope="col" className="text-center">
                            {t('bases.fa_list.table.seniority')}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table-group-divider">
                        {searchResult !== undefined && searchResult.length > 0 ? (
                          searchResult.map((result) => (
                            <tr key={result.employeeNo} className={`text-center`}>
                              <th scope="row">{result.employeeNo}</th>
                              <td className="text-capitalize text-start">{result.name}</td>
                              <td>{result.seniority}</td>
                            </tr>
                          ))
                        ) : (
                          <tr></tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className={`col-12 pt-3 ${
                    timetableResult !== undefined && timetableResult.length > 0 ? '' : 'd-none'
                  }`}>
                  <h6 className="fw-bold">
                    {t('general.modals.body.bid_date_time', {
                      timezone: Utils.getTimezoneAbbr(base.id),
                    })}
                  </h6>
                </div>
                <div className="col-12 mb-3 text-start d-flex align-items-center">
                  <div
                    className={`table-responsive w-100 ${
                      timetableResult !== undefined && timetableResult.length > 0 ? '' : 'd-none'
                    }`}>
                    <table className="table table-hover align-middle">
                      <thead>
                        <tr className="text-center">
                          <th scope="col" className="text-start">
                            {t('bases.timetable.table.start_time')}
                          </th>
                          <th scope="col" className="text-start">
                            {t('bases.timetable.table.end_time')}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table-group-divider">
                        {timetableResult !== undefined && timetableResult.length > 0 ? (
                          timetableResult.map((time) => (
                            <tr key={time.flightAttendantEmpNo} className={`text-center`}>
                              <td className="text-capitalize text-start">
                                {Utils.formatDateTime(
                                  time.startTime.toString(),
                                  base.id,
                                  selectedLanguage
                                )}
                              </td>
                              <td className="text-capitalize text-start">
                                {Utils.formatDateTime(
                                  time.endTime.toString(),
                                  base.id,
                                  selectedLanguage
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr></tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className={`row ${errorMessage === '' ? 'd-none' : ''}`}>
                <div className="col-12 text-start d-flex align-items-center">
                  <div className="alert alert-danger w-100" role="alert">
                    <i className="bi bi-exclamation-circle-fill me-2"></i>
                    {errorMessage}
                  </div>
                </div>
              </div>
              <div className={`row ${!faResult?.needsExtension ? 'd-none' : ''}`}>
                <div className="col-md-12 mb-3">
                  <div className="alert alert-warning alert-champagne mb-0 mt-1">
                    <label className="form-text">
                      <span className="d-inline-block mb-1">
                        <Trans i18nKey="general.modals.body.add_one_day" roundNo={round.roundNo}>
                          {{ roundNo: round.roundNo }}
                        </Trans>
                      </span>
                      <span className="d-inline-block">
                        <input
                          type="checkbox"
                          className={`form-check-input me-2`}
                          id="exampleCheck1"
                          onChange={handleAddDayCheck}
                          disabled={!faResult?.needsExtension || errorMessage !== ''}
                          checked={addDay}
                        />
                        <strong>{t('general.buttons.yes')}</strong>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row me-auto w-100">
                <div className="col-12 text-end p-0 d-flex">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-outline-jazz-red me-2 w-50"
                    data-bs-dismiss="modal"
                    onClick={discardChanges}>
                    {t(`general.buttons.cancel`)}
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-jazz-red w-50"
                    onClick={saveChanges}
                    data-bs-dismiss="modal"
                    disabled={
                      faResult?.needsExtension
                        ? !addDay && timetableResult.length !== 0
                        : timetableResult.length === 0
                    }>
                    {t(`general.buttons.save`)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
