import { LogLevel } from '@azure/msal-browser';

const clientId: string = process.env.REACT_APP_CLIENT_ID ?? '';
const tenantId: string = process.env.REACT_APP_TENANT_ID ?? '';
const redirectUri: string = process.env.REACT_APP_REDIRECT_URI ?? '';
const scopes: string = process.env.REACT_APP_API_SCOPES ?? '';
const apiEndpoint: string = process.env.REACT_APP_API_ENDPOINT ?? '';

// To do: Add to trouble shooting article https://payton.codes/2022/01/08/common-typescript-errors-and-how-to-fix-them/#:~:text=Type%20%27undefined%27%20is%20not%20assignable%20to%20type%20%27string%27.,which%20requires%20a%20non-falsy%20value%20%28here%2C%20a%20string%29.

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: 'https://login.microsoftonline.com/' + tenantId, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: redirectUri,
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    //By default, MSAL.js uses sessionStorage, which doesn't allow the session to be shared between tabs. To get SSO between tabs, make sure to set the cacheLocation in MSAL.js to localStorage
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: number, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.info(message);
            return;
        }
      },
    },
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [clientId + '/' + scopes], // Note from Brett: having this as the 'api://' format throws AADSTS90009 from Azure and tokens won't refresh after login
};

export const graphLoginRequest = {
  scopes: ['User.Read'],
};
// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  employeeInfoEndpoint: 'https://graph.microsoft.com/v1.0/me?$select=DisplayName,EmployeeId ',
};

export const protectedResources = {
  FAAVacationBidAPI: {
    endpoint: apiEndpoint,
    scopes: [scopes],
    client_ID: [clientId],
  },
};
