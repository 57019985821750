import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';

import { ReportsController } from '../../../../controller/ReportsController';
import { TimetableController } from '../../../../controller/TimetablesController';
import { usePostFrenchTrainingDatesExcel } from '../../../../hooks/FrenchTrainingDatesHooks';
import { FrenchTrainingExcel } from '../../../../model/FrenchTrainingExcel';
import { BID_YEAR, CPM, ROUND_1, ROUND_2 } from '../Constants';
import { useGeneralContext } from '../context/general-context-provider';
import { useUserContext } from '../context/user-context-provider';

interface UploadFrenchTrainingModalProps {
  setSuccessMessage: (value: string) => void;
  setErrorMessage: (value: string) => void;
}

export const UploadFrenchTrainingModal = ({
  setErrorMessage,
  setSuccessMessage,
}: UploadFrenchTrainingModalProps) => {
  const { t } = useTranslation();
  const { role, employeeId } = useUserContext();
  const { selectedLanguage } = useGeneralContext();

  const [selectedFile, setSelectedFile] = useState<File>();

  const [fileName, setFileName] = useState('');

  const {
    mutate: uploadFrenchTrainingDates,
    isError: isUploadFTDError,
    error: uploadFTDError,
    isLoading: uploadFTDInProgress,
  } = usePostFrenchTrainingDatesExcel(setErrorMessage, setSuccessMessage);

  const onFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };

  const onFileUpload = async () => {
    if (selectedFile !== undefined) {
      const fileReader = await new FileReader();
      fileReader.readAsArrayBuffer(selectedFile);

      fileReader.onload = (e: any) => {
        const bufferArray = e.target.result;
        const workbook = XLSX.read(bufferArray, { type: 'buffer', cellDates: true });
        const fileName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[fileName];

        const data: FrenchTrainingExcel[] = XLSX.utils.sheet_to_json(sheet, {
          defval: '',
          header: ['flightAttendantId', 'startTrainingDate', 'endTrainingDate'],
        });

        data.map((obj) => {
          var startDate = new Date(obj.startTrainingDate);
          var endDate = new Date(obj.endTrainingDate);
          var startString =
            startDate.getFullYear() + '/' + (startDate.getMonth() + 1) + '/' + startDate.getDate();
          var endString =
            endDate.getFullYear() + '/' + (endDate.getMonth() + 1) + '/' + endDate.getDate();
          obj.startTrainingDate = startString;
          obj.endTrainingDate = endString;
        });
        uploadFrenchTrainingDates(data);
      };
    }
  };

  const discardChanges = () => {
    setSelectedFile(undefined);
    setFileName('');
  };

  useEffect(() => {
    discardChanges();
  }, []);

  if (role === CPM) {
    return (
      <>
        <div
          className="modal fade"
          id="uploadFrenchTrainingModal"
          tabIndex={-1}
          aria-labelledby="uploadFrenchTrainingModalLabel"
          aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5 text-start" id="uploadFrenchTrainingModalLabel">
                  {t('general.modals.titles.import_french_training_dates')}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={discardChanges}></button>
              </div>
              <div className="modal-body pb-0">
                <div>
                  <form className="form-inline" id="assetUploadForm" encType="multipart/form-data">
                    <div className="form-group">
                      <input
                        type="file"
                        id="file"
                        accept=".xml,.xlsx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        onChange={onFileChange}
                        name="file"
                        className={` `}
                        required
                      />
                      <span className="help-block" id="file-help"></span>
                    </div>
                    <br></br>
                  </form>
                </div>
              </div>
              <div className={`modal-footer`}>
                <div className="row me-auto w-100">
                  <div className="col-12 text-end p-0 d-flex">
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-outline-jazz-red me-2 w-50"
                      data-bs-dismiss="modal">
                      {t(`general.buttons.cancel`)}
                    </button>
                    <button
                      id="uploadFile"
                      type="button"
                      className="btn btn-danger btn-jazz-red w-50"
                      onClick={onFileUpload}
                      data-bs-dismiss="modal">
                      {t(`general.buttons.upload`)}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};
