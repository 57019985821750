import { useState } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AwardedVacation } from '../../../../model/AwardedVacation';
import { Entitlement } from '../../../../model/Entitlement';
import { FlightAttendant } from '../../../../model/FlightAttendant';
import { Round } from '../../../../model/Round';
import { Split } from '../../../../model/Split';
import { AFTER, BEFORE, FA_PATHS, ROUND_2 } from '../../../components/utils/Constants';
import Utils from '../../../components/utils/Utils';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';
import { Loading } from '../../../components/utils/loading/loading';
import { SplitModal } from '../../../components/utils/modals/split-modal';

interface AwardedSplitsProps {
  activeRound: Round | undefined;
  splits: Array<Split[]> | undefined;
  currAtt: FlightAttendant | undefined;
  setIsAttach: (isAttach: boolean) => void;
  setSplitAttach: (split: Split) => void;
  setAttachCategory: (attachCategory: string) => void;
}

export const AwardedSplits = ({
  activeRound,
  splits,
  currAtt,
  setIsAttach,
  setSplitAttach,
  setAttachCategory,
}: AwardedSplitsProps) => {
  const { t } = useTranslation();
  const { selectedLanguage } = useGeneralContext();

  return (
    <>
      <div className={`${activeRound?.roundNo === ROUND_2 ? '' : 'd-none'} pb-4`}>
        <div className="accordion" id="round1Accordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne">
                <Trans components={{ bold: <b /> }} i18nKey="standing.splits_awarded" />
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#round1Accordion">
              <div className="accordion-body">
                <div className="col">
                  {splits === undefined ? (
                    t('general.loading')
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-hover align-middle">
                        <thead>
                          <tr className="text-center">
                            <th scope="col">
                              <Trans i18nKey="standing.table.start_date" />
                            </th>
                            <th scope="col">
                              <Trans i18nKey="standing.table.end_date" />
                            </th>
                            <th scope="col">
                              <Trans i18nKey="standing.table.total_days" />
                            </th>
                            <th scope="col">
                              <Trans i18nKey="standing.table.is_credit_carry" />
                            </th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody className="table-group-divider">
                          {currAtt &&
                            splits.map((group, index) => (
                              <React.Fragment key={'ag-' + index}>
                                {group.map((vacation, idx) => (
                                  <tr key={'aw-' + vacation.id} className="text-center">
                                    <td>
                                      {Utils.formatDate(
                                        vacation.startDate.toString(),
                                        currAtt?.base.id,
                                        selectedLanguage
                                      )}
                                    </td>
                                    <td>
                                      {Utils.formatDate(
                                        vacation.endDate.toString(),
                                        currAtt?.base.id,
                                        selectedLanguage
                                      )}
                                    </td>
                                    <td>{vacation.totalDays}</td>
                                    <td>
                                      {t(
                                        vacation.isCreditCarryIn
                                          ? 'general.buttons.yes'
                                          : 'general.buttons.no'
                                      )}
                                    </td>
                                    <td>
                                      <button
                                        className={`btn btn-outline-champagne me-2 ${
                                          !vacation.attachBefore ? 'd-none' : ''
                                        }`}
                                        data-bs-toggle="modal"
                                        data-bs-target="#attachBackupModal"
                                        onClick={() => {
                                          setIsAttach(true);
                                          setSplitAttach(vacation);
                                          setAttachCategory(BEFORE);
                                        }}>
                                        <i className="bi bi-plus-circle me-1"></i>
                                        <Trans i18nKey="standing.table.attach_backup_before" />
                                      </button>
                                      <button
                                        className={`btn btn-outline-champagne me-2 ${
                                          !vacation.attachAfter ? 'd-none' : ''
                                        }`}
                                        data-bs-toggle="modal"
                                        data-bs-target="#attachBackupModal"
                                        onClick={() => {
                                          setIsAttach(true);
                                          setSplitAttach(vacation);
                                          setAttachCategory(AFTER);
                                        }}>
                                        <i className="bi bi-plus-circle me-1"></i>
                                        <Trans i18nKey="standing.table.attach_backup_after" />
                                      </button>
                                      <span
                                        className={`${
                                          !vacation.attachBefore && !vacation.attachAfter
                                            ? ''
                                            : 'd-none'
                                        }`}>
                                        <Trans i18nKey="standing.table.no_availability_attach" />
                                      </span>
                                      <span
                                        className={`${
                                          vacation.attachBefore && !vacation.attachAfter
                                            ? ''
                                            : 'd-none'
                                        }`}>
                                        <Trans i18nKey="standing.table.no_availability_attach_after" />
                                      </span>
                                      <span
                                        className={`${
                                          !vacation.attachBefore && vacation.attachAfter
                                            ? ''
                                            : 'd-none'
                                        }`}>
                                        <Trans i18nKey="standing.table.no_availability_attach_before" />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
