import { AxiosError } from 'axios';
import fr from 'date-fns/locale/fr';
import moment, { Moment } from 'moment';
import { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Trans, useTranslation } from 'react-i18next';
// the locale you want
import { useLocation, useNavigate } from 'react-router-dom';

import { BasesController } from '../../../../controller/BasesController';
import { RoundsController } from '../../../../controller/RoundsController';
import { TimetableController } from '../../../../controller/TimetablesController';
import { useDaysAvailability } from '../../../../hooks/DaysAvailabilityHooks';
import { APIResponse } from '../../../../model/APIResponse';
import { Base } from '../../../../model/Base';
import { ErrorResponse } from '../../../../model/ErrorResponse';
import { FormError } from '../../../../model/FormError';
import { Round } from '../../../../model/Round';
import { Timetable } from '../../../../model/Timetable';
import { BID_YEAR, CPM_PATHS, SUCCESS_CODE } from '../../../components/utils/Constants';
import Utils from '../../../components/utils/Utils';
import { ResultAlert } from '../../../components/utils/alerts/result-alert';
import { Content } from '../../../components/utils/content/content';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';
import { useUserContext } from '../../../components/utils/context/user-context-provider';
import { TimetableTable } from './timetable-table';

interface ModifyRoundProps {}

export const ModifyRound = (props: ModifyRoundProps) => {
  const { t } = useTranslation();
  const userCtxt = useUserContext();
  const gralCtxt = useGeneralContext();
  let location = useLocation();
  registerLocale('fr', fr); // For datepicker

  const { employeeId, name } = userCtxt;
  const { selectedLanguage } = gralCtxt;
  const navigate = useNavigate();

  const roundsCtrl = new RoundsController();
  const timetableCtrl = new TimetableController();
  const basesCtrl = new BasesController();

  const [round, setRound] = useState<Round>(new Round());
  const [timetable, setTimetable] = useState<Timetable[]>();
  const [activeFaCount, setActiveFaCount] = useState(0);
  const [r2FaCount, setR2FaCount] = useState(0);
  const [base, setBase] = useState<Base>(new Base());
  const [year, setYear] = useState<number>(BID_YEAR || moment().year());
  const [month, setMonth] = useState<number>(0);
  const { data: daysAvailabilityData } = useDaysAvailability(year, month + 1, base.initials);

  const TODAY = new Date();
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [draftSaved, setDraftSaved] = useState<boolean>(true);
  const [saving, setSaving] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [timetableVisible, setTimetableVisible] = useState<boolean>(false);
  const [generatingTimetable, setGeneratingTimetable] = useState<boolean>(false);

  const [errors, setErrors] = useState<FormError[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const errorRef = useRef<null | HTMLDivElement>(null);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [bidStartDate, setBidStartDate] = useState<Date | null>(null);
  const [bidStartTime, setBidStartTime] = useState<Date | null>(null);
  const [bidEndDate, setBidEndDate] = useState<Date | null>(null);
  const [bidEndTime, setBidEndTime] = useState<Date | null>(null);
  const [allottedTime, setAllottedTime] = useState<number>(0);

  const goToBases = () => {
    navigate(-1);
  };

  const validateStartEndDates = (
    startDateFieldName: string,
    startDateValue: Date | null,
    endDateFieldName: string,
    endDateValue: Date | null
  ) => {
    let nextErrors: FormError[] = [...errors];
    let startDateError = Utils.getFieldError(errors, startDateFieldName);
    let endDateError = Utils.getFieldError(errors, endDateFieldName);
    if (startDateError !== undefined || endDateError !== undefined) {
      nextErrors = nextErrors.filter(
        (err) => err.field !== startDateFieldName && err.field !== endDateFieldName
      );
    }

    if (startDateValue !== null && endDateValue !== null) {
      if (startDateValue >= endDateValue) {
        nextErrors.push(new FormError(startDateFieldName, t('general.modals.errors.start_date')));
        nextErrors.push(new FormError(endDateFieldName, t('general.modals.errors.end_date')));
      }

      if (startDateFieldName.match('startDate') && endDateFieldName.match('endDate')) {
        if (
          bidStartDate !== null &&
          bidEndDate !== null &&
          startDateValue !== null &&
          endDateValue !== null
        ) {
          nextErrors = nextErrors.filter(
            (err) => err.field !== 'bidStartDate' && err.field !== 'bidEndDate'
          );
          let validStartDate = startDateValue <= bidStartDate && bidStartDate <= endDateValue;
          let validEndDate = startDateValue <= bidEndDate && bidEndDate <= endDateValue;

          if (!validStartDate) {
            nextErrors.push(new FormError('bidStartDate', t('general.modals.errors.bid_date')));
          }
          if (!validEndDate) {
            nextErrors.push(new FormError('bidEndDate', t('general.modals.errors.bid_date')));
          }
        }
      }
    } else {
      if (Utils.isFieldNull(startDateValue)) {
        nextErrors.push(new FormError(startDateFieldName, t('general.modals.errors.empty_field')));
      }
    }
    setErrors(nextErrors);
    setTimetableVisible(false);
  };

  const validateStartEndTimes = (
    startTimeFieldName: string,
    startTimeValue: Date | null,
    endTimeFieldName: string,
    endTimeValue: Date | null
  ) => {
    let nextErrors: FormError[] = [...errors];
    let startDateError = Utils.getFieldError(errors, startTimeFieldName);
    let endDateError = Utils.getFieldError(errors, endTimeFieldName);
    if (startDateError !== undefined || endDateError !== undefined) {
      nextErrors = nextErrors.filter(
        (err) => err.field !== startTimeFieldName && err.field !== endTimeFieldName
      );
    }

    if (startTimeValue !== null && endTimeValue !== null) {
      let start = Utils.getTimeInBaseZone(startTimeValue, base.id);
      let end = Utils.getTimeInBaseZone(endTimeValue, base.id);
      let isEndBeforeStart = Utils.isSameOrBefore(start, end);
      if (isEndBeforeStart) {
        nextErrors.push(new FormError(startTimeFieldName, t('general.modals.errors.start_time')));
        nextErrors.push(new FormError(endTimeFieldName, t('general.modals.errors.end_time')));
      }
    } else {
      if (Utils.isFieldNull(startTimeValue)) {
        nextErrors.push(new FormError(startTimeFieldName, t('general.modals.errors.empty_field')));
      }
      if (Utils.isFieldNull(endTimeValue)) {
        nextErrors.push(new FormError(endTimeFieldName, t('general.modals.errors.empty_field')));
      }
    }
    setErrors(nextErrors);
    setTimetableVisible(false);
  };

  const validateAllottedTime = (allottedTimeFieldName: string, allottedTimeValue?: number) => {
    let nextErrors: FormError[] = [...errors];
    nextErrors = errors.filter((err) => err.field !== allottedTimeFieldName);

    let faCount = round.roundNo === 1 ? activeFaCount : r2FaCount;
    if (faCount > 0) {
      if (
        startDate !== null &&
        endDate !== null &&
        bidStartDate !== null &&
        bidEndDate !== null &&
        bidStartTime !== null &&
        bidEndTime !== null
      ) {
        round.periodStartDate = startDate;
        round.periodEndDate = endDate;
        round.bidStartDate = bidStartDate;
        round.bidEndDate = bidEndDate;
        round.bidStartTime = bidStartTime;
        round.bidEndTime = bidEndTime;
        timetableCtrl
          .postCalculateAllotedTime(round)
          .then((response) => {
            let atResponse = response?.data as APIResponse;
            let calculatedAllottedTime = atResponse.data;
            if (allottedTimeValue !== undefined) {
              //Comes from onChange
              if (allottedTimeValue > 0) {
                if (calculatedAllottedTime > 0 && calculatedAllottedTime < allottedTimeValue) {
                  //
                  nextErrors.push(
                    new FormError(
                      allottedTimeFieldName,
                      t('general.modals.errors.greater_than_max', {
                        maxAllottedTime: calculatedAllottedTime,
                      })
                    )
                  );
                }
              } else {
                nextErrors.push(
                  new FormError(
                    allottedTimeFieldName,
                    t('general.modals.errors.allotted_time_zero')
                  )
                );
              }
            } else {
              // Comes from onClick
              if (calculatedAllottedTime > 0) {
                setAllottedTime(calculatedAllottedTime);
                setDraftSaved(false);
              } else {
                //error
                nextErrors.push(
                  new FormError(
                    allottedTimeFieldName,
                    t('general.modals.errors.allotted_time_zero')
                  )
                );
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        //Can't calculate yet
        nextErrors.push(
          new FormError(allottedTimeFieldName, t('general.modals.errors.allotted_time_null_dates'))
        );
      }
    } else {
      if (round.roundNo === 1) {
        nextErrors.push(
          new FormError(allottedTimeFieldName, t('general.modals.errors.active_fa_count_zero'))
        );
      }
      if (round.roundNo === 2) {
        nextErrors.push(
          new FormError(allottedTimeFieldName, t('general.modals.errors.r2_fa_count_zero'))
        );
      }
    }

    setErrors(nextErrors);
    setTimetableVisible(false);
  };

  const checkForErrors = () => {
    let nextErrors: FormError[] = [...errors];

    //Allotted Time
    nextErrors = errors.filter((err) => err.field !== Utils.getVarName({ allottedTime }));

    if (allottedTime === undefined || allottedTime <= 0 || allottedTime === null) {
      nextErrors.push(
        new FormError(
          Utils.getVarName({ allottedTime }),
          t('general.modals.errors.allotted_time_zero')
        )
      );
    }
    setErrors(nextErrors);
    setTimetableVisible(false);
  };

  const handleSubmitCheck = () => {
    setReadyToSubmit(!readyToSubmit);
  };

  const cleanErrors = () => {
    let errors: FormError[] = [];
    setErrors(errors);
  };

  const setIncomingValues = () => {
    setStartDate(Utils.formatDateForCalendar(round.periodStartDate, base.id));
    setEndDate(Utils.formatDateForCalendar(round.periodEndDate, base.id));
    setBidStartDate(Utils.formatDateForCalendar(round.bidStartDate, base.id));
    setBidEndDate(Utils.formatDateForCalendar(round.bidEndDate, base.id));
    setBidStartTime(Utils.formatDateForCalendar(round.bidStartTime, base.id));
    setBidEndTime(Utils.formatDateForCalendar(round.bidEndTime, base.id));
    setAllottedTime(round.allottedTime);
  };

  const discardChanges = () => {
    if (round.id !== 0 && base.id !== 0) {
      setDraftSaved(true);
      setErrorMessage('');
      cleanErrors();
      if (round.periodStartDate === null) {
        //First time filling form, so no errors so far.
        setStartDate(null);
        setEndDate(null);
        setBidStartDate(null);
        setBidEndDate(null);
        setBidStartTime(null);
        setBidEndTime(null);
        setAllottedTime(0);
      } else {
        //set incoming values
        setIncomingValues();
      }
    }
  };

  const hasErrors = () => {
    return errors.length !== 0;
  };

  const updateRound = async (round: Round) => {
    try {
      if (employeeId !== undefined && base !== undefined) {
        return roundsCtrl.updateRound(round);
      } else {
        let error =
          employeeId === undefined
            ? 'EmployeeId is undefined'
            : base === undefined
            ? 'Base is undefined'
            : 'Unknown message';
        throw new Error(error);
      }
    } catch (err) {
      //To do: handle message.
      let errorData = err.response?.data as ErrorResponse;
      throw new Error(errorData.detail);
    }
  };

  const calculateDaysAvailabilityForBase = () => {
    try {
      if (base !== undefined) {
        if (daysAvailabilityData!.length === 0) {
          return basesCtrl.postGenerateDaysAvailabilityForBase(BID_YEAR, base.initials);
        }
      } else {
        let error = base === undefined ? 'Base is undefined' : 'Unknown message';
        throw new Error(error);
      }
    } catch (err) {
      //To do: handle message.
      let errorData = err.response?.data as ErrorResponse;
      throw new Error(errorData.detail);
    }
  };

  const handleSaveDraftRound = (round: Round) => {
    setSaving(true);
    if (!hasErrors()) {
      if (
        startDate !== null &&
        endDate !== null &&
        bidStartDate !== null &&
        bidEndDate !== null &&
        bidStartTime !== null &&
        bidEndTime !== null &&
        allottedTime !== undefined &&
        allottedTime > 0
      ) {
        round.periodStartDate = startDate;
        round.periodEndDate = endDate;
        round.bidStartDate = bidStartDate;
        round.bidEndDate = bidEndDate;
        round.bidStartTime = bidStartTime;
        round.bidEndTime = bidEndTime;
        round.allottedTime = allottedTime;
        updateRound(round)
          .then(function (response) {
            // handle success
            round = response?.data as Round;
            setDraftSaved(true);
            goToBases();
          })
          .catch(function (error) {
            // handle message
            console.log(error);
            let errorData = error.response?.data as ErrorResponse;
            setErrorMessage(t(`general.errors.${errorData.detail}`));
            setDraftSaved(false);
          })
          .finally(() => {
            setSaving(false);
          });
      } else {
        console.log('Missing inputs');
        checkForErrors();
        setSaving(false);
      }
    } else {
      setSaving(false);
    }
  };

  const handlePreviewTimetable = async (round: Round) => {
    setTimetableVisible(true);
    setGeneratingTimetable(true);
    if (
      startDate !== null &&
      endDate !== null &&
      bidStartDate !== null &&
      bidEndDate !== null &&
      bidStartTime !== null &&
      bidEndTime !== null &&
      allottedTime !== undefined &&
      allottedTime > 0 &&
      !hasErrors()
    ) {
      round.periodStartDate = startDate;
      round.periodEndDate = endDate;
      round.bidStartDate = bidStartDate;
      round.bidEndDate = bidEndDate;
      round.bidStartTime = bidStartTime;
      round.bidEndTime = bidEndTime;
      round.allottedTime = allottedTime;
      timetableCtrl
        .postTimetablePreview(round)
        .then(function (response) {
          // handle success
          let timetable = response?.data as Timetable[];
          setTimetable(timetable);
        })
        .catch(function (error: AxiosError) {
          // handle message
          let errorData = error.response?.data as ErrorResponse;
          setErrorMessage(t(`general.errors.${errorData.detail}`));
          setTimetableVisible(false);
        })
        .finally(() => {
          setGeneratingTimetable(false);
        });
    } else {
      console.log('Missing inputs');
      checkForErrors();
      setGeneratingTimetable(false);
    }
  };

  const handleSubmitRound = (round: Round) => {
    setSubmitting(true);
    if (
      readyToSubmit &&
      startDate !== null &&
      endDate !== null &&
      bidStartDate !== null &&
      bidEndDate !== null &&
      bidStartTime !== null &&
      bidEndTime !== null &&
      allottedTime !== undefined &&
      allottedTime > 0 &&
      !hasErrors()
    ) {
      round.periodStartDate = startDate;
      round.periodEndDate = endDate;
      round.bidStartDate = bidStartDate;
      round.bidEndDate = bidEndDate;
      round.bidStartTime = bidStartTime;
      round.bidEndTime = bidEndTime;
      round.allottedTime = allottedTime;
      round.submitted = true;
      round.submittedByName = name ? name : 'unknown';
      round.submittedByEmployeeId = Number(employeeId);
      round.submittedDate = new Date();
      timetableCtrl
        .postRoundTimetable(round)
        .then((response) => {
          // handle success
          return updateRound(round);
        })
        .then((response) => {
          // handle success
          return calculateDaysAvailabilityForBase();
        })
        .then((response) => {
          setReadyToSubmit(false);
          goToBases();
        })
        .catch((error) => {
          // handle message
          console.log(error);
          let errorData = error.response?.data as ErrorResponse;
          setErrorMessage(t(`general.errors.${errorData.detail}`));
          round.submitted = false;
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      console.log('Missing inputs');
      checkForErrors();
      setSubmitting(false);
    }
  };

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  useEffect(() => {
    if (location.state !== null) {
      let state: any = location.state;
      if (state.round !== null && state.base !== null) {
        setRound(state.round);
        setBase(state.base);
        setActiveFaCount(state.activeFaCount);
        setR2FaCount(state.r2FaCount);
      } else {
        navigate(CPM_PATHS.bases);
      }
    } else {
      navigate(CPM_PATHS.bases);
    }
  }, [location]);

  useEffect(() => {
    if (round.id !== 0 && base.id !== 0) {
      if (round.periodStartDate !== null) {
        //set incoming values
        setIncomingValues();
      }
    }
  }, [round, base]);

  return (
    <>
      <Content
        includeHeader={true}
        title={t('menu.cpm.bases')}
        subtitle={base?.name ? base.initials + ' - ' + t(`bases.names.${base.name}`) : ''}>
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <span className="fs-5 fw-bold text-champagne">
              {t('bases.round_settings.title', { roundNo: round.roundNo })}
            </span>
          </div>
        </div>
        <form className="row g-1 needs-validation pt-3" noValidate>
          <div className="col-12 d-flex align-items-baseline">
            <h6 className="fw-bold d-inline-block">{t('general.modals.body.standing_bid')}</h6>
          </div>
          <div className="col-md-6">
            <label className="form-label">{t('general.modals.body.start_date')}</label>
            <DatePicker
              className={`form-control ${
                Utils.hasFieldError(errors, Utils.getVarName({ startDate })) ? 'is-invalid' : ''
              } validate-form-date-input`}
              placeholderText={t('general.date_format').toLowerCase()}
              dateFormat={t('general.date_format')}
              filterDate={isWeekday}
              isClearable={true}
              locale={selectedLanguage}
              selected={startDate}
              minDate={TODAY}
              maxDate={bidStartDate}
              onChange={(date: any) => {
                setStartDate(date);
                setDraftSaved(false);
                validateStartEndDates(Utils.getVarName({ startDate }), date, 'endDate', endDate);
              }}
            />
            <div
              className={`invalid-feedback ${
                Utils.hasFieldError(errors, Utils.getVarName({ startDate })) ? 'd-block' : 'd-none'
              }`}>
              {Utils.getFieldError(errors, Utils.getVarName({ startDate }))?.message}
            </div>
          </div>
          <div className="col-12 pt-3">
            <h6 className="fw-bold">{t('general.modals.body.bid_dates')}</h6>
          </div>
          <div className="col-md-6">
            <label className="form-label">{t('general.modals.body.start_date')}</label>
            <DatePicker
              className={`form-control ${
                Utils.hasFieldError(errors, Utils.getVarName({ bidStartDate })) ? 'is-invalid' : ''
              } validate-form-date-input`}
              selected={bidStartDate}
              onChange={(date) => {
                setBidStartDate(date);
                setDraftSaved(false);
                validateStartEndDates(
                  Utils.getVarName({ bidStartDate }),
                  date,
                  Utils.getVarName({ bidEndDate }),
                  bidEndDate
                );
              }}
              minDate={startDate ? startDate : TODAY}
              timeIntervals={15}
              dateFormat={t('general.date_format')}
              placeholderText={t('general.date_format').toLowerCase()}
              filterDate={isWeekday}
              isClearable={true}
              locale={selectedLanguage}
              disabled={startDate === null}
            />
            <div
              className={`invalid-feedback ${
                Utils.hasFieldError(errors, Utils.getVarName({ bidStartDate }))
                  ? 'd-block'
                  : 'd-none'
              }`}>
              {Utils.getFieldError(errors, Utils.getVarName({ bidStartDate }))?.message}
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="validationCustom04" className="form-label">
              {t('general.modals.body.end_date')}
            </label>
            <DatePicker
              className={`form-control ${
                Utils.hasFieldError(errors, Utils.getVarName({ bidEndDate })) ? 'is-invalid' : ''
              } validate-form-date-input`}
              selected={bidEndDate}
              onChange={(date) => {
                setEndDate(date);
                validateStartEndDates(Utils.getVarName({ startDate }), date, 'endDate', endDate);
                setBidEndDate(date);
                setDraftSaved(false);
                validateStartEndDates(
                  Utils.getVarName({ bidStartDate }),
                  bidStartDate,
                  Utils.getVarName({ bidEndDate }),
                  date
                );
              }}
              minDate={bidStartDate ? bidStartDate : startDate ? startDate : TODAY}
              timeIntervals={15}
              dateFormat={t('general.date_format')}
              filterDate={isWeekday}
              placeholderText={t('general.date_format').toLowerCase()}
              isClearable={true}
              locale={selectedLanguage}
              disabled={startDate === null || bidStartDate === null}
            />

            <div
              className={`invalid-feedback ${
                Utils.hasFieldError(errors, Utils.getVarName({ bidEndDate })) ? 'd-block' : 'd-none'
              }`}>
              {Utils.getFieldError(errors, Utils.getVarName({ bidEndDate }))?.message}
            </div>
          </div>
          <div className="col-12 pt-3">
            <h6 className="fw-bold">
              {t('general.modals.body.bid_hours', {
                timezone: moment.tz(Utils.getBaseTZ(base.id)).zoneAbbr(),
              })}
            </h6>
          </div>
          <div className="col-md-4">
            <label className="form-label">{t('general.modals.body.start_time')}</label>
            <DatePicker //Utils.getVarName({ bidStartTime })
              className={`form-control ${
                Utils.hasFieldError(errors, Utils.getVarName({ bidStartTime })) ? 'is-invalid' : ''
              } validate-form-date-input`}
              selected={bidStartTime ? Utils.setLocalZone(bidStartTime, base.id) : null}
              onChange={(date) => {
                setBidStartTime(Utils.setBaseZone(date, base.id));
                setDraftSaved(false);
                validateStartEndTimes(
                  Utils.getVarName({ bidStartTime }),
                  Utils.setBaseZone(date, base.id),
                  Utils.getVarName({ bidEndTime }),
                  bidEndTime
                );
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat={t('general.time_format')}
              isClearable={true}
              locale={selectedLanguage}
              disabled={bidStartDate === null || bidEndDate === null}
            />

            <div
              className={`invalid-feedback ${
                Utils.hasFieldError(errors, Utils.getVarName({ bidStartTime }))
                  ? 'd-block'
                  : 'd-none'
              }`}>
              {Utils.getFieldError(errors, Utils.getVarName({ bidStartTime }))?.message}
            </div>
          </div>

          <div className="col-md-4">
            <label className="form-label">{t('general.modals.body.end_time')}</label>
            <DatePicker
              className={`form-control ${
                Utils.hasFieldError(errors, 'bidEndTime') ? 'is-invalid' : ''
              } validate-form-date-input`}
              selected={bidEndTime ? Utils.setLocalZone(bidEndTime, base.id) : null}
              onChange={(date) => {
                setBidEndTime(Utils.setBaseZone(date, base.id));
                setDraftSaved(false);
                validateStartEndTimes(
                  Utils.getVarName({ bidStartTime }),
                  bidStartTime,
                  Utils.getVarName({ bidEndTime }),
                  Utils.setBaseZone(date, base.id)
                );
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat={t('general.time_format')}
              isClearable={true}
              locale={selectedLanguage}
              disabled={bidStartDate === null || bidEndDate === null}
            />

            <div
              className={`invalid-feedback ${
                Utils.hasFieldError(errors, Utils.getVarName({ bidEndTime })) ? 'd-block' : 'd-none'
              }`}>
              {Utils.getFieldError(errors, Utils.getVarName({ bidEndTime }))?.message}
            </div>
          </div>

          <div className="col-md-4">
            <label className="form-label d-inline-block">
              {t('general.modals.body.allotted_time')}
            </label>
            <div className="input-group">
              <input
                type="number"
                className={`form-control ${
                  Utils.hasFieldError(errors, Utils.getVarName({ allottedTime }))
                    ? 'is-invalid'
                    : ''
                } validate-form-date-input`}
                id="validationCustom04"
                value={allottedTime}
                onChange={(e) => {
                  setErrorMessage('');
                  setAllottedTime(Number(e.target.value));
                  validateAllottedTime(Utils.getVarName({ allottedTime }), Number(e.target.value));
                  setDraftSaved(false);
                }}
                disabled={bidStartDate === null || bidEndDate === null}
                min="0"
                required></input>
              <button
                type="button"
                className="btn btn-secondary text-center rounded-end"
                onClick={() => {
                  setErrorMessage('');
                  validateAllottedTime(Utils.getVarName({ allottedTime }));
                }}
                disabled={bidStartDate === null || bidEndDate === null}>
                <i className="bi bi-calculator-fill"></i>
              </button>
              <div
                className={`invalid-feedback ${
                  Utils.hasFieldError(errors, Utils.getVarName({ allottedTime }))
                    ? 'd-block'
                    : 'd-none'
                }`}>
                {Utils.getFieldError(errors, Utils.getVarName({ allottedTime }))?.message}
              </div>
            </div>
          </div>
          <div className="col-md-12 pt-3">
            <div className=" mb-0 mt-1">
              <label className="form-text">
                <input
                  type="checkbox"
                  className={`form-check-input me-2 ${!readyToSubmit ? 'border-secondary' : ''}`}
                  id="exampleCheck1"
                  onChange={handleSubmitCheck}
                  disabled={round.submitted || errors.length !== 0}
                  checked={round.submitted || readyToSubmit}
                />
                <Trans i18nKey="general.modals.body.ready_to_submit_text" roundNo={round.roundNo}>
                  {{ roundNo: round.roundNo }}
                </Trans>
              </label>
            </div>
          </div>
          <div className={`col-md-12 pt-3 ${round.submitted || readyToSubmit ? '' : 'd-none'}`}>
            <div className=" mb-0 mt-1">
              <div className="alert alert-secondary" role="alert">
                <i className="bi bi-info-circle-fill me-2"></i>
                <Trans
                  components={{ bold: <b /> }}
                  values={{
                    roundNo: round.roundNo,
                  }}
                  i18nKey="general.modals.body.ready_to_submit_alert"
                />
              </div>
            </div>
          </div>
        </form>
        <div className="row my-3 py-2">
          <div className="col-md-12 my-2">
            <button
              type="button"
              className="btn btn-secondary mt-lg-0 mt-md-2 mt-sm-2"
              onClick={() => {
                handlePreviewTimetable(round);
              }}
              disabled={errors.length !== 0 || generatingTimetable}>
              <i className="bi bi-eye-fill me-1"></i>
              {generatingTimetable
                ? t('bases.round_settings.generating_timetable')
                : t('bases.round_settings.preview_timetable')}
            </button>
          </div>
        </div>
        <div className="row my-3 bg-secondary bg-opacity-10 py-2">
          <div className="col-md-6 my-2 text-start">
            <button
              type="button"
              className="btn btn-outline-secondary btn-outline-champagne me-2"
              onClick={() => {
                discardChanges();
              }}
              disabled={draftSaved}>
              {t(`general.buttons.discard`)}
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-champagne me-2"
              onClick={() => {
                handleSaveDraftRound(round);
              }}
              disabled={draftSaved || readyToSubmit || saving || errors.length !== 0}>
              {saving ? t(`general.buttons.saving`) : t(`general.buttons.save_draft`)}
            </button>
            <span className={`text-muted ms-1 ${draftSaved ? 'd-none' : 'd-inline-block'}`}>
              {t('general.modals.body.unsaved_changes')}
            </span>
          </div>
          <div className="col-md-6 my-2 text-end">
            <button
              type="button"
              className="btn btn-outline-secondary btn-outline-danger me-2"
              onClick={() => {
                goToBases();
              }}>
              {t(`general.buttons.cancel`)}
            </button>
            <button
              type="button"
              className="btn btn-danger btn-jazz-red"
              disabled={!readyToSubmit || submitting || errors.length !== 0}
              onClick={() => {
                handleSubmitRound(round);
              }}>
              {submitting ? t(`general.buttons.submitting`) : t(`general.buttons.submit`)}
            </button>
          </div>
        </div>
        <div className="row my-2"></div>
        <div className={`row mb-3 ${timetableVisible ? '' : 'd-none'}`}>
          <div className="col-12">
            <span className="fs-5 fw-bold text-secondary">
              {t('bases.round_settings.timetable_preview')}
            </span>
            <span className="fs-6 fw-light d-block lead">
              {t('bases.round_settings.timetable_alert')}
            </span>
          </div>
        </div>
        <div className={`row ${timetableVisible ? '' : 'd-none'}`}>
          <div className="col-12">
            <div className="alert alert-secondary alert-champagne mb-3" role="alert">
              <p className="text-champagne">
                <i className="bi bi-info-circle-fill me-2"></i>
                <Trans
                  components={{ bold: <b className="fw-bold" /> }}
                  values={{
                    roundNo: round.roundNo,
                    date: Utils.formatDateTime(
                      timetable !== undefined
                        ? timetable![timetable!.length - 1].endTime.toString()
                        : new Date().toISOString(),
                      base.id,
                      selectedLanguage
                    ),
                  }}
                  i18nKey="bases.round_settings.round_closing_date"
                />
              </p>
              <hr />
              <p className="mb-0 small text-champagne fw-medium">
                <Trans
                  components={{ bold: <b /> }}
                  i18nKey="bases.round_settings.closing_date_disclaimer"
                />
              </p>
            </div>
          </div>
        </div>
        <TimetableTable
          isPreview={true}
          timetableVisible={timetableVisible}
          generatingTimetable={generatingTimetable}
          timetable={timetable}
          base={base}></TimetableTable>
        <ResultAlert
          resultMessage={errorMessage}
          setResultMessage={setErrorMessage}
          resultRef={errorRef}
          isSuccess={false}></ResultAlert>
      </Content>
    </>
  );
};
