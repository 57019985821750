import { FrenchTrainingExcel } from '../model/FrenchTrainingExcel';

const baseURL = process.env.REACT_APP_API_URL;

//Bases
export const GetBase = (baseId: number) => `${baseURL}/Bases/${baseId}`;
export const Bases = `${baseURL}/Bases/`;
export const GetBasesAndRoundsByYear = `${baseURL}/Bases/GetBasesAndRoundsByYear`;

//FTD
export const PostFrenchTrainingDatesExcel = `${baseURL}/FrenchTrainingDates/PostFrenchTrainingDatesExcel/`;
export const PostFrenchTrainingDates = `${baseURL}/FrenchTrainingDates/PostFrenchTrainingDates/`;
export const DeleteFrenchTrainingDates = (id: number) => `${baseURL}/FrenchTrainingDates/${id}`;
export const GetFrenchTrainingDates = (employeeNo: number, year: number) =>
  `${baseURL}/FrenchTrainingDates/GetFrenchTrainingDates/${employeeNo}/${year}`;
export const GetFrenchTrainingDatesByYear = (year: number, baseId: number) =>
  `${baseURL}/FrenchTrainingDates/GetFrenchTrainingDatesByYearAndBase/${year}/${baseId}`;

//Flight Attendants
export const FlightAttendants = `${baseURL}/FlightAttendants/`;
export const GetFlightAttendantsByBase = `${baseURL}/FlightAttendants/GetFlightAttendantsByBase`;
export const GetFlightAttendantsCountByBase = `${baseURL}/FlightAttendants/GetFlightAttendantsCountByBase`;
export const GetActiveFlightAttendantsByBase = `${baseURL}/FlightAttendants/GetActiveFlightAttendantsByBase`;
export const GetActiveFlightAttendantsCountByBase = `${baseURL}/FlightAttendants/GetActiveFlightAttendantsCountByBase`;
export const GetR2FlightAttendantsByBase = `${baseURL}/FlightAttendants/GetR2FlightAttendantsByBase`;
export const GetR2FlightAttendantsCountByBase = `${baseURL}/FlightAttendants/GetR2FlightAttendantsCountByBase`;
export const GetFAWithoutTimetableByBase = `${baseURL}/FlightAttendants/GetFAWithoutTimetableByBase`;
export const FlightAttendantChangeBase = (employeeNo: number, baseId: number) =>
  `${baseURL}/FlightAttendants/FlightAttendantChangeBase/${employeeNo}/${baseId}`;

export const Entitlements = `${baseURL}/Entitlements/`;
export const LanguagePreferences = `${baseURL}/LanguagePreferences/`;
export const GetLastUpdatedFAList = `${baseURL}/AuditLogs/GetLastUpdatedFAList/`;
export const GetLanguagePreferenceByEmployeeId = `${baseURL}/LanguagePreferences/GetLanguagePreferenceByEmployeeId`;
export const Rounds = `${baseURL}/Rounds/`;
export const PutExtendRound = `${baseURL}/Rounds/PutExtendRound/`;
export const PostTimetablePreview = `${baseURL}/Timetables/PostTimetablePreview`;
export const PostTimetableDTO = `${baseURL}/Timetables/PostTimetableDTO`;
export const PostRoundTimetable = `${baseURL}/Timetables/PostRoundTimetable`;
export const GetPreviewFATimeSlot = `${baseURL}/Timetables/GetPreviewFATimeSlot`;
export const GetRoundTimetable = `${baseURL}/Timetables/getRoundTimetable`;
export const GetTimetablesExcel = `${baseURL}/Timetables/GetTimetablesExcel`;
export const GetDownloadAllowed = `${baseURL}/Timetables/GetDownloadAllowed`;
export const PostCalculateAllotedTime = `${baseURL}/Timetables/PostCalculateAllotedTime`;

export const ImportFlightAttendant = `${baseURL}/Cma/ImportFlightAttendant`;
export const GetFlightAttendantByEmployeeId = `${baseURL}/Cma/GetFlightAttendantByEmployeeId`;
export const GetCurrentFA = `${baseURL}/FlightAttendants/current`;
export const GetFa = (id: number) => `${baseURL}/FlightAttendants/${id}`;
export const UpdateR2PReference = (employeeId: number) =>
  `${baseURL}/FlightAttendants/UpdateR2Preference/${employeeId}`;
export const UpdateFrenchPreference = (employeeId: number) =>
  `${baseURL}/FlightAttendants/UpdateFrenchPreference/${employeeId}`;
export const UpdateFaStatus = (employeeId: number) =>
  `${baseURL}/FlightAttendants/UpdateFaStatus/${employeeId}`;

export const GetDaysAvailability = (year: number, month: number, baseInitials: string) =>
  `${baseURL}/DaysAvailabilities/${year}/${month}/${baseInitials}`;
export const GetDaysAvailabilityByYear = (year: number, baseInitials: string) =>
  `${baseURL}/DaysAvailabilities/${year}/${baseInitials}`;
export const GenerateDaysAvailabilityForBase = (year: number, baseInitials: string) =>
  `${baseURL}/DaysAvailabilities/GenerateDaysAvailabilityForBase/${year}/${baseInitials}`;
export const GetActiveRoundsForBase = (baseInitials: string) =>
  `${baseURL}/Rounds/Active/${baseInitials}`;
export const GetEntitlementsForEmployee = (employeeId: number, year: number) =>
  `${baseURL}/Entitlements/ForEmployee/${employeeId}/${year}`;
export const GetTimetableForEmployee = (employeeId: number, roundId: number) =>
  `${baseURL}/Timetables/ForEmployee/${employeeId}/${roundId}`;

export const GetFAChangeBaseDeadline = (flightAttendantEmployeeNo: number, baseId: number) =>
  `${baseURL}/Timetables/GetFAChangeBaseDeadline/${flightAttendantEmployeeNo}/${baseId}`;

export const SubmitBid = (employeeId: number, year: number) =>
  `${baseURL}/AwardedVacations/PlaceBid/${employeeId}/${year}`;
export const GetAwardedVacationsForEmployee = (employeeId: number, year: number) =>
  `${baseURL}/AwardedVacations/ForEmployee/${employeeId}/${year}`;
export const DeleteAwardedVacation = (id: number) => `${baseURL}/AwardedVacations/${id}`;
export const GetSplitsForEmployee = (employeeId: number, year: number) =>
  `${baseURL}/Splits/ForEmployee/${employeeId}/${year}`;
export const GetSplitsForBase = (baseId: number, year: number) =>
  `${baseURL}/Splits/ForBase/${baseId}/${year}`;
export const DeleteSplit = (id: number) => `${baseURL}/Splits/${id}`;
export const CreateSplit = `${baseURL}/Splits`;
export const ModifySplit = (id: number) => `${baseURL}/Splits/${id}`;

export const GetSblForEmployee = (employeeId: number, year: number, roundNo: number) =>
  `${baseURL}/StandingBidLines/ForEmployee/${employeeId}/${year}/${roundNo}`;
export const GetSBLProcessingForEmployee = (employeeId: number, year: number, roundNo: number) =>
  `${baseURL}/StandingBidLines/ProcessingForEmployee/${employeeId}/${year}/${roundNo}`;
export const DeleteSbl = (id: number) => `${baseURL}/StandingBidLines/${id}`;
export const CreateSbl = `${baseURL}/StandingBidLines`;
export const ModifySbl = (id: number) => `${baseURL}/StandingBidLines/${id}`;

export const GetEntitleReport = (year: number) => `${baseURL}/Report/Entitle/${year}`;
export const GetCrewTracVAC = (startDate: Date, endDate: Date) =>
  `${baseURL}/Report/CrewTracVAC/${startDate}/${endDate}`;
export const GetCrewTracBST = (startDate: Date, endDate: Date) =>
  `${baseURL}/Report/CrewTracBST/${startDate}/${endDate}`;

export const SendSummaryEmail = (employeeId: number, year: number) =>
  `${baseURL}/Email/ForEmployee/${year}/${employeeId}`;
