import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import axiosInstance from '../config/axios-instance';
import { GetEntitlementsForEmployee, UpdateR2PReference } from '../config/endpoints-config';
import { Entitlement } from '../model/Entitlement';

export const useEntitlementsForEmployee = (employeeId?: number, year?: number) =>
  useQuery<Entitlement, AxiosError>(
    ['entitlement', employeeId, year],
    () =>
      axiosInstance.get(GetEntitlementsForEmployee(employeeId!, year!)).then((resp) => resp.data),
    {
      enabled: !!employeeId && !!year,
    }
  );

type R2PreferenceData = {
  r2Preference: boolean;
  employeeId: number;
  year: number;
  baseId: number;
};

export const useUpdateR2Preference = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, R2PreferenceData>(
    (pref) =>
      axiosInstance.put(UpdateR2PReference(pref.employeeId), pref.r2Preference, {
        params: { year: pref.year },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    {
      onSuccess: (resp, pref) => {
        queryClient.invalidateQueries(['entitlement', pref.employeeId, pref.year]);
      },
    }
  );
};
