import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Base } from '../../../../model/Base';
import { Round } from '../../../../model/Round';
import { CPM_PATHS, MODIFY_ROUND_MODAL } from '../../../components/utils/Constants';
import Utils from '../../../components/utils/Utils';
import { useGeneralContext } from '../../../components/utils/context/general-context-provider';

interface RoundCardProps {
  round: Round;
  base: Base;
  activeFaCount: number;
  r2FaCount: number;
}

export const RoundCard = (props: RoundCardProps) => {
  const { t } = useTranslation();
  const { selectedLanguage } = useGeneralContext();
  const { round, base, activeFaCount, r2FaCount } = props;
  const [modalId, setModalId] = useState(MODIFY_ROUND_MODAL + round.roundNo + base.initials);

  const getRoundName = (roundNo: number | undefined) => {
    switch (roundNo) {
      case 1:
        return t('bases.card.title.round_1');
      case 2:
        return t('bases.card.title.round_2');

      default:
        return '';
    }
  };

  const getDate = (value: string | Date | undefined) => {
    if (Utils.isNotNull(value)) {
      //format date
      let tmp = Utils.formatDateTimeOffsetIgnoreTZ(value!.toString(), selectedLanguage);
      return tmp;
    } else {
      return t('general.tbd');
    }
  };

  const getTime = (value: string | Date | undefined) => {
    if (Utils.isNotNull(value)) {
      //format date
      let tmp = value instanceof Date ? value.toString() : value;
      return Utils.formatTime(tmp, base.id);
    } else {
      return t('general.tbd');
    }
  };

  const getEndTime = (value: string | Date | undefined) => {
    if (Utils.isNotNull(value)) {
      //format date
      let tmp = value instanceof Date ? value.toString() : value;
      return (
        Utils.formatTime(tmp, base.id) + ' ' + t(`timezones.${Utils.getTimezoneAbbr(base.id)}`)
      );
    } else {
      return t('general.tbd');
    }
  };

  useEffect(() => {
    if (round !== null && base !== null) {
      setModalId(MODIFY_ROUND_MODAL + round.roundNo + base.initials);
    }
  }, [round, base]);

  return (
    <>
      <div className="card h-100">
        <div className="card-header fw-bold">{getRoundName(round.roundNo)}</div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 ">
              <span className="fw-bold d-block">{t('bases.card.standing_bid')}</span>
              <span className="d-block">{` ${getDate(round.periodStartDate)}`}</span>
              <span className="fw-bold d-block">{t('bases.card.bid_dates')}</span>
              <span className="d-block">
                {' '}
                {` ${getDate(round.bidStartDate)} - ${getDate(round.bidEndDate)}`} |{' '}
                {` ${getTime(round.bidStartTime)} - ${getEndTime(round.bidEndTime)}`}
              </span>
              <span className="fw-bold d-block">{t('bases.card.allotted_time')}</span>
              <span className="d-block">
                {Utils.isNotNull(round.allottedTime) ? round.allottedTime : t('general.tbd')}
              </span>
              <span className="fw-bold d-block">{t('bases.card.closing_date')}</span>
              <span className="d-block">
                {Utils.isNotNull(round.closingDate)
                  ? Utils.formatDateTime(round.closingDate.toString(), base.id, selectedLanguage)
                  : t('general.tbd')}
              </span>
            </div>
          </div>
          <div className="row mt-3 mb-1">
            <div className="col-12 d-flex ">
              <Link
                style={{ pointerEvents: round.submitted ? 'none' : 'visible' }}
                to={CPM_PATHS.round}
                className={`btn btn-primary w-50 btn-champagne ${
                  round.submitted ? 'btn-link-disabled' : ''
                }`}
                state={{
                  round: round,
                  base: base,
                  activeFaCount: activeFaCount,
                  r2FaCount: r2FaCount,
                }}>
                <span className="nav_name">{t('general.buttons.modify')}</span>
              </Link>
              <Link
                style={{ pointerEvents: !round.submitted ? 'none' : 'visible' }}
                to={CPM_PATHS.timetable}
                className={`btn btn-outline-primary w-50 ms-2 btn-outline-champagne ${
                  !round.submitted ? 'btn-link-disabled' : ''
                }`}
                state={{ round: round, base: base }}>
                <span className="nav_name">{t('general.buttons.timetable')}</span>
              </Link>
            </div>
          </div>
        </div>

        <div className="card-footer text-muted">
          {round.submitted
            ? t('bases.card.submitted', {
                cpmName: round.submittedByName,
                date: Utils.formatDate(round.submittedDate.toString(), base.id, selectedLanguage),
              })
            : t('bases.card.not_submitted')}
        </div>
      </div>
    </>
  );
};
