export interface Split {
  id: number;
  flightAttendantId: number;
  year: number;
  isCreditCarryIn: boolean;
  startDate: Date;
  endDate: Date;
  totalDays: number;
  attachBefore?: boolean;
  attachAfter?: boolean;
  IsAttach?: boolean;
  attachedDaysStart: number;
  attachedDaysEnd: number;
  ParentSplitId: number;
  index: number;
}

export const SplitDefaults: Split = {
  id: 0,
  flightAttendantId: 0,
  year: 0,
  isCreditCarryIn: false,
  startDate: new Date(),
  endDate: new Date(),
  totalDays: 0,
  attachBefore: false,
  attachAfter: false,
  IsAttach: false,
  attachedDaysStart: 0,
  attachedDaysEnd: 0,
  ParentSplitId: 0,
  index: 0,
} as const;
