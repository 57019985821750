import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { FA_PATHS } from '../Constants';
import { Callout } from '../alerts/callout';
import { useGeneralContext } from '../context/general-context-provider';
import { Header } from '../header/header';
import styles from './content.module.scss';

interface ContentProps {
  title?: string;
  subtitle?: string | React.ReactNode;
  includeHeader: boolean;
  impersonateFA?: { isImpersonate: boolean; faName: string; faEmpNo: number };
  children?: React.ReactNode;
}

export const Content = (props: ContentProps) => {
  const generalCtxt = useGeneralContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { isMenuToggled } = generalCtxt;
  const { includeHeader, children, title, subtitle, impersonateFA } = props;
  const showImpersonate = impersonateFA !== undefined ? impersonateFA.isImpersonate : false;
  const fromActiveTimeslotAlert =
    location.state !== null
      ? (location.state as { fromActiveTimeslotAlert: boolean }).fromActiveTimeslotAlert
      : false;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main className={`col py-4 transition ${isMenuToggled ? 'body-pd' : ''}`}>
        <header
          className={`pb-3 mb-4 border-bottom border-secondary ${!includeHeader ? 'd-none' : ''}`}>
          <span className="fs-4 fw-bold">{title}</span>
          <span className="fs-6 fw-light d-block lead">{subtitle ? subtitle : ''}</span>
        </header>
        <div className="container-fluid mb-5">
          <div className={`${showImpersonate ? '' : 'd-none'}`}>
            <Callout jazzRed={true}>
              <div className="row">
                <div className="col-12 col-lg-6 align-self-center">
                  <Trans
                    components={{ strong: <strong /> }}
                    values={{
                      employeeName: impersonateFA?.faName,
                      employeeNo: impersonateFA?.faEmpNo,
                    }}
                    i18nKey="impersonate.impersonating_fa"
                  />
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-lg-end mt-2 mt-lg-0">
                  <button
                    className="btn btn-danger btn-jazz_red"
                    onClick={() => {
                      console.log(window.history);
                      console.log(location);

                      if (
                        location.pathname.includes(FA_PATHS.vac_bid_standing) ||
                        location.pathname.includes(FA_PATHS.stat_bid_standing)
                      ) {
                        navigate(fromActiveTimeslotAlert ? -3 : -2);
                      } else {
                        navigate(fromActiveTimeslotAlert ? -2 : -1);
                      }
                    }}>
                    {t('impersonate.quit_impersonating')}
                  </button>
                </div>
              </div>
            </Callout>
          </div>
          {children}
        </div>
      </main>
    </>
  );
};
