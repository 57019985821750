import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FAController } from '../../../../controller/FAController';
import { useImportFA } from '../../../../hooks/FlightAttendantHooks';
import { Base } from '../../../../model/Base';
import { FlightAttendant } from '../../../../model/FlightAttendant';
import { BID_YEAR, EMP_NO_LENGTH, SUCCESS_CODE } from '../Constants';

interface ImportFAModalProps {
  fas: FlightAttendant[] | undefined;
  tBase: Base;
  setSuccessMessage: (value: string) => void;
  setErrorMessage: (value: string) => void;
}

export const ImportFAModal = (props: ImportFAModalProps) => {
  const { t } = useTranslation();
  const { fas, tBase, setSuccessMessage, setErrorMessage } = props;

  const faCtrl = new FAController();

  const [employeeId, setEmployeeId] = useState<string>('');
  const [searchResult, setSearchResult] = useState<FlightAttendant[]>([]);
  const [searching, setSearching] = useState<boolean>(false);

  const [base, setBase] = useState<Base>(new Base());
  const [errorModalMessage, setErrorModalMessage] = useState<string>('');

  const {
    mutate: importFA,
    isError: isImportFAError,
    error: importFAError,
    isLoading: importFAInProgress,
  } = useImportFA(setSuccessMessage, setErrorMessage);

  const empIdOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setEmployeeId(e.target.value);
      setErrorModalMessage('');
    }
  };

  const handleSearch = (e: any) => {
    e.preventDefault && e.preventDefault();
    setSearching(true);
    if (employeeId.length === EMP_NO_LENGTH) {
      if (fas !== undefined) {
        let filteredFa = fas.filter((fa) => {
          if (fa.employeeNo.toString() === employeeId) {
            return fa;
          }
        });
        if (filteredFa.length === 0) {
          faCtrl
            .getFlightAttendantByEmployeeId(employeeId, base.id, BID_YEAR)
            .then((response) => {
              console.log(response);
              let resultFa = response?.data as FlightAttendant;
              if (resultFa.employeeNo === 0) {
                setErrorModalMessage(
                  t('general.modals.errors.no_fas_found', {
                    empNo: employeeId,
                    baseInitials: base.initials,
                  })
                );
              } else {
                let resultList: FlightAttendant[] = [];
                resultList.push(resultFa);
                setSearchResult(resultList);
              }
            })
            .catch((error) => {
              setErrorModalMessage(t('general.errors.server_error'));
            })
            .finally(() => {
              setSearching(false);
            });
        } else {
          //Already imported FA
          setErrorModalMessage(t('general.modals.errors.already_imported', { empNo: employeeId }));
          setSearching(false);
        }
      }
    } else {
      setErrorModalMessage(t('general.modals.errors.short_emp_no'));
      setSearching(false);
    }
  };

  const onKeyEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  const saveChanges = () => {
    importFA({
      employeeId: Number(employeeId),
      year: BID_YEAR,
      baseId: base!.id,
    });
    discardChanges();
  };

  const discardChanges = () => {
    setEmployeeId('');
    setSearchResult([]);
    setErrorModalMessage('');
    setEmployeeId('');
  };

  useEffect(() => {
    setSearching(false);
    setErrorModalMessage('');
  }, []);

  useEffect(() => {
    if (tBase !== null && tBase.id !== 0) {
      setBase(tBase);
    }
  }, [tBase]);

  return (
    <>
      <div
        className="modal fade"
        id="addfaModal"
        tabIndex={-1}
        aria-labelledby="addfaModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-start" id="addfaModalLabel">
                {t('general.modals.titles.add_fa')}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={discardChanges}></button>
            </div>
            <div className="modal-body pb-0">
              <form className="row">
                <div className="col-12 mb-3 text-start d-flex align-items-center">
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      className="form-control"
                      value={employeeId}
                      placeholder={t('general.modals.body.search_by_emp_no')}
                      aria-label={t('general.modals.body.search_by_emp_no')}
                      aria-describedby="button-addon2"
                      maxLength={EMP_NO_LENGTH}
                      onChange={(e) => {
                        empIdOnChange(e);
                      }}
                      onKeyUp={(e) => {
                        onKeyEnter(e);
                      }}
                    />
                    <button
                      className="btn btn-secondary"
                      type="submit"
                      id="button-addon2"
                      onClick={(e) => {
                        handleSearch(e);
                      }}
                      disabled={searching || employeeId.length === 0}>
                      <i className="bi bi-search"></i>
                    </button>
                  </div>
                </div>
              </form>
              <div className={`row ${errorModalMessage === '' ? '' : 'd-none'}`}>
                <div className="col-12 mb-3 text-start d-flex align-items-center">
                  <div
                    className={`table-responsive w-100 ${
                      searchResult !== undefined && searchResult.length > 0 ? '' : 'd-none'
                    }`}>
                    <table className="table table-hover align-middle">
                      <thead>
                        <tr className="text-center">
                          <th scope="col">{t('bases.fa_list.table.employee_no')}</th>
                          <th scope="col" className="text-start">
                            {t('bases.fa_list.table.name')}
                          </th>
                          <th scope="col" className="text-center">
                            {t('bases.fa_list.table.seniority')}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table-group-divider">
                        {searchResult !== undefined && searchResult.length > 0 ? (
                          searchResult.map((result) => (
                            <tr key={result.employeeNo} className={`text-center`}>
                              <th scope="row">{result.employeeNo}</th>
                              <td className="text-capitalize text-start">{result.name}</td>
                              <td>{result.seniority}</td>
                            </tr>
                          ))
                        ) : (
                          <tr></tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className={`row ${errorModalMessage === '' ? 'd-none' : ''}`}>
                <div className="col-12 mb-3 text-start d-flex align-items-center">
                  <div className="alert alert-danger w-100" role="alert">
                    <i className="bi bi-exclamation-circle-fill me-2"></i>
                    {errorModalMessage}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row me-auto w-100">
                <div className="col-12 text-end p-0 d-flex">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-outline-jazz-red me-2 w-50"
                    data-bs-dismiss="modal"
                    onClick={discardChanges}>
                    {t(`general.buttons.cancel`)}
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-jazz-red w-50"
                    onClick={saveChanges}
                    data-bs-dismiss="modal"
                    disabled={searchResult?.length === 0}>
                    {t(`general.buttons.save`)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
