import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import axiosInstance from '../config/axios-instance';
import {
  Bases,
  GetBase,
  GetBasesAndRoundsByYear,
  PostFrenchTrainingDatesExcel,
} from '../config/endpoints-config';
import { Base } from '../model/Base';
import { FrenchTrainingDate } from '../model/FrenchTrainingDate';
import { FrenchTrainingExcel } from '../model/FrenchTrainingExcel';

export const useBasesList = () =>
  useQuery<Base[], AxiosError>(
    ['basesList'],
    () => axiosInstance.get(Bases).then((resp) => resp.data),
    {
      refetchInterval: 5000,
      placeholderData: [],
    }
  );

export const useBasesAndRounds = (year: number) =>
  useQuery<Base[], AxiosError>(
    ['basesAndRoundsList', year],
    () =>
      axiosInstance
        .get(GetBasesAndRoundsByYear, {
          headers: {
            Accept: 'text/plain',
            'Content-Type': 'application/json',
          },
          params: {
            year: year,
          },
        })
        .then((resp) => resp.data),
    {
      enabled: !!year,
      refetchInterval: 5000,
      placeholderData: [],
    }
  );

export const useGetBase = (baseId?: number) =>
  useQuery<Base, AxiosError>(
    ['base', baseId],
    () =>
      axiosInstance
        .get(GetBase(baseId!), {
          headers: {
            Accept: 'text/plain',
            'Content-Type': 'application/json',
          },
        })
        .then((resp) => resp.data),
    {
      enabled: !!baseId,
      refetchInterval: 5000,
      placeholderData: new Base(),
    }
  );
